
import { initializeApp } from "firebase/app";
import { 
  getFirestore,
  collection,
  addDoc,
  serverTimestamp
} from "firebase/firestore";
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDk_NNn6kjBUY_TAZ8s5aExl9bKcduo1oA",
    authDomain: "storylenses-f213d.firebaseapp.com",
    projectId: "storylenses-f213d",
    storageBucket: "storylenses-f213d.appspot.com",
    messagingSenderId: "1097349158125",
    appId: "1:1097349158125:web:2259a99cba5add1817e8e4",
    measurementId: "G-GZ7Y4Z428C"
};

export const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);





