import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    detection: {
      order: ['navigator', 'htmlTag', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    debug: false,
    backend: {
      loadPath: '/translations/{{lng}}',
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          login: {
            title: "Form", 
            email: "Enter e-mail",
            password: "Enter Password",
            signup: "Don't have an account? Sign Up",
            remember:"Remember me",
            forget:"Forgot password?",
            reset:"Check out your email to reset your password and get started with your story!"
          },
          nav: {
            login:"Login",
            register: "Register",
            logout:"Logout",
          },
          subnav: {
            create:"Create Stories",
            job:"Job Application",
            saved: "Saved",
            favorites:"Saved Stories",
            knowledge:"Story Knowledge",
            course: "Online Course",
            ebook:"E-Book",
            cta: "Improve your Story",
            button: "Start Online Course",
          },
          lp: {
            headline:"Get", 
            headlineBold:"your story,",
            headline2: "be",
            headlineBold2:"heard.",
            herotext:"You are looking for a job? Welcome to Story Lenses, the first tool that tells your story:",
            herotext2: "Your own story, structured professionally for applications, CVs, negotiations and interviews.",
            cta: "Get Started",
            ctaContact:"Contact us",
            featH1: "Features",
            featH2:"What you get",
            featH3:"Get your personalised story: tailored to you, your profile and your situation. In professional story format. To be understood and heard. Suitable for all aspects of the application, interview - and beyond...!",
            feat1H:"Story Creator",
            feat1P:"Professional storytelling that appeals to the listener, captivates, meets the content requirements of the target audience, and keeps their attention at all times.",
            feat2H:"Story Improvers",
            feat2P:"Guided questionnaires, supporting tools, grammar help, language improver, style guide, multiple languages, e-book and expert advice to convey all that is important.",
            feat3H:"Application guide",
            feat3P:"The story is the foundation for everything; communication, your application documents, especially the cover letter and job interview. Everything that for you being heard and recognised.",
            stepsH1:"STEPS",
            stepsH2:"Easy to Get Started.",
            step1H:"Register",
            step1D:"Create an account with your email in 30 seconds.",
            step2H:"Payment",
            step2D:"Subscribe and start with a 7 days free trial.",
            step3H:"Story up",
            step3D:"Follow the flow, answer the questions and revise your text to perfection thanks to Story Lenses input tips.",
            pricingH1: "Pricing",
            pricingH2: "Flexible Plan",
            pricingH3: "Start with a free trial and subscribe monthly for as long as you use the tool.",
            pricingMo: "Month",
            pricingM: "Monthly",
            pricingYo: "Year",
            pricingY: "Yearly",
            pricingButton: "Buy now",
            pricingPlan: "Story Creation Plan",
            pricingPlanDesc: "Suited for Job applicants",
            pricingPlanD1: "Application Story Creator",
            pricingPlanD2: "Ebook on Storytelling",
            pricingPlanD3: "Multi language support",
            pricingPlanD4: "No usage restriction",
            pricingPlanD5: "Support",
            testimonials: "Testimonials",
            testimonialsH: "Our Users Love Us.",
            testimonialsD: "Applicants from all over the world shower us with praise. We've had the pleasure of introducing a few of them to you here.",
            testimonialsH1: "Strong foundation",
            testimonialsD1: "I had just moved from Southern America to Europe and had no experience with job applications, so I was looking for a guide on how to go about it. The app was amazingly easy to use and delivered results quickly. I used the story as the basis for my cover letter, CV structure and interview narrative. I received immediate feedback on all applications and finally a job offer, which I gladly accepted.",
            testimonialsName1: "Danielle Silva", 
            testimonialsPosition1: "Sales, H&M",
            faqH:"Frequently Asked Questions",
            faqD:"This is a novel tool and approach. Of course there are questions. Here are the most common ones.",
            faqH1:"Why do you recommend not to use the first draft?",
            faqD1:"In summary, while a first draft is a necessary starting point, it's only the beginning of the writing process. The tool is already designed to cut this process short. Nevertheless, revision and editing are crucial steps to refine and improve the work before it's shared with an audience, ensuring that it meets the writer's and the audience's expectations. The tool is designed to help you guide attention to check for consistency and provides resources to help you reflect for an even higher impact. ",
            faqH2:"How do I use the story in my application process?",
            faqD2:"The story is the basic narrative that contains the relevant information about who you and the job offering company are and what you are looking for. It describes the potential highlights and challenges as well as a resolution path. The scope and content serves for the job search (Is that what i really want?), the application material (What to say in the covering letter, what to focus on in the resume), and most importantly as the narrative for the verbal interview. ",
            faqH3:"Can I get feedback on my story?",
            faqD3:"Absolutely. We offer a 48h feedback service for a low one time fee on your covering letter, resume and story. ",
            faqH4:"This is a powerful tool, why is it so cheap?",
            faqD4:"We built this to help people getting the best shot for the job they want. A low price is literally the way, we think, to make it as widely accessible as possible.",
            faqH5:"What is this based on? ",
            faqD5:"Story Lenses is a system that creates the right architecture for your story based on models that have been handed down and successfully used since the first sagas, Greek tragedy, the Bible and printing. Story patterns of professional authors, stage and media directors and story tellers, like the screenwriter and dramaturge Blake Snyder. Many of these story patterns are based mainly on Greek mythology and they are “bulletproof” and tested million times over as they were professionally used in the media, publishing, film and screenplay business.",
            faqH6:"Who built this?",
            faqD6:"We are a team of professionals from film, media, publishing and software development united to help you to tell the best story that you got. ",
            valueH: "THE STORY RESTS WITHIN YOU!",
            valueH1: "Time to get it...and more.... out of it!",
            valueD: "You don't have to reinvent the wheel for writing, you don't have to learn storytelling from 100 books and courses: follow the instructions in Story Lenses - and you'll get all you need, a complete story.",
            valueH2: "Very Affordable",
            valueD2: "We want to make the tool accessible to everyone! Simple. Anywhere. Anytime. For any kind of application, interview or story. And: The first 7 days are even free.",
            valueH3: "A story goes a long way",
            valueD3: "A really well-told, authentic, convincing and exciting story goes a long way – and lasts for many opportunities: Take your story and use it from now on for all relevant application documents: cover letter, CV, interview ...",
            valueCTA: "Get started",
            ctaBoxH: "Are you ready for your story?",
            ctaBoxD: "Give it a shot.",   
          },
          terms: {
            h1: "Last Updated: 08.01.2024",
            p1: "Welcome to StoryLenses.app! Please read these Terms and Conditions carefully before using our website and services.",
            h2: "1. ACCEPTANCE OF TERMS",
            p2: "By accessing or using StoryLenses.app (the 'Website'), you agree to comply with and be bound by these Terms and Conditions. If you do not agree with any part of these terms, you may not use our services.",
            h3: "2. USE OF SERVICES",
            p3: "StoryLenses.app provides an online tool for creating personalized stories and an online course. By using our services, you agree to:\n- Pay the one-time fee of $1.99 USD for access to the personalized story creation tool.\n- Pay the one-time fee of $49.99 USD for the lector service.\n- Acknowledge that the job application enhancement tool is provided to improve application success rates, but there are no guarantees of job placement.",
            h4: "3. PAYMENTS",
            p4: "All payments for services provided by StoryLenses.app are processed securely. Fees are non-refundable unless otherwise specified. By making a payment, you agree to the specified charges.",
            h5: "4. INTELLECTUAL PROPERTY",
            p5: "All content and materials provided on StoryLenses.app, including but not limited to text, graphics, logos, images, audio, and video, are the property of StoryLenses and are protected by copyright, trademark, and other intellectual property laws.",
            h6: "5. DISCLAIMER",
            p6: "StoryLenses.app does not guarantee job placement or success. The tools and courses provided are meant to enhance skills and improve application strategies, but individual outcomes may vary.",
            h7: "6. LIMITATION OF LIABILITY",
            p7: "StoryLenses.app and its affiliates shall not be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our services.",
            h8: "7. PRIVACY POLICY",
            p8: "Your privacy is important to us. Please refer to our Privacy Policy for information on how we collect, use, and disclose personal information.",
            h9: "8. MODIFICATIONS",
            p9: "StoryLenses.app reserves the right to modify these Terms and Conditions at any time. Updated versions will be posted on the Website, and your continued use constitutes acceptance of the modified terms.",
            h10: "9. GOVERNING LAW",
            p10: "These Terms and Conditions are governed by and construed in accordance with the laws of Germany, EU.",
            h11: "10. CONTACT US",
            p11: "If you have any questions or concerns about these Terms and Conditions, please contact us at info@storylenses.app."
          },
          privacy: {
            h1: "PRIVACY POLICY",
            p1: "Last Updated: 08.01.2024",
            p2: "This Privacy Policy outlines how StoryLenses.app ('we', 'us', or 'our') collects, uses, and protects your personal information. By using our website and services, you consent to the practices described in this policy.",
            h2: "INFORMATION WE COLLECT",
            p3: "1. **Anonymous User Statistics:** We use Heap.io for tracking anonymous user statistics to analyze user behavior and improve our services. This data is collected in aggregate and does not personally identify individuals.",
            p4: "2. **Payment Data:** We do not store payment data on our database. We only retain the payment status of our paying customers.",
            p5: "3. **Invoice Information:** For the purpose of providing invoices, we store the name, address, and email address of our customers who have made payments.",
            h3: "USE OF INFORMATION",
            p6: "We use the collected information for the following purposes:",
            p7: "- **Improving Services:** Anonymous user statistics help us understand how users interact with our website and enhance the user experience.",
            p8: "- **Payment Status:** We use payment data to manage and track the payment status of our customers.",
            p9: "- **Invoicing:** Name, address, and email address are used for creating and delivering invoices.",
            h5: "INFORMATION SECURITY",
            p10: "We are committed to ensuring the security of your information. While we strive to protect your personal information, no method of transmission over the internet or electronic storage is completely secure.",
            h6: "DISCLOSURE TO THIRD PARTIES",
            p11: "We do not sell, trade, or otherwise transfer your personally identifiable information to outside parties. This does not include trusted third parties, such as Heap.io and Stripe Payment Service, who assist us in operating our website and conducting our business, as long as they agree to keep this information confidential.",
            h7: "YOUR RIGHTS",
            p12: "You have the right to access, correct, update, or delete your personal information. If you have any concerns or requests regarding your data, please contact us at [Your Contact Information].",
            h8: "CHANGES TO THIS PRIVACY POLICY",
            p13: "We reserve the right to update or modify this Privacy Policy at any time. The latest version will be posted on the website with the effective date. Continued use of our services after changes constitute acceptance of the updated policy.",
            h9: "CONTACT US",
            p14: "If you have any questions or concerns about this Privacy Policy, please contact us at info@storylenses.app.",
            p15: "Thank you for trusting StoryLenses.app with your information."
          },
          home: {
            mainHeadline: "Lets Create Your Story",
            subHeadline: "Tips for Success",
            description: "",
            herostory: "The perfect type of story for a job application is the so called Superhero Story. The key here is a nemesis and a problem seemingly bigger than the protagonists themselves. So it's a perfect match for most job describtions to put yourself into a grander scheme, which is the new job and company.",
            herostory2: "",
            herostory3: "",
          },
          storyform: {
            headline: "Respond the questions, get your first draft in about 30min.",
            description: "Read the above tips for success, grab the job ad and give it go.",
            placeholder:"No answer yet.",
            placeholderForm: "Start typing...",
            pdf:"Download as PDF",
            tipsH:"",
            tips0:"Start with the job ad. This tool is designed to work out your best story based on the job ad you want to apply for.",
            tips1:"Follow the question helper guidelines.",
            tips2:"Do not use punctuation in the end of your resonses.",
            tips3:"",
            Helper:"For support, play the audio, check input and example. For more, check out the <a href='/learn'>online course</a>.",
            name:"name",
            qname: "What is your name?",
            qnameHelper: "Input you First name and your last name. If you have more than that, leave the middle ones out as you would do on your CV.",
            workExperience:"workexperience",
            qworkexperience: "How many years working experience have you got?",
            qworkexperienceHelper: "Input a number that expresses the amount of your professional work experience in years. Round it to full year. Make sure that matches your resume.",
            date:"date",
            qdate:"What is the date of the job ad?",
            qdateHelper:"This is a time reference for the job ad. Either put in the date the ad got published or otherwise just add month and year you read it first.",
            media:"media",
            qmedia:"Where did you read the Job ad?",
            qmediaHelper:"This is to provide a bit of media context. Input the name of the website or journal where you have read the ad. For instance, LinkedIn or Company website. If you cannot recall the name of the publication, just mention on a high level in what media category you found it. For instance, newspaper or internet.",
            jobtitle: "jobtitle" ,
            qjobtitle: "What is the Job Title you are applying for?" ,
            qjobtitleHelper:"Input the job title as accurate as possible. Best you take the title from the job ad." ,
            workExperiences: "workexperiences",  
            qworkExperiences: "What are your most relevant previous professional work experiences?",  
            qworkExperiencesHelper:"This is a list of previous roles which are relevant work experiences to the job you are applying for. Make sure your response is High level. Ideally you mention the Job titles of the top relevant Experiences. A good format would be the titles and company names of previous job positions.",
            specialty:"specialty",
            qspecialty:"What is your profession?",
            qspecialtyHelper:"If you have one or more professions, name them. The profession title portrays your specialty.",
            degree:"degree",
            qdegree:"What is your highest academic degree or formal qualification?",
            qdegreeHelper:"Name the title of your academic degree and / or formal qualifications. This list must not be exhaustive, but represent the highlights. For a full list, you attach your resume anyhow.",
            treasure: "treasure",
            qtreasure: "What is most precious to you?",
            qtreasureHelper: "You input a list of things that you value most. This is a very personal section and can be literally anything. The idea here, is to be clear on what means the world to you. Ideallly you focus on the top three. This is high impact input that allows your reader to connect with you. This is essential to get attention and achieve successful communication." , 
            jobDeliverable:"jobdeliverable",
            qjobDeliverable:"What is the Job Deliverable?",
            qjobDeliverableHelper:"Hands down, what needs to get done in the job? Input a list of defined results without any fluff. Check out the Job ad for hints and state here what result is expected from this position. By result is meant what is the literal outcome of the work.",
            applicantChallenge:"applicantchallenge",
            qapplicantChallenge:"What made you look for a new job opportunity?",
            qapplicantChallengeHelper:"The questions behind the question here are multiple ones: Why are you even available? Why now? There is no right or wrong, but whatever you input needs to be rock solid.",
            applicantGoal:"applicantgoal",
            qapplicantGoal:"What are you looking for?",
            qapplicantGoalHelper:"Another to the point, no bullshit input:  list what you are looking for. Read it again out loud and check if that is really it. There might be more than one things. Add the biggest and most important ones. Keep in mind, there is no right or wrong. This is about what you are looking for in your next job. ",
            applicantSearch: "applicantsearch",
            qapplicantSearch: "What did you do to find what you are looking for?",
            qapplicantSearchHelper:"This is to give a little bit of context what have you been done so far find what you are looking for. The approach is part of the story. So list the activities or means of your search.",
            companyName:"companyname",
            qcompanyName:"What is the name of the Company you are applying for?",
            qcompanyNameHelper:"Input the official company name. You can get that either from the Job ad or company registry.",
            companyProblem:"companyproblem",
            qcompanyProblem:"What Problem is the company trying to solve?",
            qcompanyProblemHelper:"This is a big picture respponse. Every company has a purpose. Name it. Again, no fluff. Here you need to be comfortable with the big words that compelete the following sentence: The purpose of the company is. ",
            companyThreat:"companythreat",
            qcompanyThreat:"What threat does the company face if the advertised job is not fulfilled properly?",
            qcompanyThreatHelper:"This is about being aware of the consequences. What happens if they fail? Again a big picture point that shows you know the boundaries of what is at stake.",
            hiringReason: "hiringreason",
            qhiringReason: "What is the reason why the company is hiring right now?",
            qhiringReasonHelper:"This is about the job context in terms of the company and its strategy and organisation. Research the reasons what makes them advertise the job. What is behind the job?",
            companyInitiative:"companyinitiative",
            qcompanyInitiative:"What strategic initiative is behind that hiring?",
            qcompanyInitiativeHelper:"Does the company pursue any concrete initiatives, projects or strategies that have to do with the job? List the relevant initiatives. Research in what strategic business context the job is placed. ",
            city: "city",
            qcity: "In what city is the position?",
            qcityHelper: "Literally name the city the job is at. If it is not bound to a specific location, say sth like remote work or another noun that describes where the work is done.",
            qualification:"qualification",
            qqualification: "What are your relevant formal qualifications for the job? ",  
            qqualificationHelper:"This is literally a list of the relevant qualifications. Just list the names, no further descriptions. A job qualification refers to the certifications and attributes that an employer requires from a candidate to be considered eligible for a particular job position. These qualifications are typically listed in the job posting or job description provided by the employer or hiring company. Feel free to add qualifications that you feel are necessary to mention in that context.",
            languages: "languages",  
            qlanguages: "What languages do you speak fluently or well?",  
            qlanguagesHelper:" List the relevant Languages with a formal level like B2, C1 if you have one or use terms like fluent, native. Mention only languages you can maintain in a professional context.",
            skill3: "skill3",  
            qskill3: "What are your top three relevant skills for the job?",  
            qskill3Helper:"This is about whay you can do that gets the job done. This is literally a list of your main skills which are relevant for the job. No fluff, just name them in a comma separated list.",
            references:"references",
            qreferences: "What are your top relvant references for this job?",  
            qreferencesHelper:"This is about what kind of people are willing to do you a favor. List up to four relevant professionals with name, position and company. Those contacts should be in the position of authority and experience to recommend you for delivering what is in question. Their contact details should be listed in your resume and be informed by you about you sharing their info in that context",
            desire:"desire",
            qdesire:"What are the job promises that make you wanna do the job?",
            qdesireHelper:"This is about you being conscient of their offerings. List the promises by name that are most important to you. No fluff, just name them in a comma separated list.",
            sustainable:"sustainable",
            qsustainable:"What makes the job offering sustainable for you?",
            qsustainableHelper:"This is about what helps you to keep the engagement in the long run. List all your relevant factors where you know for sure that this helps you sustain the job. There is no right or wrong. However, some classics would be sustainable financials, personal growth perspective and time flexibilities that allow you to have a life, too.",
            companyoffering: "companyoffering",
            qcompanyOffering:"What does the company offer that helps you sustain the job?",
            qcompanyOfferingHelper: "This is literally a list of relevant offerings by name. Research in Job Ad or Company career section and quote whote they offer here.",
            companynotoffering:"companynotoffering",
            qcompanyNotOffering:"What does the company not seem to offer that would help you sustain the job?",  
            qcompanyNotOfferingHelper: "Mention here clearly what offer is missing or where you are in doubt if they offer it or not. This is absolutely crucial. It puts you in a stronger position, being able to take control in the interview and negociation process.",  
            start:"start",
            qstart:"What would you have to learn for a successful start?",
            qstartHelper:"Imagine you get the job. What new skills or knowledge do you need to build? No fluff, just name them in a comma separated list.",
            learn:"learn",
            qlearn:"Name up to three examples where you learned necessary skills on the job.",
            qlearnHelper:"This is about making a point. You have been in a similar situation, sucessfully learning on the job before. Give a few learning on the job examples.",
            change:"change",
            qchange:"What will change for you in the new job?",
            qchangeHelper:"Compare your past roles to this one. What would change for you? For example will you be confronted with a different working style, more or less responsibity, new industry,...  Name up to three major changes.",
            questions:"questions",
            qquestions:"What questions would you like to clarify in an interview?",
            qquestionsHelper:"Who asks, leads. These questions are absolutely essential. Take you your time, make sure they are consistent with you narrative and that the response is of true meaning to your decision making. If the response to a question does not make a difference, do not bother with it.",
            questionmarks: "questionmarks",
            qquestionMarks:"What question marks do you think the employer might have about you?",
            qquestionMarksHelper:"This is the game of devils advocate. Think of it like this: What would you like to know about, if you were them reading your resume? The more ruthless you are, the better your narrative will be and you will be able to respond strongly in the interview.",
            questionmarksexamples:"questionmarksexamples",
            qquestionMarksExamples:"Name a few examples.",
            qquestionMarksExamplesHelper:"Provide a few obvious examples of particularities in your application or resume that most likely spark curiosity on the employer's side. Think of it like this: What would you like clarified or to know more about if you were them reading your resume.",
          },
          stepper: {
             back: "Back",
             next: "Next",
             complete: "Complete Step",
             exposition: "Exposition",
             inciting: "Incitig Incident",
             suspense: "Suspense",
             preclimax: "Pre-climax",
             climax: "Climax",
             resolution: "Resolution",
             story: "Your story",
             check: "Application checklist",
             apply: "Apply",
             audio: "Audio help",
             input: "Respond the questions, get your story",
             inputCheck:"Input check",
             inputCheckH:"Check if your input reads well in the above sentence. Otherwise edit.",
             example: "Example Input", 
             exampleD:"User input is marked like that: __Input__",  
             exampleHover:"Example",
             iconExplainer:"Response helper",
             iconText:"Short Text response (Just the facts)",
             iconNumbers:"Enter a number",
             iconDate:"Enter a date",
             iconList:"Comma separated Text response. (e.g. This is my First point, second point and third point.)",
             iconThink:"Think hard. Your input here goes a long way.",
             helpMaterial:"Check out the focused online course. It helps you with knowledge and tooling to prepare yourself.",
             helpMaterialLink:"Start Learning",
          },
          example: {
            name:"Frank Smith",
            nameL: "My name is",
            nameS: "Example first and last name",
            workExperience:"7",
            workExperienceL:"I have __7__ years of professional experience.",
            workExperienceS:"Example of 7 years professional work experience",
            date:"12.02.2023",
            dateL:".. the job ad dated ",
            dateS:"Example job ad date",
            media:"LinkedIN",
            mediaL:"I learned about the job on ",
            mediaS:"Example media",
            jobtitle:"Sales Manager Consumer Electronics",
            jobtitleL:".. applying for the job as ",
            jobtitleS:"Example of a Job Title as it would appear in an ad.",
            workExperiences:"Sales Lead Company X, Sales Team Lead Electronics Company Y, Tele Sales Consumer Electronics",
            workExperiencesL:".. relevant experience from my previous roles as ",
            workExperiencesS:"Example of how to best list previous job roles.",            
            specialty:"Salesmen",
            specialtyL:"The term that describes my profession is  ",
            specialtyS:"Example of a profession",
            degree:"Bachelor B.A. Marketing",
            degreeL:"My highest academic degree or formal qulification is ",
            degreeS:"Example of an academic degree",
            treasure:"My partner and my family.",
            treasureL:"What means the world to me is: ",
            treasureS:"Example of what matters to someone",
            jobDeliverable:"Increase Sales, build a team",
            jobDeliverableL:"Results from the job:",
            jobDeliverableS:"Example results from a sales job",
            applicantChallenge:"Grow as a professional, expand my industry know how to xyz",
            applicantChallengeL:"Key reasons that make me look for a job are: ",
            applicantChallengeS:"Example of reasons to look for a new job.",
            applicantGoal:"A managerial role. Team Lead or Region Lead.",
            applicantGoalL:"I am looking for a ",
            applicantGoalS:"Example of a role",
            applicantSearch:"Digging into my network, Internet Search",
            applicantSearchL:"This is how i tried to find jobs:",
            applicantSearchS:"Example of actions to find a suitable job",
            companyName:"Bloomberg L.P.",
            companyNameL:"The company's legal name is ",
            companyNameS:"Example of company legal name",
            companyProblem:"Data overload in finance.",
            companyProblemL:"The company set out to solve the problem of ",
            companyProblemS:"Example of a problem that a company tackles.",
            companyThreat:"Finance professionals tooling and networking will be scatterd. ",
            companyThreatL:"In case of failure the consequence will be: ",
            companyThreatS:"Example of a looming threat.",
            hiringReason:"Company expansion into the xyz market.",
            hiringReasonL:"The reason the company is hiring is ",
            hiringReasonS:"Example for a reason to hire.",
            companyInitiative:"A Latin America Growth Agenda",
            companyInitiativeL:"The company initiated ",
            companyInitiativeS:"Example of a strategic initiative",
            city:"Boston, MA",
            cityL:"The job is based in ",
            cityS:"Example of a job location.",
            qualification:"Certified Professional Sales Leader (CPSL) ",
            qualificationL:"My most relevant qualifications being: ",
            qualificationS:"Example of a Job qualification",
            languages:"English Native,Russian C2, Spanish C1",
            languagesL:"My professional language skills are: ",
            languagesS:"Example of professional language skills",
            skill3:"Effective Communication, Product Expertise and Sales Demoing",
            skill3L:"My most relevant skills are: ",
            skill3S:"Example for job skills",
            references:"Michael Smith, CFO Company X, Sarah Wighely, CPO Company Y",
            referencesL:"My most relevant professional references are: ",
            referencesS:"Example for professional references",
            desire:"Get shares, personal growth and earning possibilities",
            desireL:"My biggest reasons to do the job are: ",
            desireS:"Example for priorities",
            sustainable:"Flexible work hours, continous training and parental leave time.",
            sustainableL:"The following conditions make the engagement sustainable for me: ",
            sustainableS:"Example of factors that make a job sustainable.",
            companyOffering:"Mentorship and Training Program",
            companyOfferingL:"These Company offerings meet my demands: ",
            companyOfferingS:"Example for company offerings.",
            companyNotOffering:"Parental leave time beyond holiday and flexible working hours.",
            companyNotOfferingL:"It seems the company is not offering ",
            companyNotOfferingS:"Example of items a company might not offer",
            start:"administering Salesforce CRM and setting SMART Goals.",
            startL:"For a successful start I need to learn ",
            startS:"Example for new skills to be learned.",
            learn:"Microsoft Dynamics 365 CRM Admin skills, OKR Goal System",
            learnL:"In similar fashion, i learned these skills on the job: ",
            learnS:"Example for skills learned on the job",
            change: "have more responsiblity, hiring duties and higher budget.",
            changeL: "On this job i would ",
            changeS: "Example for changes from one job to the other",
            questions:"Is paternity leave time possible? What options are there to make working hours more flexible?",
            questionsL:"I would like to know: ",
            questionsS:"Example for questions to clarify.",
            questionMarks:"Have i got sufficient experience? Can I deliver results despite flexible working hours?",
            questionMarksL:"Your resume and covering letter in mind, I wonder: ",
            questionMarksS:"Example of question marks an employer might have",
            questionMarksExamples:"Flexible Working Schedule",
            questionMarksExamplesL:"You might wonder about ",
            questionMarksExamplesS:"Example of a possible question.",
          },
          resume: {
            headline:"Resume checklist",
            subHeadline:"Check you resume if the following information match:",
            benefit:"Make sure you show relevant information that is consistent with your narrative.",
            name:"Name: ",
            workExperience:"Work Experience in years: ", 
            media:"Media Account: ", 
            specialty:"Profession: ",
            degree:"Academic degree or school diploma: ", 
            applicantChallenge:"Goals: ", 
            applicantGoal:"Desired Position: ", 
            qualification:"Qualifications: ", 
            languages:"Language skills: ", 
            skill3:"Skills: ", 
            references:"References ", 
            workExperiences:"Work Experiences: ", 
            change:"Mention of what to change from: ",  
            questionMarks:"Question marks: ",
            questionMarksExamples:"Question marks examples", 
          },
          search: {
            headline:"Job search checklist",
            subHeadline:"Check if this is really what you are looking for:",
            benefit:"Save time and trouble. Focus only on jobs you are looking for.",
            jobTitle:"Is this what you want to be doing? - job title: ", 
            workExperience:"Do you have experience doing it? - your job experience in years: ", 
            treasure:"Does the job nurture what you care for? - your treasure: ", 
            specialty:"Does the job match your profession? - your profession: ", 
            degree:"Does your degree enable you to do this job? - your degree: ", 
            jobDeliverable: "Do you can and want to produce these results: ",
            applicantChallenge:"Does the job allow you to face your challenge? - your challenge: ", 
            applicantGoal:"Is this what you are looking for? - your goal: ",
            companyProblem:"Is the company's mission of interest to you? - company mission: ",
            companyThreat:"Do you care about the downside if they fail? - if they fail then: ", 
            hiringReason:"Is the hiring reason convincing? - hiring reason: ", 
            companyInitiative:"Do you agree with the strategy initiative that is behind the offer? - company intitiative: ", 
            desire:"Does the job offer what you desire? - your desire: ", 
            sustainable:"Does the job and setup offer everything that allows you to do it over several years without comprimising essentials such as your health, finances, family or hobbies? - your sustainability factors: ", 
            companyOffering:"Is this what you need? - company offering: ", 
            companyNotOffering:"Are these missing factors a deal breaker? - company not offering", 
            start:"Do you want to learn these things? Will that be beneficial in the long run? - learning requirements: ", 
            learn:"Does your past learning challenges match with the ones required in this job? - similar past learning experiences:",
            change:"Are these changes in your interest? - changes to previous engagements:", 
            references: "Is the feedback of your references supportive? Do they have authority to give you credit for this job? References: ",
            questions:"Is this opportunity worthwhile to get your questions answered? - your questions being: ", 
            questionMarks:"Are the potential marks too big of a gap? - Potential question marks being: ",
            questionMarksExamples:"Would you hire yourself given these question mark examples? - Examples for potential question marks: ",
          },
          coverletter: {
            headline:"Cover letter and application material",
            subHeadline:"Check your cover letter and application for the following:",
            benefit:"Consistent, focused application to grasb attention of your audience.",
            letter:"A cover letter that contains a high level version of your story. All parts, starting from Exposition to Resolution should be featured. We recommend to send your story as covering letter, as it is already high level and focused on the big picture. However, in some cases you have to deal with word limits for online applications. In this case you must shorten the story by focusing on the absolute highlights while keeping the structure from exposition to resolution.",
            resume:"A resume that contains the evidence your story content",
            documents:"Documents as proof of you qualification",
          },
          interview: {
            headline:"Interview checklist",
            subHeadline:"We recommend you create a gameplan around the essentials: Why?, How?, What?, Potenial Risks and Mitigations.",
            benefit:"You enter the interview with a plan and scope.",
            benefit2:"You have tools to grasp control and come prepared to deal with potential weaknesses in the best manner.",
            why:"All starts with WHY. Prepare to elaborate on your reasons to take on the job: ",
            how:"How are you going to do job? Prepare your elaborate concept based on your skills, qualifications and professional experience: ",
            skills3: "Skills: ",
            qualification:  "Qualification: ",
            workExperience: "Work experience: ",
            Degree: "Highest Academic Degree: ",
            what: "The ultimate question to be answered: What can you do for me? Prepare the result you strive to produce: ",
            risk: "Address potential risks or perceived shortcomings: ",
            questions: "Make sure to address your questions and get a definitive answer: ",
            preparedHeadline:"Time to shine",
            preparedSubHeadline:"With all checkmarks ticked, you are ready to tell your best story. Go and tell it!"
          },
          storysection: { 
            headline: "No story yet",
            subHeadline:"Start responding and get your story.",
            cta:"Respond all questions to get your full story",
            finalStepH:"Voilà!.. ",
            finalStepH1:".. now review and edit for a strong result. ",
            finalStepD:"Never publish your first draft. Every PRO Writer knows that. To help you review and edit efficiently, ",
            finalStepD1:"all your entries are marked purple",
            finalStepT:"On hover you get review guidance. Follow it to improve your story narrative, coherence and experession",
            reviewMode:"Your story [review mode]",
            cleanMode:"Your story",
            paragraph1:"My name is {{name}}. I hereby wish to apply for the job as {{jobTitle}} dated {{date}} posted in {{media}}.",
            name: "My name is",
            nameT:"Double Check: Is this the name on your Resume?",
            jobtitle:" I hereby wish to apply for the job as ",
            jobTitleT:"Double check: is this the job title from the ad?",
            date:" date ",
            dateT:"Double check: Is this the exact date from the job ad?",
            media: " posted in ",
            mediaT: "Double check: Is this where you found the ad?",
            paragraph2:"I have over {{workExperience}} years of professional experience in {{workExperiences}} and specialise in the profession of {{specialty}} with a degree in {{degree}}.",
            workexperience:" I have over ",
            workexperienceT:"Double check: Is this your total amount of professional work experience?",
            workexperience1:" years of professional experience in ",
            workexperience1T:"Double check: Are these your Top 3 relevant work experiences to this job?",
            specialty: " and specialise in the profession of ",
            specialtyT: "Double check: Is Your profession relevant to the job ad?",
            degree:" with a degree in ",
            degreeT:"Double check: Is this your list of your degrees as can be found in your CV?",
            paragraph3:"{{treasure}}, is my source of strength. That empowers me to deliver {{jobDeliverable}}. My challenge making me look for a job right at this moment is {{applicantChallenge}}. I am in the process of looking for {{applicantGoal}}. Therefore, I started to search for a matching and vacant job opportunity firstly by {{applicantSearch}}.",
            treasure:", is my source of strength.That empowers me to deliver ",
            treasureT:"Double Check: Is this what means the world to you? Your treasure, source of strength, what your life energy is built upon,... ",
            jobDeliverable:"",
            jobDeliverableT:"What results do you have to deliver in the job? What is the outcome? ",
            applicantChallenge:"My challenge making me look for a job right at this moment is ",
            applicantChallengeT:"Double check: Is this THE thing you are seeking to take on?",
            applicantGoal:"I am in the process of looking for ",
            applicantGoalT:"Double check: Does what you are looking for matches with the job at hand? ",
            applicantSearch:"Therefore, I started to search for a matching and vacant job opportunity firstly by ",
            applicantSearchT:"Double check: Do these methods include the means how you found this job?",
            paragraph4H: "Company challenge; where I come in",
            paragraph4: "{{companyName}} pursues the problem of {{companyProblem}}. Doing so it faces the threat of {{companyThreat}} in case of failure. The reason the company is hiring is {{hiringReason}}. In that endeavour, the company initiated {{companyInitiative}}.",
            companyName:" pursues the problem of  " ,
            companyNameT:"Double check: Is this the official company name as it appears in the commerce registry?" ,
            companyProblem: " Doing so it faces the threat of ",
            companyProblemT: "Double check: In the big picture: is this what the company is trying solve?",
            companyThreat: " in case of failure.",
            companyThreatT: "Double check: Is this what happens if they fail?",
            hiringReason:" The reason the company is hiring is ",
            hiringReasonT:"Double check: is this really the reason?",
            companyInitiative:" In that endeavour, the company initiated ",
            companyInitiativeT:" Double check: Is that really the company hiring context?",
            paragraph5: "This is precisely where i come in. I have got the following qualifications, skills and desire to achieve the required tasks and to get the job done. My top three relevant qualifications being: {{qualification}}. I speak the following job relevant languages fluently: {{languages}}. My top three relevant skills being {{skill3}}. My top three relevant references being: {{references}}. My relevant work experiences i mentioned already to beginn with.",
            qualification: "This is precisely where i come in. I have got the following qualifications, skills and desire to achieve the required tasks - and to get the job done. My most relevant qualifications being, ",
            qualificationT: "Double check: Are these qualifications prominently listed in your CV and have you got some sort of proof for them?",
            languages: " I speak the following languages fluently: ",
            languagesT: "Double check: Are these languages prominently listed in your CV and have you got some sort of proof for the Level? ",
            skill3: " My key relevant skills being ",
            skill3T: "Double check: are these skills mentioned in your CV and do you have proof for them?",
            references: " My relevant references being ",
            referencesT: "Double check: Are the references on your CV?",
            workExperiences: " My relevant work experiences i mentioned already to beginn with.",
            workExperiencesT: "Double check: Are these your Top 3 relevant work experiences?",
            paragraph6H:"Key Assumptions to dig into",  
            paragraph6:"The promise that the job offer raises for me is matching with these desires: {{desire}} is why I would do the job. Whats more, the following factors make the job engagement sustainable for me in the medium to long run: {{sustainable}}. The company seems to sustain this by providing {{companyOffering}}. The company seems not to provide {{companyNotOffering}}, or at least I yet have no knowledge of it. Due to these given factors as mentioned above I think I can support {{companyName}} in its mission to tackle the problem of {{companyProblem}}, which ignites the motivation that I am interested in your advertised position as a {{jobTitle}}.",  
            desire:"The promise that the job offer raises for me is matching with what i desire, namely:",
            desireT:"Double check: Is it clear and consistent with your narrative, why you desire these points? Is it certain they offer that?",
            desire1:" is why I would do the job.",
            sustainable:"Whats more, the following factors make the job engagement sustainable for me in the medium to long run: ",
            sustainableT:"Double check: Are these factors crucial to keep you interested, motivated, financed, healthy and sane? Unless you want to look for another job in 6 months from now, make sure to get these right.",
            companyOffering:"The company seems to sustain this by providing ",
            companyOfferingT:"Double check: Name the company benefits or offerings that cater to your previously mentioned important factors.  ",
            companyNotOffering:"The company seems not to provide ",
            companyNotOfferingT:"Double check: Name the benefits or offerings you are missing or unsure about if anything along those lines is offered at all. Pro Tip: This point comes in handy when you are in an interview. Raise it and you get a great chance to gain control and ground in the interview.",
            companyNotOffering2:", or at least I yet have no knowledge of it. ",
            conclusion1:"Due to these given factors as mentioned above I think I can support ",
            conclusion2:" in its mission to tackle the problem of ",
            conclusion3:" which ignites the motivation that I am interested in your advertised position as a ",
            start:"For a successful start, I probably would have to learn: ",
            startT:"Double check: Is this the gap of your skills and experience to the the job reality and requirements? Pro tip: Starting a new job will always contain company specific novelties to absorb, no matter you level of expertise in your subject. Pointing out your expectations to what those gonna be, can spark interesting insights in the interview process to your advantage.",
            learn:"In the past, I have proven that i can learn what is necessary on the job. For instance through my activities and/or experiences in ",
            learnT:"Double check: Do these activities really proof that you are capable learning the required skills? ",
            change:"What I suspect will change for me given my current understanding of the job is: ",
            changeT:"Double check: Do these differences result from your previous experiences to your expectation of what this job is going to be like?",
            preClimaxParagraph: "For a successful start, I probably would have to learn: {{start}}. In the past, I have proven that i can learn what is necessary on the job. For instance through my activities and/or experiences in {{learn}}. What I suspect will change for me given my current understanding of the job is: {{change}}.",
            wrapUpH:"Resolution",
            questions:"From my perspective, now there appears to be clarity on many aspects, but some items like ",
            questionsT:"Double check: Are these questions consistent with your narrative? Are these deal breakers or nice to haves?",
            questionMarks:" still require clarification. I could imagine that you also have questions for me regarding my ",
            questionMarksT:"Double check: Are these potential questions consistent with your provided information in your application material?",
            questionMarks1:" for example ",
            questionMarksExamples:" It is essential to determine if these unknowns align with both of our interests.",
            questionMarksExamplesT:"Double check: If you play devils advocat, is this the most obvious possible point of interest?",
            wrapup:"From my perspective, now there appears to be clarity on many aspects, but some items like {{questions}} still require clarification. I could imagine that you also have questions for me regarding my {{questionMarks}} for example {{questionMarksExamples}}. It is essential to determine if these unknowns align with both of our interests.",
            wrapup2:"I would prefer the opportunity to contribute to the realization of the company's mission promptly. To achieve this, I would like to discuss the job description in a personal interview. This way, we can address any remaining questions and relevant aspects together.",
          },
          favorites: {
            headline: "Favorite Stories",
            note: "No favorite stories yet. Find your saved favorties ",
            add: "Add to Favorite List",
          },
          learn:{
            headline:"Learn and improve your Storytelling",
            subHeadline:"Get cutting edge knowledge in few poignant steps.",
            benefit:"FAST: Go straight to the topic you care most about.",
            benefit2:"SUSTAINABLE: go step by step and question your story with the reasoning and methods introduced.",
            path:"Start learning. The path might take up to 30min",
          },
          learnSteps: {
            intro:"Introduction",
            introtext1:"To effectively create a job application, it helps to be familiar with related contexts of the job, yourself, and storytelling and effective writing concepts. But storytelling and writing alone are vast topics. How do you know when you are ready to create the best application?",
            introtext2:"Welcome to the ©Storylenses course! This beginner-friendly, example-led.course will guide you through the prerequisite knowledge for creating the story, how you are the best match for the job. You will build your story, step-by-step; starting with a job context analysis and self-analysis, migrating to a Venn Diagram and Story narrative.",
            introtext3:"Each section builds on the previous one, so you should follow the sequence from finish to end. This tutorial assumes knowledge of writing in your native language, and no knowledge of storytelling. If you’re already familiar with storytelling, we still encourage you not to skip anything and follow the sequence.",
            introtext4:"If you have questions about anything related to storytelling or this course, you're welcome to ask our community on ",
            linkText:"Discord",
            introtextcta:"Let’s get started!",
            framework:"Framework",
            frameworkGraph:"Narrative gets attention and shareability",
            frameworkGraph1:"The Frame",
            frameworkHeadline:"What is this method?",
            framework1:"This is a flexible storytelling framework that gives you building blocks to create “the” story for your job applications. But what exactly do we mean by this? Let’s spend some time expanding on what this framework and Storytelling are and how they can help",
            frameworkSubHeadline2:"Building Blocks of a Job Application",
            framework2:"There are a few things you need to consider when building job applications. Such as:",
            framework2li1:"Understanding of the job context - what context narratives are linked to you and the job.",
            framework2li2:"Understanding yourself - Qualification and motivation for the job",
            framework2li3:"How you connect - what can the job, boss, context do for you and vice versa",
            framework2li4:"Narrative - structuring the information and message you want to bring across.",
            framework2li5:"Deliverables - The formats that are required to deliver your application story",
            frameworkSubHeadline3:"What is this framework?",
            framework3:"This is a communication framework for building stories that bring your message across to your target audience. By stories, we mean the messages that the target audience sees and interacts with in an job application context.",
            framework4:"By framework, we mean it provides helpful functions to build your story pieces and structure, but leaves it up to you which pieces and what structure to include in your application. The effect of ©LIC is, that your application has an increased likelihood to get attention, that your narrative, and no one elses take, gets adopted and shared",
            framework5:"Part of this framework success is that it is relatively unopinionated about the other aspects of putting together job applications. This has resulted in a flourishing ecosystem of third-party tools and solutions that can be used in line with this process and tooling.",
            framework6:"It also means, however, that building a complete job application from the ground up requires some effort. Job applicants need to spend time filling in the questionnaire, go through a 2nd draft editorial process and run through the checklists to get the application documents and interview in line with the narrative.",
            frameworkSubHeadline7:"What is a Storytelling framework?",
            framework7:"Building a story means: defining a goal or an appeal and addressing it to a target group by means of medium, content, message, dramaturgy in order to create an effect. By framework, we mean it handles the tooling and configuration needed for an application story, and provides additional structure, features, and optimizations for building your story outputs.",
            framework8:"Whether you are a rocket scientist or sales person, you can leverage this tool to build your best job application story.In the next lessons, we will discuss how you can get started.",
            process:"The Process",
            processIntro: "Let us see what has to be done to get the best story done:",
            processLi1:"Understand WHY you need to put in the work.",
            processLi1p:"Misleading promises - It would be awesome to listen to a video on youtube or read a blog post that says: your perfect application in 3 Minutes! It would be. But this kind of stuff does not get you through the process.",
            processLi1p1:"The truth is… - “There is no free lunch on wall street” and “there are no short cuts in life” are sayings any investor on the entire planet has heard of. The same applies to crafting your story. There is no silver bullet. It’ll take effort. This is good news by the way, if it came easy, how would you outcompete other applicants?",
            processLi1p2:"Learn “Why” is all you need to get you through the process. Then walk the walk.",
            process1:"WHY",
            process2:"WHAT",
            processLi2:"The Job and the Context",
            processLi2p1:" - Analyse the job description and the job context",
            processLi2p2:" - Sharpen your axe by diving into the deep dive material",
            processLi2p3:" - Do your homework and fill in the questionnaire",
            process3:"Know THYSELF",
            processLi3:"YOU",
            processLi3p1:" - Analyse yourself, on many levels.",
            processLi3p2:" - Do your homework and fill in the questionnaires and frameworks.",
            process4:"Connect",
            processLi4: "Connect you and the job",
            processLi4p1: " - Connect where you and the Job meet",
            processLi4p2: " - Do your homework and fill in the Venn Diagram",
            process5:"Story Craft",
            processLi5:"Craft your story Narrative",
            processLi5p1:" - Putting the pieces in a narration structure",
            processLi5p2:" - Do your homework and fill in the storyline",
            process6:"Application",
            processLi6:"Create the application material based on the storyline.",
            processLi6p1:" - Write a resume",
            processLi6p2:" - Write a cover letter",
            processLi6p3:" - Select a Foto",
            processLi6p4:" - Shape your online identity",
            processLi6p5:" - Shape your Physical expression",
            process7:"Interview",
            processLi7:"The interview.",
            processLi7p1:" - enter the interview with a plan and scope.",
            processLi7p2:" - use the tools checklist to prepare your questions and deal with potential weaknesses in the best manner.",
            process8:"End and Beginning",
            processLi8:"Negotiate, sign the contract or rinse and repeat.",
            processLi8p1:" - As a result of this approach, you will value what you bring to the table, what a match it is and the shortcomings of the employer.",
            processLi8p2:" - Use your knowledge to negotiate a sustainable deal that reflects how strong your relationship with the employer is.",
            processLi8p3:" - If you decide against the offer, you exercised your application muscles and are in an even better shape for the next one.",
            why:"The WHY",
            whyH1:"Why trouble yourself with this?",
            whySubH:"Understanding the WHY",
            whyp1:"To understand how it works, we first need a basic understanding of how common biases set you up not to tell the best story and how your odds are if you let recruiters interpret your information, instead of delivering a narrative.",
            whyp2:"Next to the text, there is an image portraying just a few examples of story “lego pieces” that you and the recruiter have at your/their disposal to craft a story. In Lego terms, putting together the pieces means to build an impression of your ability to get the job done, or not. There are many more “Lego” pieces that “your application” is build upon.",
            whySub1:"It is your job to describe these Lego pieces",
            whyp3:"The “curse of knowledge” bias is one of the  reasons for this course, It describes our brains' tendency to assume everyone knows what we know. In other words, we think our Lego pieces are known “somehow”. Don’t fall for this trap in our brain. Encouraging to know that there is also the truth bias which describes people's inclination towards believing, to some degree, the communication of another person. So in other words, if you put in the work, there is a high likelihood people will believe in your story. There are numerous other mental models and knowledge supporting the importance of owning a story:",
            whyQuote1:"“There is no objective reality. Missing information is being filled in by reader/listeners experience.” (Max Wertheimer, Kurt Koffka and Wolfgang)",
            whyQuote2:"Our brains are hardwired for narrative (Carl Alviani)",
            whyp4:"For some time I kicked off every physical meeting handing the six lego pieces to the participants asking to build a duck. More than forty different people did that, not one duck was the same.:",
            whyQuote3:"The Maths behind the lego-duck-six-pieces challenge: 6 pieces ⇾ 1x2, 2x2, 2x2, 2x3, 2x3, 2x4 connection points. According to a Maths professor for a similar combination of 6 pieces 2x4 found that the number of ways to combine six two-by-four studded Lego bricks of the same colour is 102,981,500",
            whySub2:"Moral of the story",
            whyp5:"A Lego piece stands for one “dimension” of you. The combination of pieces is the “story” of you being right for the job. In your application, there are many more factors, than 6 pieces 2x4 that make up your story. So there are more than 102,981,500 possibilities that a recruiter can come up with. In this course, you will learn how to tell the strongest story of them all. ",
            whySub3:"Recommendation",
            whyp6:"Crafting your story “being the best match” is hence the approach you will learn to improve your odds, beat the missing information trap and ride the right vehicle to the recruiters brain.",
            whyGraph1:"All starts with WHY",
            whyGraph2:"Tangible “You Lego Pieces” in the application context:",
            whyGraph3:"6 Pieces of lego ⇾ Infinite results",
            context:"Context",
            contextH1:"How part 1 - Job and context",
            contextSubH:"Understanding the job context of space and time",
            contextGraph:"Story Frame",
            contextGraph1:"Job Context",
            contextp1:"Now that you know why you need to take ownership and put in the work, let’s look how to get it done.",
            contextp2:"This “ingredient”, is probably the most commonly missed material to build a job application story. Looking into context forces you from big picture to detail. This is a road pathed by hard work. However, it is worth it. Using this will serve you in many ways:",
            contextLi1:"Filter: is this really an industry, organisation, .. i want for myself. This research is saving you potentially a lot of time for jobs that you might have otherwise kept applying for “to see how it is going”",
            contextLi2:"Pro-argument in your story: The more you can in depth and breadth anchor your vision of the job and its impact the stronger you will come across",
            contextLi3:"You demonstrate that you have oversight and an eye for detail without having to tell it",
            contextLi4:"Essential jump off point: in order to explain a “match”, you need to understand “what you are matching with”!",
            contextLi5:"choosing a strong context is a something your future employer will appreciate. Use that to connect! They are in it, because they believe it their future, obviously.",
            contextLi6:"Understanding why the context is important for me and why I am important for the context… the list is literally endless and the point is clear, invest the time, and you will come up with solid building material",
            contextSubH3:"It starts with the job description",
            contextp3:"Research of the job context begins with the job description. Here you (might) get their information about megatrends, industry, organizational, job and applicant information. It might even comment on the past, present and future of the respective dimensions. What it does not give away, you need to fill in.",
            contextSubH4:"Context as “star” ingredient: Find yourself a horse to ride! ",
            contextp4:"This is the story of you choosing consciously your context dynamic. Be it a “rising, growing, future-proof” or another context that suits your narrative.",
            contextp4Quote:"“The truth is, the road to fame and fortune is rarely found within yourself. The only sure way to success is to find yourself a horse to ride. It may be difficult for the ego to accept, but success in life is based more on what others can do for you than on what you can do for yourself. “Ask what your company can do for you. Therefore, if you want to take maximum advantage of the opportunities that your career has to offer, you must keep your eyes open and find yourself a horse to do the job for you.” (From the book Positioning)",
            contextp5:"This thought alone is an excellent “highlight piece” in your story. There is further material to deepen your perception and understanding of the forces behind “connecting and understanding” someone’s situation. You can skip ahead and fill in the questionnaire without reading the deep dive material. If you however would like to “sharpen your axe”, we recommend the following two deep dives:",
            contextH6:"Expert deep dive 1:",
            contextp6:"Never Split the Difference: Negotiating as If Your Life Depended on It - The FBI hostage negotiator Chris Voss advocates in this deep dive how you can use “tactical empathy”, which is basically your understanding of what matters to “them”",
            contextH7:"Expert deep dive 2:",
            contextp7:"Spiral Dynamics: Mastering Values, Leadership and Change. This book runs you through a framework on how to assess an organisational context.",
            contextp8:"Either way, the understanding in this case means putting work into researching the contexts in the past, present and future. Guiding questions to respond in the attached sheet.",
            contextLi7:"What megatrends are affecting the job, organization, industry and how",
            contextLi8:"What industry are they in, and how is it doing?",
            contextLi9:"What organization is it, what competing and collaborating organizations are there?",
            contextLi10:"What is the context of the job in the organization, who, processes, value creation,...",
            contextLi11:"Who is your boss going to be? Where is he going?  What can he do for you?",
            contextLi12:"What is the job? Profile? (Personality), Skills, qualifications, Who is the competition, what are the other applicants like?",
            contextH9:"Deep dive to “get into the spirit”:",
            contextp9:"Inspiration lies in all kinds of places. For a different path to access the “power” of context, we recommend the following brother Grimm fairytale to get into the spirit of the power of context: Puss in Boots: While being a fairytale, puss in boots is a story about the power of understanding context, demand and leveraging those. https://www.grimmstories.com/language.php?grimm=211&l=en&r=de",
            you:"YOU",
            youH1:"How part 2, Know thyself!",
            youSubH1:"What (and who) are you?",
            youp1:"The question of what or who a person is, is really complicated. There are many aspects to “identity”. In the context of the job application, we point out the following dimensions as they define your Lego pieces in terms of:",
            youLi1:"What is your personality?",  
            youLi2:"Highlights and lowlights?",  
            youLi3:"Hard facts?",  
            youLi4:"What do others say about you?",  
            youp2:"Together, these pieces give you a solid base to craft the story of being the best match for a job. Let us look into them one by one.",
            youSubH3:"Lego Pieces Map",
            youp3:"The Lego pieces are an analogy for what you “got”. For you to realize all the things you got is a creative and analytical process that can be framed, structured and done in numerous ways. Here are different structures to get you started:",
            youp3Li1:"Personality type",
            youp3Li2:"Swot Analysis",
            youp3Li3:"Professional - Private Highlight, Lowlight",
            youp3Li4:"Facts about you - Questionnaire",
            youSubH4:"Getting a  grasp on Personality",
            youp4:"There is much to be said about learning about oneselves personality. This course recommends the following concepts to start getting a “concept” on your personality dynamics:",
            youp4BoxH:"Basic concept: Carl Jung’s 4 Personality types",
            youp4Boxp:"Recommended text book: Surrounded by Idiots: The Four Types of Human Behaviour",
            youGraph:"Carl Jung’s 4 Personality types",
            you4p1:"The concept of four colours to describe four different styles of personality: ",            
            you4p1Li1:"precise cool blue",            
            you4p1Li2:"caring earth green",            
            you4p1Li3:"sociable sunshine yellow",            
            you4p1Li4:"confident fiery red",   
            you4SubH:"Short intro on the Jung colors:",            
            you4p2:"One way to think of it is from the website: thediscoverypartnership.ie. One of the most powerful tools you can use in sales, or in any form of communications for that matter, is to understand yours and other personality types. You have a unique personality and it can be classified as how you do things, your observable behaviour or temperament.",            
            you4p3:"It is little to do with why you do things/your motivators nor has it anything to do with what you do in terms of skills, knowledge and competencies. Personality theory today is largely based on Swiss psychologist Carl Gustav Jung and his lifetimes of research and publications in this area. I have had the great privilege of being able to study and qualify in one of the world's foremost systems called Insights Discovery that is based on his work.",            
            you4p4:"I can state without contradiction that of all the skills that I have developed over the years, psychometric profiling, as it is known, is probably the most effective tool to have in one's armoury.Why is this? Research confirms that having an in-depth understanding of yourself, your strengths, weaknesses, blind spots, style preferences, how you like to learn, communicate, manage and so on, gives you control.",            
            you4p5:"That of which you are aware, gives you control. That, of which you are not aware, controls you. So, awareness is the key and psychometric profiling tools, like insights discovery, gives you that control. In its simplest format, insights discovery is generated from several hundred thousand permutations of statements and positions you on a wheel in one of 72 different locations. People are either introverted or extroverted in their behaviour. People are also either thinking types (head) or feeling types (heart). This X and Y axis approach creates four highly different styles, to which we attribute four colours.",            
            you4p6:"Think of the four colours in the article heading above. Fiery red and sunshine yellow are extrovert colours, while earth green and cool blue are introvert. Fiery red and cool blue are what we consider as thinking or head energies while earth green and sunshine yellow are warmer, friendly and emotional, in other words, feeling/heart. This is illustrated in the following slide.",            
            you4p7:"If you feel Jung’s color concept is “sufficient” to work with to use “personality” as a building block in your story feel free to skip the Enneagramm and continue with “How to use it in the story.",            
            you4SubH1:"Advanced concept: The Enneagram",            
            you4p8:"If you enjoy looking into your personality dimensions more, we recommend the Enneagramm as a next step. It is very “old” knowledge and much more complex than the Jung’s colors. However, it offers a more thorough and dynamic explanation of different personality types. Students of the enneagram have a sustained understanding of what they need to thrive and what are factors putting them on a downward spiral. Both factors serve as excellent story pieces, so we deem the deep dive into the enneagram as promising.",            
            you4p8Li1:"https://www.enneagraminstitute.com/how-the-enneagram-system-works",
            you4p8Li2:"Recommended text book: Personality Types: Using the Enneagram for Self-Discovery",
            you4SubH2:"How to use personality types in the story",            
            you4p9:"Use personality types to get an “idea” of what kind of “colours” are strongest in you and what kind of colours are “asked for” in the job description. Contrast and compare your take on it makes for a great piece of your story.",            
            you4BoxH9:"Get your color feedback:",            
            you4Boxp91:"Short route: Make a test e.g. this one is for free https://www.bemotion.nl/insights-kleurentest/free-personality-color-test/",            
            you4Boxp92:"Long route: Read the recommended book or a similar book on the four colors",            
            youSubH5Graph:"Empty Template",
            youSubH5Graph1:"Swot Matrix Mind Tool",
            youSubH5Graph2:"Example",
            youSubH5:"Swot Analysis",
            youp5:"A SWOT analysis is a strategic planning tool used to evaluate the strengths, weaknesses, opportunities, and threats involved in a project or in a business venture.  It involves identifying internal and external factors that can have an impact on the venture's success. The acronym SWOT stands for Strengths, Weaknesses, Opportunities, and Threats. The analysis is used to determine how to leverage the strengths, address the weaknesses, take advantage of opportunities, and mitigate threats. It is a useful tool for understanding a [company's] or persons position in the market, and for identifying potential areas for growth and improvement.",
            youp51:"We recommend you fill in the SWOT about yourself as one way of framing your situation regarding this job. The hope is to “extract” information that you otherwise would not have thought of.",
            you5H2:"Professional - Private - Highlight - Lowlight Matrix",
            youp52:"Ever heard of “when life gives you lemons, make lemonade”? In terms of storytelling, low and high points are equally important for a strong and, most importantly, turning narrative. Let’s look into your lows and high points. We teach you to appreciate them in a later chapter: step 5 narrative. For now what you need to know about both low and highlights.",
            youp52Li1:"A low and a high point are both a status of a situation",
            youp52Li2:"Status changes create attention. In short, our brains are wired to a) recognise and b) pay attention status changes.",
            youp52Li3:"Attention is the single most important pre-requisite for the “recipient” to get your message.",
            youp53:"You want that attention in your readers,listeners! So let’s hunt for low and highlights. We teach you in step 5 how to use these in your narrative. The matrix structure is there to get you thinking about these seemingly disconnected storytelling dimensions when it comes to job applications: private professional and low and highlights.",
            youp54:"Hold on? I get the “attention bit” but should a job application not be just a stellar record of “wins” and “perfectionist performance”? A super human record and nothing less? Well, … no.The recruiter knows much like you do, life is not a straight line. As Goethe famously put: “You will recognise them by the fractures”. For now, let’s just establish that the real power of your story lies in the moments of extreme (low,high) status, where a status changed, how did you respond and what did you get out of it. Let’s fill in the matrix to get us this highly precious material.",
            youp55:"As with everything, practice makes the master. Let’s look at a few examples what kind of low lights or highlights we could start with to get the creative juices flowing and train our perception.",
            youSubH6:"Questionnaire: Facts about you",
            youSubH6Graph:"Template:",
            youp6:"Now comes the easy bit: let’s write down the plain facts that matter in the job context:",
            youp6Li1:"Personal",            
            youp6Li2:"Origin, background",            
            youp6Li3:"Lifestyle",            
            youp6Li4:"Education",            
            youp6Li5:"Work, professional experience",            
            youp6Li6:"Goals",            
            youp6Li7:"References", 
            youp61:"To help you with this, we made a template that you literally just have to fill in. The document in great depth and breadth contains the following points:",
            you62Li1:"Contact Information: Where do you live physically and virtually? How are you reachable?",           
            you62Li2:"Hard facts: Where are you from? What is your cultural background?",           
            you62Li3:"Objective: What goals or dreams do you have? What drives you? Whats your purpose? What are your highlights? What are your qualifications and career goals?",           
            you62Li4:"Education: What education level have you got? What degree(s), field of study, and any relevant coursework or honours?",           
            you62Li5:"Work Experience: What professional experience have you got? A list of your previous employment, including job titles, company names, and dates of employment. What responsibilities, learnings and achievements have you got? Are you qualified for outlined in the job ad? Or in other terms: Have you done something similar to the work outlined in the job ad? Who in that organisation can we talk to about your performance?",
            you62Li6:"Skills: A list of relevant skills, including technical abilities, language proficiency, and certifications. What skills have you got that might be useful for this job or our organisation? Can you learn?",           
            you62Li7:"Accomplishments: A list of notable achievements, such as awards, publications, or other honours. What do you excel in? Do you compete in something?",           
            you62Li8:"Livestyle and Lifeshaping experiences. Do you do sth for your community? E.g. Volunteer experience, training, teaching,... Hobbies and interests? Courses, relevant projects,.. Professional memberships? Clubs, subscriptions, associations,...",
            connect:"Connect",
            connectH:"Connecting. The. Matching. Pieces.",
            connectSubH:"",
            connectGraph:"Generic VENN Diagramm",
            connectGraph1:"Application Context VENN",
            connectp:"The above image is from the legendary 80s series “The A-Team”. In short, the A-Team is crew of vietnam veterans who back home in the US fight for justice for the little man against bullies and villains. It’s captain Hannibal is the “strategist” who plans how to go about achieving their goals by coordinate action of multiple steps and diverse operational streams. Every episode ends with him lighting a cigar saying: “I love it, when a plan comes together”. Exactly this “coming together” is where we are at now.",
            connectSubH1:"Who is coming?",
            connectp1:"Let’s look at what you brought to the table. You have:",
            connectp1Li1:"Researched the job, and the personality they are looking for",
            connectp1Li2:"Organisation",
            connectp1Li3:"Industry",
            connectp1Li4:"Megatrends",
            connectp1Li5:"Competition",
            connectp1Li6:"Your personality",
            connectp1Li7:"Your strengths, weaknesses, opportunities and threads in regards to the job",
            connectp1Li8:"Your private and professional low- and highlights",
            connectp1Li9:"Got your personal facts straight",
            connectp2:"The method to structure the connections is the Venn Diagram.",
            connectSubH4:"Venn Diagram of your application context",
            connectp4:"Let’s first understand what a Venn diagram is and what sets of items we got. A Venn diagram is a graphical representation of the relationships between sets of items. It consists of circles, with each circle representing a set. The area where the circles overlap represents the items that belong to both sets. Venn diagrams are used to visually organize and compare sets of data. They are often used in mathematics, statistics, and logic to illustrate the relationships between sets.",
            connectSubH5:"Sets of items",
            connectp5:"Let’s see which sets we are working with: A - You | B - Job | C - Bigger context",
            connectp5y:"A - You",
            connectp5j:"B - Job",
            connectp5b:"C - Bigger context",
            connectp5Li1:"Your personality",
            connectp5Li2:"Your strengths, weaknesses, opportunities and threads in regards to the job",
            connectp5Li3:"Your private and professional low- and highlights",
            connectp5Li4:"Got your facts straight",
            connectp5Li5:"Researched the organisation behind the job",
            connectp5Li6:"and the personality they are looking for",
            connectp5Li7:"Organisation",
            connectp5Li8:"Industry",
            connectp5Li9:"Megatrends",
            connectp5Li10:"Competition",
            connectSubH6:"Tooling to facilitate",
            connectp6:"We have created a Matrix to help you structure the overlaps between the three different sets.",
            connectp6BoxH:"Action: fill in your research notes ",
            connectp6BoxP:"Copy the embedded Google Sheet to take your notes for all above mentioned points.",
            connectCTA:"Checkout helper sheet",
            connectGraphHelper:"Helper Sheet",
            narrative:"Narrative",
            narrativeH:"Storytelling",
            narrativeSubH:"Show, don’t tell - if you cant explain it, show it",
            narrativeP:"A story conveys a message. It is not scientific or archival, but dramaturgical work. It's about tension and resolution!",
            narrativeH1:"The Five Acts according to Freytag",
            narrativeP1:"The five-act structure specifies even more precisely what, when and how something happens in the plot. The German playwright and novelist Gustav Freytag discovered this plot pattern in dramas by Shakespeare and in ancient Greece. ",
            narrativeP2:"1 - Exposition: (Genesis) The exposition again sets out all the main characters, players and antagonists, locations, and the trigger for the conflict. However, this does not always have to be obvious. ",
            narrativeP3:"2 - Action climax: (Nemesis)Further complications are added which prevent the resolution of the conflict and set the plot in motion. ",
            narrativeP4:"3 - Climax: (Zenith/Höhepunkt) The climax is the most exciting part of the story. As with the three- and four-act play, the plot and conflict are brought to a head. ",
            narrativeP5:"4 - Decline of action: (Katastrophe)The tension is gradually reduced, and after-effects of the climax are revealed. ",
            narrativeP6:"5 - Denouement/resolution: (Katharsis) After the plot falls away, the conflict is finally resolved. Sometimes a moral of the story or a lesson is conveyed. ",
            narrativeH2:"Three-act Play",
            narrativeP7:"1. There are further act-systems in play and narration, e.g. 5 and even more acts. Hence, for your performance within your application goal we suggest the 3-act-play because it goes fast and straightforward and saves time. Thus, the 3-act play works for any of the below mentioned ten types of story-telling.",
            narrativeP8:"2. Given that Freytag’s Pyramid always works, especially in a compact 3-act play, the question whether my story should be consistent, tenseful and resolve into relief is already answered: Yes! ",
            narrativeP9:"But, what remains to be defined is your set up regarding the problem (lack of knowledge, lack of team capacity, lack of management etc. - or in other words, WHY shall somebody work with this company, why is the recruiter searching for employees.This means that the Introduction and moreover the achieved relief of the problem/conflict almost stays the same for all mentioned 10 story types - but the problem/conflict varies. So, you must identify the current problem/conflict!",
            narrativeP10:"3. The set up mentioned above consists - in our context, meaning your goal to achieve the employment - in recognizing which kind of problem, conflict, urge, goal your targeted  company, recruiter, team… is situated right at the moment when the Job advertisement has been published and/or when you have received your Job interview.",
            narrativeH11:"Note",
            narrativeP11:"Often, not always, there is a time gap between job advertisement and job interview that may lead to different expectations on the side of the hr management, therefore to different set-ups when you read the job advertisement firstly and secondly when you arrive to the job interview.",
            narrativeH12:"Conclusion",
            narrativeP12:"For catchy, quickly receivable and simpler messages, e.g. in blogs, presentations or newsletters, the three classic levels of action mentioned before are usually sufficient. These can be structurally divided into:",
            narrativeP12Li:"an introduction, ",
            narrativeP12Li1:"a main section ",
            narrativeP12Li2:"and a resolution. ",
            narrativeP13:"In literature, film and theatre studies, this pattern according to the pyramid of tension by Gustav Freytag is referred to as a three-act or epic three-act structure. This proves to be extremely functional and is therefore used equally in plays, novels, commercials, blog articles and concerts, among others.",
            narrativeP14:"Just as a main clause consists of a predicate and a subject, any story should have at least three acts in which it is structured! ",
            narrativeP15:"This classic way of storytelling gives you a lot of room for plot, perspective and creation. Since you need less time and effort here than in a five-act or even (quite rare)  7-act story, you can move forward quickly - and the story is easy to remember. ",
            narrativeIntroH:"Introduction",
            narrativeIntroP:"At the beginning of your story, introduce and describe your protagonists, their motives, the place, time and the primary conflict/the central question. Use the below checklist to get started:",
            narrativeIntroP1:"1. The Protagonists: (Name of the company and its ecosystem, meaning their leading personnel, such as CEO, the team, managers, competitors, clients, contributors etc.",
            narrativeIntroP2:"2. The treasure (the company’s product, service, business case, business mission",
            narrativeIntroP3:"3. The stage (the market) What is the current and, if possible, what was the past  and what should be the upcoming market situation?",
            narrativeIntroP4:"4. The problem (the protagonist’s burden):  e.g.: the CEO does not find successors, the team is too young and inexperienced, the prices for resources are getting expensive, the clients demand more service etc. Note here: These problems are annoying to any company's team, but not something special, just daily business. The CONFLICT to solve appears in the main part.",
            narrativeMainH:"Main part",
            narrativeMainP:"As the plot progresses, the conflict becomes more and more acute. How is the path for solution, then? How do you solve the problem? Which role are you choosing for your path, your way of solution? Usually the daily business, already filled with typical obstacles such as clients who vanish, bills that are not paid, problems in the supply chain, staff members who retire etc. there is a combination of new and/ or known problems:",
            narrativeResolutionH1:"Resolution/conclusion",
            narrativeResolutionP1:"Towards the end, your protagonist/main character manages to solve the conflict/problem and thus overcomes the case or the low point. Here you have to end or answer open questions and plot lines that you have introduced in the first two acts of your story in order not to leave your readers, listeners, viewers out in the cold and provide conclusive resolution. ",
            narrativeResolutionP2:"This should not be too overloaded, because the end should also be the end, unless you are working on a series - in which case a cliffhanger would be a good idea. But even that should end tightly here! This explains why in many stories a manageable number of characters are introduced, because their resolution costs too much tension, space and time at the end, i.e. budget.",
            narrativeResolutionP3:"In our case, your goal, achieving the job it means that you reveal answers for future obstacles or and what you might need to solve the problem/the conflict (technology, gear, team, time….) Are there upcoming obstacles that you already could mention? What is from your side important to organize, before the possible job starts, e.g. moving into another city, finishing your current contract, learning a special skill like another language etc.",
            narrativePatternH:"Select a genre",
            narrativePatternP:"Now, all that is left for you to get started is to decide, which of the ten story patterns you as a protagonist who might and would solve this bunch of problems fit bests? Here, as follows below, are presented the 10 epic guys according to Blake Snyder for successful story genre work. ",
            narrativePatternH1Box:"Note",
            narrativePatternP1Box:"No matter what genre your writing belongs to or whether you are designing a blog for marketing - as soon as you create narration, i.e. apply storytelling, you will use one of the mentioned narrative patterns and genres.",
            narrativePatternH2:"Example: Job role and Genre Selection",
            narrativePatternP1:"Depending on the intended message, problem and objective, different stories often work for the applicant e.g..:",
            narrativePatternH1Table:"Job Role",
            narrativePatternH2Table:"Story Genre",
            narrativePatternP1TableC1:"Project Manager",
            narrativePatternP1TableC2:"Whydunit",
            narrativePatternP1TableC3:"Expert",
            narrativePatternP1TableC4:"Genie in the bottle",
            narrativePatternP1TableC5:"Team Addition",
            narrativePatternP1TableC6:"Golden Fleece or Road Story ",
            narrativePatternP1TableC7:"Manager, Engineer",
            narrativePatternP1TableC8:"Guy with a Problem",
            narrativePatternH3:"The 10 Story Genres according to Blake Snyder",
            narrativeGenre1H:"1. Guy with a problem",
            narrativeGenre1P:"This genre includes everything from films like Die Hard to Titanic to Schindler's List. The stories are always about a guy with a problem. But this particular genre prescribes a particular kind of problem: Namely, one that is usually a matter of life and death and must be solved through some kind of physical struggle, and immediately! These stories are essentially a chronicle of that struggle (which may consist of a series of mini-battles). Think Die Hard, Bourne Identity, Misery, 2012 or Apollo 13.",
            narrativeGenre1P1:"The pattern in a guy with a problem is in almost any case driven by an a) extreme and b) decisive situation. Therefore a third problem appears, usually c) lack of time and/or money. But in many cases regarding a job opportunity it’s time. ",
            narrativeGenre1P2:"The negative aspect for you in this sort of job-application is that you have to act immediately, and you can not deal with prepared aspects like “In the future your company should do this and that, and I can contribute with this and that… - NO - you’ve got to start right now and then.",
            narrativeGenre1P3:"But the positive aspect is, that you have a  - once in a lifetime opportunity to show your skills from scratch and to bust into the company with the role and title “Solution Provider” which saves you from starting on a low scale, spending many years to develop yourself within this company - no, you start immediately and directly in front of the management team, delivering a solution thanks to experience, creativity and business network, following our example above e.g. You start as a rocket, both physical and intellectual, to cope with “the guy’s (the manager’s) problem”.",
            narrativeGenre1H1Box:"In short: This pattern can be characterized as “Hurry up!”  The formula for your story climax is: Guy with a problem =  hurry up!",
            narrativeGenre2H:"2. The Golden Fleece",
            narrativeGenre2P:"This genre is often referred to as catch-all when no other fits. But this genre also has its own specific requirements that must be met in order for it to really work. The key is that the main character's 'team' is chasing a very clear and definable 'prize' that seems unattainably difficult. The specificity of the prize is important for the arc of suspense: you know the film/story is over because they have achieved the prize - or not. Often in novels, epics or screenplays that claim to be a 'fleece', the 'prize' is too ambiguous or not big or challenging enough; then, unfortunately, the journey there doesn't become as compelling as it could be. Think Star Wars, Back to the Future, Finding Nemo, Saving Private Ryan, Ocean's Eleven or Cast Away.",
            narrativeGenre3H:"3. Buddy love ",
            narrativeGenre3P:"This genre refers to both love stories and friendships between two characteristically different partners such as the successful actor duos Stan Laurel & Oliver Hardy, Terence Hill & Bud Spencer, Walter Matthau and Jack Lemmon etc..  Cop and partner films as well as successful duo podcasts or TV programmes such as late-night shows are also part of this, think of the figure of the -sidekick- in mischievous opposition to the host of the show. Note: 'Buddy Love' is not a relationship with problems. Unless the main problem of the film has to do with a key relationship that seems to be essential to the main character and that is threatened by something. -Will they get together or not?- is the central question of the story and the main problem is explored throughout the film, see Don Quixote & Sancho Panza, Tristan & Isolde, Starsky and Hutch, Mr. & Mrs. Smith.",
            narrativeGenre4H:"4. Whydunit / institutionalised",
            narrativeGenre4P:"The 'institution' in the sense of an authority, for example, can, but does not have to be taken literally. The question is whether there is a group with its own rules and norms in which the main character explores the costs and benefits of membership - and finally decides whether to belong or not: 'Whydunit' (= US-English slang for 'How it became what it is') It is about deciding who these characters want to be in their relationship to this group, and about the risks and benefits involved. So an institution can also be the publishing industry, a gang of smugglers, the mafia or an undertaker's firm; think here of series like Six Feet Under, The Ozarks, films like Full Metal Jacket, Goodfellas, The Devil Wears Prada or novels like 'The Magic Mountain', by Thomas Mann or 'L.A. Confidential' by James Ellroy. ",
            narrativeGenre5H:"5 Rites of Passage",
            narrativeGenre5P:"The criteria for this genre is not simply met with a character in some kind of rite of passage, e.g. a graduation ceremony. It is crucial that it is an understandable life problem (such as: puberty, divorce, mid-life crisis, loss of a loved one or addiction) that the main character avoids by chasing something else! These characters are clearly on the wrong track as they spend most of the story chasing a challenging goal that is entertaining to watch but ultimately does not end well. In the end, the protagonists have to face the seriousness of life and hopefully learn something in the process. Think of the novel 'The Mysteries of Pittsburgh' by Michael Chabon or 'The Catcher in the Rye' by J.D. Salinger or films like 10, Trainspotting or American Pie.",
            narrativeGenre6H:" 6. Superhero",
            narrativeGenre6P:"The key here is a nemesis and a problem seemingly bigger than the protagonists themselves. It's never exciting to read or see amazing people (real life or invented) succeed over and over again. Good stories are always about the characters being pushed to their limits and overwhelmed - basically descending into hell - until the end. Remember: stories are always about overcoming big problems that only get worse when you try to overcome them - or not overcome them. Remember our aforementioned example with the snake in Neander Valley. Coping also applies to individual episodes, chapters, blog sections, scenes. That's the main problem writers face with almost any story is making sure it's a compelling enough problem, difficult enough, complicated enough to fill an entire story. Think of novels like 'Dracula' by Bram Stoker, 'Frankenstein' by Mary Shelley, 'Dr. Faust - A Tragedy' by Johann Wolfgang von Goethe, but also films like Erin Brockovich, the Harry Potter saga series, The Matrix, Gladiator or Spider-Man. ",
            narrativeGenre7H:"7. genie out of the bottle / Deus ex Machina",
            narrativeGenre7P:"A magic object, a 'magical' catalyst evokes complications and challenges that would never have existed without it. Again, they make the hero's life more difficult, in a way that demands resolution. Usually it is easier for readers to digest if the magic arises from a comprehensible, semi-explainable (i.e. not too arbitrary or contrived) reason, such as a wishing machine at the fair, a thunderstorm, or an established mythology, such as ghosts or witchcraft. And the magic should disappear or be resolved at the end, so that the main character can return to a largely 'normal life' that he or she can cope with in some way. Think of stories like Aladdin and the Magic Lamp, 'Tischchen Deck Dich (Gebrüder Grimm) or the films Mad Professor, Bruce Almighty and the US 1960s TV cult series Charming Jeannie. ",
            narrativeGenre8H:"8. Triumphant fool ",
            narrativeGenre8P:"This genre also refers to an old and successful type of storytelling, also called the picaresque novel in German literary history, see the baroque picaresque novel 'Simplicissimus Teutsch' (published in 1669) by Hans Jakob Christoffel von Grimmelshausen, the 'Kater Murr' by Ernst Theodor Wilhelm (E. T.A.) Hoffmann, or the more recent picaresque novel 'The Tin Drum', by writer Günter Grass, which was filmed by director Volker Schlöndorff. In both picaresque novels, the inconceivable horrors of war, be it the Thirty Years' War (Simplicissimus) or the First and Second World Wars (Tin Drum), are told from the naïve detachment of a child or prankster/fool who is allowed the luxury of mockery and biting questions, since they do not face any responsibility. ",
            narrativeGenre8P1:"This genre depends and stands with the characters, it needs credible protagonists who can transport the role of the prankster or fool and who are anchored in an appropriate context (family, crisis, war, royal court...). Already in early cinema, these characters were embodied by actors like Buster Keaton, Harold Lloyd, Charles Chaplin. Today, Jim Carrey (The Riddler), Heath Ledger, Joaquin Phoenix (Joker) stand out in the Bat Man series. Overall, the Bat Man protagonist lives strongly through the antagonist of the Joker (Narrs), because despite or because of his viciousness, the Joker is often the one who speaks the truth. Mythical models for the often transcendent quality in the figure of the Jester towards the anti-hero or dark god can be found in all the gods' sagas, from the",
            narrativeGenre8P2:"Norse myth world and the figure of the god Loki, to Greek antiquity and the figures of Pan, Bacchus and Prometheus, to the Bible (the archangel Lucifer). It is the dialectic of provocation, chaos and truth that makes the figure of the Fool so powerful in storytelling and, especially in chaos (war, crisis), speaks truths that no one wants to hear, but which contribute to order out of chaos. ",
            narrativeGenre9H:"9 - Incarcerated",
            narrativeGenre9P:"This genre is mostly about what it sounds like: namely, groups of originally different people who henceforth lead a common destiny in a space decoupled from everyday life with fixed, restrictive rules. How, when and whether they find their way out and, above all, find their way together, fail or win, and in the process either make use of new realities and/or former skills from their old lives, is ultimately not as exciting as the actual events that carry the suspense: The unsolvable task the group is faced with is to solve, to 'descend into hell', believing in the better life after the struggle.  A classic is the story (novel: Ken Kesey/film: Milos Forman) 'One Flew Over the Cuckoo's Nest', novel (Mario Puzo) and film (Francis Ford Coppola) 'The Godfather', in a way novel (Herman Melville) and film (John Huston) 'Moby Dick', Moby Dick being a true masterpiece of many genres.",
            narrativeGenre10H:"10 - The monster in the house",
            narrativeGenre10P:"Honestly, this is the oldest story, but one that always works, especially in feature films. We know it from our childhood days, be it the fairy tales of the Brothers Grimm, the werewolf stories or, as adolescents, the typical 'teen shockers' like Nightmare on Elm Street, Helloween, Scary Movie. But adults also like to get spooked and see who, if, when and how this all-powerful monster will be eliminated from the world: see the series Alien, Jaws, Panic Room or novellas like 'Dr Jeckyll & Mr Hyde' by Scottish author Robert Louis Stevenson or the thrillers 'Shining' and 'It' by author Stephen King, both of which were also made into films. ",
            narrativeToolingH:"Tooling to support",
            narrativeToolingP:"The StoryLenses Create Story flow creates the structure for you. Go through the Questionnaire Flow and you receive the narrative in the structure taught in this chapter.",
            material:"Application",
            materialH:"Create Application Material",
            materialP:"The Story serves as your North star and means to create the actual application material.",
            materialGraph:"Final Process Step",
            materialH1:"Tooling to support",
            materialP2:"The StoryLenses Create Story flow creates an application material checklist. Go through the Questionnaire Flow and you receive the following checklists:",
          },
        }
      },
      pt: {
        translation: {
          login: {
            title: "Form",
            email: "Insira o e-mail",
            password: "Insira a senha",
            signup: "Não tem uma conta? Cadastre-se",
            remember: "Lembrar-me",
            forget: "Esqueceu a senha?",
            reset: "Confira seu e-mail para redefinir sua senha e começar sua história!"
        },
        nav: {
            login: "Login",
            register: "Registrar",
            logout: "Logout",
        },
        subnav: {
            create: "Criar Histórias",
            job: "Candidatura de Emprego",
            saved: "Salvo",
            favorites: "Histórias Salvas",
            knowledge: "Conhecimento de Histórias",
            course: "Curso Online",
            ebook: "E-Book",
            cta: "Melhore sua História",
            button: "Iniciar Curso Online",
        },
        lp: {
          headline: "Obtenha",
          headlineBold: "sua história,",
          headline2: "ser",
          headlineBold2: "ouvido.",
          herotext: "Você está procurando emprego? Bem-vindo ao Story Lenses, a primeira ferramenta que conta sua história:",
          herotext2: "Sua própria história, estruturada profissionalmente para aplicações, currículos, negociações e entrevistas.",
          cta: "Comece agora",
          ctaContact: "Contate-nos",
          featH1: "Recursos",
          featH2: "O que você recebe",
          featH3: "Receba sua história personalizada: adaptada a você, ao seu perfil e à sua situação. Em formato de história profissional. Para ser compreendido e ouvido. Adequado para todos os aspectos da candidatura, entrevista - e além...!",
          feat1H: "Criador de Histórias",
          feat1P: "Contação de histórias profissional que atrai o ouvinte, cativa, atende aos requisitos de conteúdo do público-alvo e mantém a atenção o tempo todo.",
          feat2H: "Aprimoradores de Histórias",
          feat2P: "Questionários guiados, ferramentas de suporte, ajuda gramatical, aprimorador de linguagem, guia de estilo, suporte a vários idiomas, e-book e conselhos de especialistas para transmitir tudo o que é importante.",
          feat3H: "Guia de Aplicação",
          feat3P: "A história é a base para tudo; comunicação, seus documentos de inscrição, especialmente a carta de apresentação e a entrevista de emprego. Tudo para que você seja ouvido e reconhecido.",
          stepsH1: "PASSOS",
          stepsH2: "Fácil de começar.",
          step1H: "Registrar",
          step1D: "Crie uma conta com seu e-mail em 30 segundos.",
          step2H: "Pagamento",
          step2D: "Assine e comece com um teste gratuito de 7 dias.",
          step3H: "História pronta",
          step3D: "Siga o fluxo, responda às perguntas e revise seu texto até a perfeição com as dicas do Story Lenses.",
          pricingH1: "Preços",
          pricingH2: "Plano Flexível",
          pricingH3: "Comece com um teste gratuito e assine mensalmente pelo tempo que usar a ferramenta.",
          pricingMo: "Mês",
          pricingM: "Mensal",
          pricingYo: "Ano",
          pricingY: "Anual",
          pricingButton: "Compre agora",
          pricingPlan: "Plano de Criação de Histórias",
          pricingPlanDesc: "Indicado para candidatos a emprego",
          pricingPlanD1: "Criador de Histórias de Aplicação",
          pricingPlanD2: "Ebook sobre Contação de Histórias",
          pricingPlanD3: "Suporte a vários idiomas",
          pricingPlanD4: "Sem restrição de uso",
          pricingPlanD5: "Suporte",
          testimonials: "Depoimentos",
          testimonialsH: "Nossos Usuários nos Amam.",
          testimonialsD: "Candidatos de todo o mundo nos elogiam. Tivemos o prazer de apresentar alguns deles aqui.",
          testimonialsH1: "Base sólida",
          testimonialsD1: "Eu tinha acabado de me mudar do Sul da América para a Europa e não tinha experiência com candidaturas a empregos, então eu estava procurando um guia de como fazer isso. O aplicativo foi incrivelmente fácil de usar e entregou resultados rapidamente. Usei a história como base para minha carta de apresentação, estrutura de currículo e narrativa de entrevista. Recebi feedback imediato em todas as candidaturas e, finalmente, uma oferta de emprego, que aceitei com prazer.",
          testimonialsName1: "Danielle Silva",
          testimonialsPosition1: "Vendas, H&M",
          faqH: "Perguntas Frequentes",
          faqD: "Esta é uma ferramenta e abordagem inovadoras. Claro que há perguntas. Aqui estão as mais comuns.",
          faqH1: "Por que você recomenda não usar o primeiro rascunho?",
          faqD1: "Em resumo, enquanto um primeiro rascunho é um ponto de partida necessário, é apenas o início do processo de escrita. A ferramenta já é projetada para encurtar esse processo. No entanto, revisão e edição são etapas cruciais para refinar e aprimorar o trabalho antes de compartilhá-lo com uma audiência, garantindo que atenda às expectativas do escritor e da audiência. A ferramenta é projetada para ajudá-lo a direcionar a atenção para verificar a consistência e fornece recursos para ajudá-lo a refletir para um impacto ainda maior.",
          faqH2: "Como uso a história no meu processo de inscrição?",
          faqD2: "A história é a narrativa básica que contém as informações relevantes sobre quem você e a empresa oferecedora de emprego são e o que você está procurando. Ela descreve os destaques e desafios potenciais, bem como um caminho de resolução. O escopo e o conteúdo servem para a busca de emprego (Isso é realmente o que eu quero?), o material de inscrição (O que dizer na carta de apresentação, em que focar no currículo) e, o mais importante, como narrativa para a entrevista verbal.",
          faqH3: "Posso obter feedback sobre minha história?",
          faqD3: "Absolutamente. Oferecemos um serviço de feedback de 48 horas por uma taxa única baixa em sua carta de apresentação, currículo e história.",
          faqH4: "Esta é uma ferramenta poderosa, por que é tão barata?",
          faqD4: "Construímos isso para ajudar as pessoas a obterem a melhor chance para o emprego que desejam. Um preço baixo é literalmente a maneira, na nossa opinião, de torná-lo o mais acessível possível.",
          faqH5: "Em que isso é baseado?",
          faqD5: "Story Lenses é um sistema que cria a arquitetura certa para sua história com base em modelos que foram transmitidos e usados com sucesso desde as primeiras sagas, tragédias gregas, a Bíblia e a impressão. Padrões de história de autores profissionais, diretores de palco e mídia, e contadores de histórias, como o roteirista e dramaturgo Blake Snyder. Muitos desses padrões de história são baseados principalmente na mitologia grega e são 'à prova de balas' e testados milhões de vezes, pois foram profissionalmente utilizados na mídia, publicação, cinema e negócios de roteiros.",
          faqH6: "Quem construiu isso?",
          faqD6: "Somos uma equipe de profissionais de cinema, mídia, publicação e desenvolvimento de software unidos para ajudá-lo a contar a melhor história que você tem.",
          valueH: "A HISTÓRIA ESTÁ DENTRO DE VOCÊ!",
          valueH1: "Hora de tirar isso...e mais....de dentro!",
          valueD: "Você não precisa reinventar a roda para escrever, não precisa aprender contação de histórias com 100 livros e cursos: siga as instruções do Story Lenses - e você terá tudo o que precisa, uma história completa.",
          valueH2: "Muito Acessível",
          valueD2: "Queremos tornar a ferramenta acessível a todos! Simples. Em qualquer lugar. A qualquer momento. Para qualquer tipo de aplicação, entrevista ou história. E: Os primeiros 7 dias são gratuitos.",
          valueH3: "Uma história vai longe",
          valueD3: "Uma história realmente bem contada, autêntica, convincente e emocionante vai longe - e dura muitas oportunidades: Pegue sua história e use-a a partir de agora para todos os documentos de inscrição relevantes: carta de apresentação, currículo, entrevista...",
          valueCTA: "Comece agora",
          ctaBoxH: "Está pronto para sua história?",
          ctaBoxD: "Tente.",
        },
        terms: {
          h1: "Última Atualização: 08.01.2024",
          p1: "Bem-vindo ao StoryLenses.app! Por favor, leia atentamente estes Termos e Condições antes de utilizar nosso site e serviços.",
          h2: "1. ACEITAÇÃO DOS TERMOS",
          p2: "Ao acessar ou usar o StoryLenses.app ('Website'), você concorda em cumprir e estar vinculado a estes Termos e Condições. Se você não concordar com qualquer parte destes termos, não poderá usar nossos serviços.",
          h3: "2. USO DOS SERVIÇOS",
          p3: "O StoryLenses.app oferece uma ferramenta online para criar histórias personalizadas e um curso online. Ao utilizar nossos serviços, você concorda em:\n- Pagar a taxa única de $1.99 USD para acessar a ferramenta de criação de histórias personalizadas.\n- Pagar a taxa única de $49.99 USD pelo serviço de leitor.\n- Reconhecer que a ferramenta de aprimoramento de inscrições de emprego é fornecida para melhorar as taxas de sucesso, mas não há garantias de colocação profissional.",
          h4: "3. PAGAMENTOS",
          p4: "Todos os pagamentos pelos serviços fornecidos pelo StoryLenses.app são processados de forma segura. As taxas não são reembolsáveis, a menos que especificado de outra forma. Ao efetuar um pagamento, você concorda com as cobranças especificadas.",
          h5: "4. PROPRIEDADE INTELECTUAL",
          p5: "Todo o conteúdo e materiais fornecidos no StoryLenses.app, incluindo, mas não se limitando a texto, gráficos, logotipos, imagens, áudio e vídeo, são propriedade do StoryLenses e estão protegidos por direitos autorais, marcas registradas e outras leis de propriedade intelectual.",
          h6: "5. ISENÇÃO DE RESPONSABILIDADE",
          h7: "6. LIMITAÇÃO DE RESPONSABILIDADE",
          p7: "O StoryLenses.app e suas afiliadas não serão responsáveis por quaisquer danos diretos, indiretos, incidentais, especiais ou consequentes decorrentes ou de alguma forma relacionados ao uso de nossos serviços.",
          h8: "7. POLÍTICA DE PRIVACIDADE",
          p8: "Sua privacidade é importante para nós. Consulte nossa Política de Privacidade para obter informações sobre como coletamos, usamos e divulgamos informações pessoais.",
          h9: "8. MODIFICAÇÕES",
          p9: "O StoryLenses.app reserva-se o direito de modificar estes Termos e Condições a qualquer momento. As versões atualizadas serão postadas no Website, e seu uso contínuo constitui aceitação dos termos modificados.",
          h10: "9. LEI APLICÁVEL",
          p10: "Estes Termos e Condições são regidos e interpretados de acordo com as leis da Alemanha, União Europeia.",
          h11: "10. CONTATE-NOS",
          p11: "Se você tiver alguma dúvida ou preocupação sobre estes Termos e Condições, entre em contato conosco pelo info@storylenses.app."
        },
        privacy: {
          h1: "POLÍTICA DE PRIVACIDADE",
          p1: "Última Atualização: 08.01.2024",
          p2: "Esta Política de Privacidade delineia como o StoryLenses.app ('nós', 'nos' ou 'nosso') coleta, usa e protege suas informações pessoais. Ao usar nosso site e serviços, você consente com as práticas descritas nesta política.",
          h2: "INFORMAÇÕES QUE COLETAMOS",
          p3: "1. **Estatísticas de Usuários Anônimos:** Utilizamos Heap.io para rastrear estatísticas anônimas de usuários para analisar o comportamento do usuário e aprimorar nossos serviços. Esses dados são coletados de forma agregada e não identificam pessoalmente indivíduos.",
          p4: "2. **Dados de Pagamento:** Não armazenamos dados de pagamento em nosso banco de dados. Apenas retemos o status de pagamento de nossos clientes que efetuaram o pagamento.",
          p5: "3. **Informações de Fatura:** Com a finalidade de fornecer faturas, armazenamos o nome, endereço e endereço de e-mail de nossos clientes que efetuaram pagamentos.",
          h3: "USO DAS INFORMAÇÕES",
          p6: "Utilizamos as informações coletadas para os seguintes fins:",
          p7: "- **Melhoria de Serviços:** Estatísticas de usuários anônimos nos ajudam a entender como os usuários interagem com nosso site e aprimoram a experiência do usuário.",
          p8: "- **Status de Pagamento:** Utilizamos dados de pagamento para gerenciar e rastrear o status de pagamento de nossos clientes.",
          p9: "- **Faturamento:** Nome, endereço e endereço de e-mail são usados para criar e enviar faturas.",
          h5: "SEGURANÇA DA INFORMAÇÃO",
          p10: "Comprometemo-nos a garantir a segurança de suas informações. Embora nos esforcemos para proteger suas informações pessoais, nenhum método de transmissão pela internet ou armazenamento eletrônico é completamente seguro.",
          h6: "DIVULGAÇÃO A TERCEIROS",
          p11: "Não vendemos, trocamos ou transferimos de outra forma suas informações de identificação pessoal para partes externas. Isso não inclui terceiros confiáveis, como Heap.io e Stripe Payment Service, que nos auxiliam na operação de nosso site e condução de nossos negócios, desde que concordem em manter essas informações confidenciais.",
          h7: "SEUS DIREITOS",
          p12: "Você tem o direito de acessar, corrigir, atualizar ou excluir suas informações pessoais. Se você tiver alguma preocupação ou solicitação em relação aos seus dados, entre em contato conosco em [Suas Informações de Contato].",
          h8: "ALTERAÇÕES A ESTA POLÍTICA DE PRIVACIDADE",
          p13: "Reservamo-nos o direito de atualizar ou modificar esta Política de Privacidade a qualquer momento. A versão mais recente será postada no site com a data efetiva. O uso contínuo de nossos serviços após as alterações constitui aceitação da política atualizada.",
          h9: "CONTATE-NOS",
          p14: "Se você tiver alguma dúvida ou preocupação sobre esta Política de Privacidade, entre em contato conosco pelo info@storylenses.app.",
          p15: "Obrigado por confiar no StoryLenses.app com suas informações."
        },
        home: {
          mainHeadline: "Vamos Criar Sua História",
          subHeadline: "Dicas para o Sucesso",
          description: "",
          herostory: "O tipo perfeito de história para uma inscrição de emprego é a chamada História de Super-Herói. A chave aqui é um némesis e um problema aparentemente maior do que os protagonistas. Portanto, é uma combinação perfeita para a maioria das descrições de empregos, colocando-se em um esquema mais grandioso, que é o novo emprego e a empresa.",
          herostory2: "",
          herostory3: "",
       },
         storyform: {
          headline: "Responda às perguntas e obtenha seu primeiro rascunho em cerca de 30 minutos.",
          description: "Leia as dicas acima para o sucesso, pegue o anúncio de emprego e dê uma chance.",
          placeholder: "Nenhuma resposta ainda.",
          placeholderForm: "Comece a digitar...",
          pdf: "Baixar como PDF",
          tipsH: "",
          tips0: "Comece com o anúncio de emprego. Esta ferramenta é projetada para elaborar sua melhor história com base no anúncio de emprego para o qual você deseja se candidatar.",
          tips1: "Siga as diretrizes do auxiliar de perguntas.",
          tips2: "Não use pontuação no final de suas respostas.",
          tips3: "",
          Helper: "Para suporte, ouça o áudio, verifique a entrada e o exemplo. Para mais informações, confira o <a href='/learn'>curso online</a>.",
          name: "nome",
          qname: "Qual é o seu nome?",
          qnameHelper: "Insira seu primeiro nome e seu sobrenome. Se tiver mais do que isso, deixe os do meio de fora, como faria em seu currículo.",
          workExperience: "experienciaprofissional",
          qworkexperience: "Quantos anos de experiência profissional você possui?",
          qworkexperienceHelper: "Insira um número que expresse a quantidade de sua experiência profissional em anos. Arredonde para o ano inteiro. Certifique-se de que isso corresponda ao seu currículo.",
          date: "data",
          qdate: "Qual é a data do anúncio de emprego?",
          qdateHelper: "Esta é uma referência temporal para o anúncio de emprego. Insira a data em que o anúncio foi publicado ou, de outra forma, adicione apenas o mês e o ano em que o leu pela primeira vez.",
          media: "media",
          qmedia: "Onde você leu o anúncio de emprego?",
          qmediaHelper: "Isso é para fornecer um pouco de contexto de mídia. Insira o nome do site ou jornal onde você leu o anúncio. Por exemplo, LinkedIn ou site da empresa. Se você não se lembrar do nome da publicação, apenas mencione em um nível mais alto em que categoria de mídia encontrou. Por exemplo, jornal ou internet.",
          jobtitle: "jobtitle",
          qjobtitle: "Qual é o cargo para o qual você está se candidatando?",
          qjobtitleHelper: "Insira o título do cargo o mais preciso possível. É melhor você pegar o título do anúncio de emprego.",
          workExperiences: "workexperiences",
          qworkExperiences: "Quais são suas experiências profissionais anteriores mais relevantes?",
          qworkExperiencesHelper: "Esta é uma lista de funções anteriores que são experiências profissionais relevantes para o cargo para o qual você está se candidatando. Certifique-se de que sua resposta seja de alto nível. Idealmente, mencione os títulos e nomes das empresas das posições de trabalho anteriores.",
          specialty: "specialty",
          qspecialty: "Qual é a sua profissão?",
          qspecialtyHelper: "Se você tiver uma ou mais profissões, nomeie-as. O título da profissão retrata sua especialidade.",
          degree: "degree",
          qdegree: "Qual é o seu grau acadêmico mais alto ou qualificação formal?",
          qdegreeHelper: "Informe o título do seu grau acadêmico e/ou qualificações formais. Esta lista não precisa ser exaustiva, mas representar os destaques. Para uma lista completa, você pode anexar seu currículo de qualquer maneira.",
          treasure: "treasure",
          qtreasure: "O que é mais precioso para você?",
          qtreasureHelper: "Você insere uma lista de coisas que você valoriza mais. Esta é uma seção muito pessoal e pode ser literalmente qualquer coisa. A ideia aqui é ser claro sobre o que significa o mundo para você. Idealmente, concentre-se nas três principais. Esta é uma entrada de alto impacto que permite ao seu leitor se conectar com você. Isso é essencial para chamar a atenção e alcançar uma comunicação bem-sucedida.",
          jobDeliverable: "jobdeliverable",
          qjobDeliverable: "Qual é o resultado esperado do trabalho?",
          qjobDeliverableHelper: "Direto ao ponto, o que precisa ser feito no trabalho? Insira uma lista de resultados definidos sem enrolação. Consulte o anúncio de emprego para dicas e indique aqui qual resultado é esperado dessa posição. Por resultado, entende-se qual é o resultado literal do trabalho.",
          applicantChallenge: "applicantchallenge",
          qapplicantChallenge: "O que o fez procurar uma nova oportunidade de emprego?",
          qapplicantChallengeHelper: "As perguntas por trás dessa pergunta são múltiplas: por que você está disponível? Por que agora? Não há certo ou errado, mas o que quer que você insira precisa ser sólido.",
          applicantGoal: "applicantgoal",
          qapplicantGoal: "O que você está procurando?",
          qapplicantGoalHelper: "Outra entrada direta, sem bobagens: liste o que você está procurando. Leia em voz alta novamente e verifique se é realmente isso. Pode haver mais de uma coisa. Adicione as maiores e mais importantes. Lembre-se, não há certo ou errado. Isso se trata do que você está procurando em seu próximo emprego.",
          applicantSearch: "applicantsearch",
          qapplicantSearch: "O que você fez para encontrar o que está procurando?",
          qapplicantSearchHelper: "Isso é para dar um pouco de contexto sobre o que você fez até agora para encontrar o que está procurando. A abordagem faz parte da história. Então, liste as atividades ou meios da sua busca.",
          companyName: "companyname",
          qcompanyName: "Qual é o nome da empresa para a qual você está se candidatando?",
          qcompanyNameHelper: "Insira o nome oficial da empresa. Você pode obter isso tanto no anúncio de emprego quanto no registro da empresa.",
          companyProblem: "companyproblem",
          qcompanyProblem: "Qual problema a empresa está tentando resolver?",
          qcompanyProblemHelper: "Esta é uma resposta de visão geral. Toda empresa tem um propósito. Nomeie-o. Novamente, sem firulas. Aqui você precisa estar confortável com as palavras grandiosas que completam a seguinte frase: O propósito da empresa é.",
          companyThreat: "companythreat",
          qcompanyThreat: "Qual é a ameaça que a empresa enfrenta se não tiver sucesso?",
          qcompanyThreatHelper: "Isso se trata de estar ciente das consequências. O que acontece se eles falharem? Novamente, um ponto de visão geral que mostra que você conhece os limites do que está em jogo.",
          hiringReason: "hiringreason",
          qhiringReason: "Qual é o motivo pelo qual a empresa está contratando agora?",
          qhiringReasonHelper: "Isso diz respeito ao contexto do trabalho em termos da empresa, sua estratégia e organização. Pesquise as razões que as levam a anunciar a vaga. O que está por trás do trabalho?",
          companyInitiative: "companyinitiative",
          qcompanyInitiative: "Qual iniciativa estratégica está por trás dessa contratação?",
          qcompanyInitiativeHelper: "A empresa está seguindo alguma iniciativa, projeto ou estratégia concreta relacionada ao trabalho? Liste as iniciativas relevantes. Pesquise em que contexto estratégico de negócios o trabalho está inserido.",
          city: "city",
          qcity: "Em que cidade está a posição?",
          qcityHelper: "Nomeie literalmente a cidade onde o trabalho está localizado. Se não estiver vinculado a um local específico, diga algo como trabalho remoto ou outro substantivo que descreva onde o trabalho é realizado.",
          qualification: "qualification",
          qqualification: "Quais são suas qualificações formais relevantes para o trabalho?",
          qqualificationHelper: "Esta é literalmente uma lista das qualificações relevantes. Apenas liste os nomes, sem descrições adicionais. Uma qualificação de trabalho refere-se às certificações e atributos que um empregador exige de um candidato para ser considerado elegível para uma posição de trabalho específica. Essas qualificações geralmente são listadas no anúncio de emprego ou na descrição do trabalho fornecida pelo empregador ou empresa contratante. Sinta-se à vontade para adicionar qualificações que considere necessário mencionar nesse contexto.",
          languages: "languages",
          qlanguages: "Quais idiomas você fala fluentemente ou bem?",
          qlanguagesHelper: "Liste os idiomas relevantes com um nível formal, como B2, C1, se tiver, ou use termos como fluente, nativo. Mencione apenas idiomas que você pode manter em um contexto profissional.",
          skill3: "skill3",
          qskill3: "Quais são suas três principais habilidades relevantes para o trabalho?",
          qskill3Helper: "Isso se trata do que você pode fazer para concluir o trabalho. Esta é literalmente uma lista de suas principais habilidades relevantes para o trabalho. Sem firulas, apenas nomeie-as em uma lista separada por vírgulas.",
          references: "references",
          qreferences: "Quais são suas principais referências relevantes para este trabalho?",
          qreferencesHelper: "Isso diz respeito ao tipo de pessoas dispostas a fazer um favor a você. Liste até quatro profissionais relevantes com nome, cargo e empresa. Esses contatos devem estar em posição de autoridade e experiência para recomendá-lo para o que está em questão. Seus detalhes de contato devem ser listados em seu currículo e você deve informá-los sobre o compartilhamento de suas informações nesse contexto.",
          desire: "desire",
          qdesire: "Quais são as promessas de trabalho que fazem você querer fazer o trabalho?",
          qdesireHelper: "Isso se trata de você estar consciente das ofertas deles. Liste as promessas pelo nome que são mais importantes para você. Sem firulas, apenas nomeie-as em uma lista separada por vírgulas.",
          sustainable: "sustainable",
          qsustainable: "O que torna a oferta de emprego sustentável para você?",
          qsustainableHelper: "Isso se trata do que ajuda você a manter o envolvimento a longo prazo. Liste todos os fatores relevantes nos quais você tem certeza de que ajudam a sustentar o emprego. Não há certo ou errado. No entanto, alguns clássicos seriam finanças sustentáveis, perspectiva de crescimento pessoal e flexibilidades de tempo que permitem que você tenha uma vida também.",
          companyoffering: "companyoffering",
          qcompanyOffering: "O que a empresa oferece que ajuda você a sustentar o emprego?",
          qcompanyOfferingHelper: "Esta é literalmente uma lista de ofertas relevantes pelo nome. Pesquise no anúncio de emprego ou na seção de carreiras da empresa e cite o que eles oferecem aqui.",
          companynotoffering: "companynotoffering",
          qcompanyNotOffering: "O que a empresa não parece oferecer que ajudaria você a sustentar o emprego?",
          qcompanyNotOfferingHelper: "Mencione claramente aqui qual oferta está faltando ou onde você tem dúvidas se eles oferecem ou não. Isso é absolutamente crucial. Isso o coloca em uma posição mais forte, sendo capaz de assumir o controle no processo de entrevista e negociação.",
          start: "start",
          qstart: "O que você teria que aprender para um início bem-sucedido?",
          qstartHelper: "Imagine que você conseguiu o emprego. Quais novas habilidades ou conhecimentos você precisa desenvolver? Sem firulas, apenas nomeie-os em uma lista separada por vírgulas.",
          learn: "learn",
          qlearn: "Nomeie até três exemplos nos quais você aprendeu habilidades necessárias no trabalho.",
          qlearnHelper: "Isso diz respeito a fazer um ponto. Você já esteve em uma situação semelhante, aprendendo com sucesso no trabalho antes. Dê alguns exemplos de aprendizado no trabalho.",
          change: "change",
          qchange: "O que mudará para você no novo emprego?",
          qchangeHelper: "Compare seus cargos anteriores com este. O que mudaria para você? Por exemplo, você será confrontado com um estilo de trabalho diferente, mais ou menos responsabilidade, nova indústria,... Nomeie até três mudanças importantes.",
          questions: "questions",
          qquestions: "Que perguntas você gostaria de esclarecer em uma entrevista?",
          qquestionsHelper: "Quem pergunta, lidera. Essas perguntas são absolutamente essenciais. Tome seu tempo, certifique-se de que são consistentes com sua narrativa e que a resposta tenha um significado real para sua tomada de decisão. Se a resposta a uma pergunta não fizer diferença, não se preocupe com ela.",
          questionmarks: "questionmarks",
          qquestionMarks: "Quais dúvidas você acha que o empregador pode ter sobre você?",
          qquestionMarksHelper: "Este é o jogo do advogado do diabo. Pense assim: O que você gostaria de saber, se fosse eles lendo seu currículo? Quanto mais implacável você for, melhor será sua narrativa e você poderá responder de maneira forte na entrevista.",
          questionmarksexamples: "questionmarksexamples",
          qquestionMarksExamples: "Nomeie alguns exemplos.",
          qquestionMarksExamplesHelper: "Forneça alguns exemplos óbvios de particularidades em sua inscrição ou currículo que provavelmente despertarão a curiosidade do empregador. Pense assim: O que você gostaria de esclarecer ou saber mais se fosse eles lendo seu currículo.",
        },
        stepper: {
          back: "Voltar",
          next: "Próximo",
          complete: "Concluir Etapa",
          exposition: "Exposição",
          inciting: "Incidente Incitante",
          suspense: "Suspense",
          preclimax: "Pré-clímax",
          climax: "Clímax",
          resolution: "Resolução",
          story: "Sua história",
          check: "Lista de verificação da aplicação",
          apply: "Aplicar",
          audio: "Ajuda de áudio",
          input: "Responda às perguntas, obtenha sua história",
          inputCheck: "Verificação de entrada",
          inputCheckH: "Verifique se sua entrada fica bem na frase acima. Caso contrário, edite.",
          example: "Entrada de Exemplo",
          exampleD: "A entrada do usuário é marcada assim: __Input__",
          exampleHover: "Exemplo",
          iconExplainer: "Assistente de resposta",
          iconText: "Resposta curta de texto (Apenas os fatos)",
          iconNumbers: "Digite um número",
          iconDate: "Digite uma data",
          iconList: "Resposta de texto separada por vírgulas. (Por exemplo, Este é o meu primeiro ponto, segundo ponto e terceiro ponto.)",
          iconThink: "Pense bem. Sua entrada aqui faz toda a diferença.",
          helpMaterial: "Confira o curso online focado. Ele o ajuda com conhecimento e ferramentas para se preparar.",
          helpMaterialLink: "Comece a Aprender",
      },
      example: {
        name: "Frank Smith",
        nameL: "Meu nome é",
        nameS: "Exemplo de primeiro e último nome",
        workExperience: "7",
        workExperienceL: "Tenho __7__ anos de experiência profissional.",
        workExperienceS: "Exemplo de 7 anos de experiência profissional",
        date: "12.02.2023",
        dateL: ".. o anúncio de emprego datado de ",
        dateS: "Exemplo de data de anúncio de emprego",
        media: "LinkedIN",
        mediaL: "Soube sobre a vaga no ",
        mediaS: "Exemplo de mídia",
        jobtitle: "Gerente de Vendas - Eletrônicos de Consumo",
        jobtitleL: ".. candidatando-me para o cargo de ",
        jobtitleS: "Exemplo de um título de cargo como apareceria em um anúncio.",
        workExperiences: "Líder de Vendas na Empresa X, Líder de Equipe de Vendas - Eletrônicos na Empresa Y, Televendas - Eletrônicos de Consumo",
        workExperiencesL: ".. experiência relevante de funções anteriores como ",
        workExperiencesS: "Exemplo de como listar da melhor forma cargos anteriores.",
        specialty: "Vendedor",
        specialtyL: "O termo que descreve minha profissão é ",
        specialtyS: "Exemplo de uma profissão",
        degree: "Bacharel em Marketing",
        degreeL: "Meu mais alto grau acadêmico ou qualificação formal é ",
        degreeS: "Exemplo de um grau acadêmico",
        treasure: "Meu parceiro e minha família.",
        treasureL: "O que significa o mundo para mim é: ",
        treasureS: "Exemplo do que é importante para alguém",
        jobDeliverable: "Aumentar as Vendas, construir uma equipe",
        jobDeliverableL: "Resultados esperados do trabalho:",
        jobDeliverableS: "Exemplo de resultados de um trabalho de vendas",
        applicantChallenge: "Crescer como profissional, expandir meu conhecimento na indústria para xyz",
        applicantChallengeL: "Principais motivos que me fazem procurar um emprego são: ",
        applicantChallengeS: "Exemplo de razões para procurar um novo emprego.",
        applicantGoal: "Um cargo gerencial. Líder de Equipe ou Líder de Região.",
        applicantGoalL: "Estou procurando por um ",
        applicantGoalS: "Exemplo de um cargo desejado",
        applicantSearch: "Explorar minha rede, Pesquisa na Internet",
        applicantSearchL: "Assim foi como tentei encontrar empregos:",
        applicantSearchS: "Exemplo de ações para encontrar um emprego adequado",
        companyName: "Bloomberg L.P.",
        companyNameL: "O nome legal da empresa é ",
        companyNameS: "Exemplo de nome legal da empresa",
        companyProblem: "Sobrecarga de dados no setor financeiro.",
        companyProblemL: "A empresa se propôs a resolver o problema de ",
        companyProblemS: "Exemplo de um problema que uma empresa enfrenta.",
        companyThreat: "As ferramentas e redes de profissionais financeiros serão dispersas.",
        companyThreatL: "Em caso de falha, a consequência será: ",
        companyThreatS: "Exemplo de uma ameaça iminente.",
        hiringReason: "Expansão da empresa no mercado xyz.",
        hiringReasonL: "O motivo da empresa estar contratando é ",
        hiringReasonS: "Exemplo de um motivo para contratação.",
        companyInitiative: "Uma Agenda de Crescimento para a América Latina",
        companyInitiativeL: "A empresa iniciou ",
        companyInitiativeS: "Exemplo de uma iniciativa estratégica",
        city: "Boston, MA",
        cityL: "O emprego está localizado em ",
        cityS: "Exemplo de localização do trabalho.",
        qualification: "Certified Professional Sales Leader (CPSL)",
        qualificationL: "Minhas qualificações mais relevantes são: ",
        qualificationS: "Exemplo de uma qualificação profissional",
        languages: "Inglês Nativo, Russo C2, Espanhol C1",
        languagesL: "Minhas habilidades profissionais em idiomas são: ",
        languagesS: "Exemplo de habilidades profissionais em idiomas",
        skill3: "Comunicação Eficaz, Conhecimento de Produto e Demonstração de Vendas",
        skill3L: "Minhas habilidades mais relevantes são: ",
        skill3S: "Exemplo de habilidades para um trabalho",
        references: "Michael Smith, CFO da Empresa X, Sarah Wighely, CPO da Empresa Y",
        referencesL: "Minhas referências profissionais mais relevantes são: ",
        referencesS: "Exemplo de referências profissionais",
        desire: "Obter ações, crescimento pessoal e possibilidades de ganhos",
        desireL: "Minhas maiores razões para fazer o trabalho são: ",
        desireS: "Exemplo de prioridades",
        sustainable: "Horário de trabalho flexível, treinamento contínuo e licença parental remunerada.",
        sustainableL: "As seguintes condições tornam o comprometimento sustentável para mim: ",
        sustainableS: "Exemplo de fatores que tornam um trabalho sustentável.",
        companyOffering: "Programa de Mentoria e Treinamento",
        companyOfferingL: "Essas ofertas da empresa atendem às minhas demandas: ",
        companyOfferingS: "Exemplo de ofertas da empresa.",
        companyNotOffering: "Licença parental além de feriados e horário de trabalho flexível.",
        companyNotOfferingL: "Parece que a empresa não está oferecendo ",
        companyNotOfferingS: "Exemplo de itens que uma empresa pode não oferecer",
        start: "Administrar o Salesforce CRM e estabelecer Metas SMART.",
        startL: "Para um início bem-sucedido, preciso aprender ",
        startS: "Exemplo de novas habilidades a serem aprendidas.",
        learn: "Microsoft Dynamics 365 CRM Admin skills, Sistema de Metas OKR",
        learnL: "De maneira semelhante, aprendi essas habilidades no trabalho anterior: ",
        learnS: "Exemplo de habilidades aprendidas no trabalho",
        change: "ter mais responsabilidade, responsabilidades de contratação e um orçamento mais alto.",
        changeL: "Neste trabalho, eu ",
        changeS: "Exemplo de mudanças de um trabalho para outro",
        questions: "É possível tirar licença paternidade? Quais opções existem para tornar o horário de trabalho mais flexível?",
        questionsL: "Eu gostaria de saber: ",
        questionsS: "Exemplo de perguntas para esclarecer.",
        questionMarks: "Eu tenho experiência suficiente? Posso entregar resultados apesar do horário de trabalho flexível?",
        questionMarksL: "Com seu currículo e carta de apresentação em mente, eu me pergunto: ",
        questionMarksS: "Exemplo de pontos de interrogação que um empregador pode ter",
        questionMarksExamples: "Horário de Trabalho Flexível",
        questionMarksExamplesL: "Você pode estar se perguntando sobre ",
        questionMarksExamplesS: "Exemplo de uma possível pergunta.",
    },
    resume: {
      headline: "Lista de Verificação do Currículo",
      subHeadline: "Verifique se as seguintes informações estão de acordo com o seu currículo:",
      benefit: "Certifique-se de mostrar informações relevantes consistentes com a sua narrativa.",
      name: "Nome: ",
      workExperience: "Experiência Profissional em anos: ",
      media: "Conta de Mídia: ",
      specialty: "Profissão: ",
      degree: "Grau Acadêmico ou Diploma Escolar: ",
      applicantChallenge: "Metas: ",
      applicantGoal: "Cargo Desejado: ",
      qualification: "Qualificações: ",
      languages: "Habilidades Linguísticas: ",
      skill3: "Habilidades: ",
      references: "Referências: ",
      workExperiences: "Experiências Profissionais: ",
      change: "Menção do que mudar de: ",
      questionMarks: "Pontos de Interrogação: ",
      questionMarksExamples: "Exemplos de Pontos de Interrogação",
  },
  search: {
    headline: "Lista de Verificação da Busca de Emprego",
    subHeadline: "Verifique se isso é realmente o que você está procurando:",
    benefit: "Economize tempo e esforço. Concentre-se apenas em empregos que você está procurando.",
    jobTitle: "Isso é o que você quer fazer? - título do cargo: ",
    workExperience: "Você tem experiência nisso? - sua experiência profissional em anos: ",
    treasure: "O trabalho nutre o que é importante para você? - seu tesouro: ",
    specialty: "O trabalho corresponde à sua profissão? - sua profissão: ",
    degree: "Seu diploma o capacita para este trabalho? - seu diploma: ",
    jobDeliverable: "Você pode e quer produzir esses resultados: ",
    applicantChallenge: "O trabalho permite que você enfrente seu desafio? - seu desafio: ",
    applicantGoal: "Isso é o que você está procurando? - seu objetivo: ",
    companyProblem: "A missão da empresa é do seu interesse? - missão da empresa: ",
    companyThreat: "Você se preocupa com as consequências se eles falharem? - se eles falharem, então: ",
    hiringReason: "A razão para contratar é convincente? - razão para contratar: ",
    companyInitiative: "Você concorda com a iniciativa estratégica por trás da oferta? - iniciativa da empresa: ",
    desire: "O trabalho oferece o que você deseja? - seu desejo: ",
    sustainable: "O trabalho e o ambiente oferecem tudo que permite que você o faça por vários anos sem comprometer aspectos essenciais, como sua saúde, finanças, família ou hobbies? - seus fatores de sustentabilidade: ",
    companyOffering: "Isso é o que você precisa? - oferta da empresa: ",
    companyNotOffering: "Esses fatores ausentes são impeditivos? - empresa não oferece: ",
    start: "Você deseja aprender essas coisas? Será benéfico a longo prazo? - requisitos de aprendizado: ",
    learn: "Seus desafios de aprendizado passados correspondem aos necessários neste trabalho? - experiências de aprendizado passadas semelhantes:",
    change: "Essas mudanças estão no seu interesse? - mudanças em compromissos anteriores: ",
    references: "O feedback de suas referências é favorável? Eles têm autoridade para lhe dar crédito para este trabalho? Referências: ",
    questions: "Essa oportunidade vale a pena para obter suas perguntas respondidas? - suas perguntas sendo: ",
    questionMarks: "Os pontos de interrogação potenciais são grandes demais? - pontos de interrogação potenciais sendo: ",
    questionMarksExamples: "Você se contrataria com esses exemplos de pontos de interrogação potenciais? - Exemplos de pontos de interrogação potenciais: ",
},
coverletter: {
  headline: "Carta de Apresentação e Documentos de Candidatura",
  subHeadline: "Verifique sua carta de apresentação e documentos de candidatura para o seguinte:",
  benefit: "Candidatura consistente e focada para chamar a atenção do seu público.",
  letter: "Uma carta de apresentação que contenha uma versão de alto nível de sua história. Todas as partes, desde Exposição até Resolução, devem ser apresentadas. Recomendamos enviar sua história como carta de apresentação, pois ela já é de alto nível e focada no quadro geral. No entanto, em alguns casos, você pode lidar com limites de palavras para inscrições online. Nesse caso, você deve encurtar a história, concentrando-se nos destaques absolutos, mantendo a estrutura da exposição à resolução.",
  resume: "Um currículo que contenha evidências do conteúdo da sua história",
  documents: "Documentos como comprovação de suas qualificações",
},
interview: {
  headline: "Checklist de Entrevista",
  subHeadline: "Recomendamos que você crie um plano em torno dos elementos essenciais: Por quê?, Como?, O quê?, Riscos Potenciais e Mitigações.",
  benefit: "Você entra na entrevista com um plano e escopo.",
  benefit2: "Você tem ferramentas para assumir o controle e se preparar para lidar da melhor maneira com eventuais fraquezas.",
  why: "Tudo começa com o POR QUÊ. Prepare-se para elaborar sobre suas razões para assumir o emprego:",
  how: "Como você vai realizar o trabalho? Prepare seu conceito elaborado com base em suas habilidades, qualificações e experiência profissional:",
  skills3: "Habilidades: ",
  qualification: "Qualificação: ",
  workExperience: "Experiência de Trabalho: ",
  degree: "Grau Acadêmico Mais Alto: ",
  what: "A pergunta fundamental a ser respondida: O que você pode fazer por mim? Prepare o resultado que você se esforça para produzir:",
  risk: "Aborde os riscos potenciais ou supostas deficiências:",
  questions: "Certifique-se de abordar suas perguntas e obter uma resposta definitiva:",
  preparedHeadline: "Hora de Brilhar",
  preparedSubHeadline: "Com todos os checkmarks marcados, você está pronto para contar sua melhor história. Vá em frente e conte-a!",
},
storysection: { 
  headline: "Ainda não há história",
  subHeadline: "Comece a responder e obtenha sua história.",
  cta: "Responda a todas as perguntas para obter sua história completa",
  finalStepH: "Voilà!.. ",
  finalStepH1: ".. agora revise e edite para um resultado forte. ",
  finalStepD: "Nunca publique seu primeiro rascunho. Todo PRO Escritor sabe disso. Para ajudá-lo a revisar e editar eficientemente, ",
  finalStepD1: "todas as suas entradas estão marcadas de roxo",
  finalStepT: "Ao passar o mouse, você recebe orientações de revisão. Siga-as para melhorar a narrativa, coerência e expressão da sua história",
  reviewMode: "Sua história [modo de revisão]",
  cleanMode: "Sua história",
  paragraph1: "Meu nome é {{name}}. Eu desejo me candidatar para a vaga de {{jobTitle}} datada de {{date}} publicada em {{media}}.",
  name: "Meu nome é",
  nameT: "Verifique novamente: Este é o nome em seu currículo?",
  jobtitle: " Eu desejo me candidatar para a vaga de ",
  jobTitleT: "Verifique novamente: Este é o título da vaga do anúncio?",
  date: " datada de ",
  dateT: "Verifique novamente: Esta é a data exata do anúncio de emprego?",
  media: " publicada em ",
  mediaT: "Verifique novamente: É aqui que você encontrou o anúncio?",
  paragraph2: "Tenho mais de {{workExperience}} anos de experiência profissional em {{workExperiences}} e sou especialista na profissão de {{specialty}} com uma graduação em {{degree}}.",
  workexperience: " Tenho mais de ",
  workexperienceT: "Verifique novamente: Este é o total de sua experiência profissional?",
  workexperience1: " anos de experiência profissional em ",
  workexperience1T: "Verifique novamente: Essas são suas três principais experiências de trabalho relevantes para este trabalho?",
  specialty: " e sou especialista na profissão de ",
  specialtyT: "Verifique novamente: Sua profissão é relevante para o anúncio de emprego?",
  degree: " com uma graduação em ",
  degreeT: "Verifique novamente: Esta é a lista de seus diplomas conforme consta em seu currículo?",
  paragraph3: "{{treasure}}, é a minha fonte de força. Isso me capacita a entregar {{jobDeliverable}}. O desafio que me faz procurar emprego neste momento é {{applicantChallenge}}. Estou no processo de procurar {{applicantGoal}}. Portanto, comecei a procurar por uma oportunidade de emprego vaga e compatível, primeiramente por {{applicantSearch}}.",
  treasure:", é a minha fonte de força. Isso me capacita a entregar ",
  treasureT: "Verifique novamente: Isso é o que significa o mundo para você? Seu tesouro, fonte de força, no que a energia da sua vida é construída,... ",
  jobDeliverable:"",
  jobDeliverableT:"Quais resultados você precisa entregar no trabalho? Qual é o resultado final?",
  applicantChallenge:"O desafio que me faz procurar emprego neste momento é ",
  applicantChallengeT:"Verifique novamente: Isso é REALMENTE o que você procura assumir?",
  applicantGoal:"Estou no processo de procurar ",
  applicantGoalT:"Verifique novamente: O que você procura corresponde ao trabalho em questão?",
  applicantSearch:"Portanto, comecei a procurar por uma oportunidade de emprego vaga e compatível, primeiramente por ",
  applicantSearchT:"Verifique novamente: Esses métodos incluem os meios pelos quais você encontrou este trabalho?",
  paragraph4H: "Desafio da empresa; onde eu entro",
  paragraph4: "{{companyName}} busca resolver o problema de {{companyProblem}}. Ao fazer isso, enfrenta a ameaça de {{companyThreat}} em caso de falha. A razão pela qual a empresa está contratando é {{hiringReason}}. Nesse esforço, a empresa iniciou {{companyInitiative}}.",
  companyName:" busca resolver o problema de " ,
  companyNameT:"Verifique novamente: Este é o nome oficial da empresa conforme consta no registro comercial?" ,
  companyProblem: " Ao fazer isso, enfrenta a ameaça de ",
  companyProblemT: "Verifique novamente: No quadro geral, isso é o que a empresa está tentando resolver?",
  companyThreat: " em caso de falha.",
  companyThreatT: "Verifique novamente: Isso é o que acontece se eles falharem?",
  hiringReason:" A razão pela qual a empresa está contratando é ",
  hiringReasonT:"Verifique novamente: Isso é realmente a razão?",
  companyInitiative:" Nesse esforço, a empresa iniciou ",
  companyInitiativeT: "Confirme: Isso realmente corresponde ao contexto de contratação da empresa?",
  paragraph5: "É precisamente aqui que eu entro. Eu tenho as seguintes qualificações, habilidades e desejo de realizar as tarefas necessárias e conseguir o trabalho. Minhas três qualificações relevantes principais são: {{qualification}}. Falo fluentemente os seguintes idiomas relevantes para o trabalho: {{languages}}. Minhas três habilidades relevantes principais são {{skill3}}. Minhas três referências relevantes principais são: {{references}}. Já mencionei minhas experiências de trabalho relevantes no início.",
  qualification: "É precisamente aqui que eu entro. Eu tenho as seguintes qualificações, habilidades e desejo de realizar as tarefas necessárias e conseguir o trabalho. Minhas qualificações mais relevantes são ",
  qualificationT: "Confirme: Estas qualificações estão destacadas em seu currículo e você tem algum tipo de comprovação para elas?",
  languages: " Falo fluentemente os seguintes idiomas: ",
  languagesT: "Confirme: Esses idiomas estão destacados em seu currículo e você tem comprovação do nível?",
  skill3: " Minhas habilidades chave relevantes são ",
  skill3T: "Confirme: Essas habilidades estão mencionadas em seu currículo e você tem comprovação para elas?",
  references: " Minhas referências relevantes são ",
  referencesT: "Confirme: As referências estão em seu currículo?",
  workExperiences: " Já mencionei minhas experiências de trabalho relevantes no início.",
  workExperiencesT: "Confirme: Essas são suas três principais experiências de trabalho relevantes?",
  paragraph6H: "Principais Suposições a Explorar",
  paragraph6: "As promessas que a oferta de emprego me traz correspondem a esses desejos: {{desire}} é por isso que eu faria o trabalho. Além disso, os seguintes fatores tornam o engajamento no trabalho sustentável para mim a médio e longo prazo: {{sustainable}}. Parece que a empresa sustenta isso fornecendo {{companyOffering}}. No entanto, a empresa parece não fornecer {{companyNotOffering}}, ou pelo menos ainda não tenho conhecimento disso. Devido a esses fatores, conforme mencionado acima, acredito que posso apoiar a {{companyName}} em sua missão de enfrentar o problema de {{companyProblem}}, o que desperta a motivação de que estou interessado na posição anunciada como {{jobTitle}}.",
  desire: "As promessas que a oferta de emprego me trazem correspondem ao que eu desejo, ou seja,",
  desireT: "Confirme: Está claro e consistente com sua narrativa por que você deseja esses pontos? Está certo de que eles oferecem isso?",
  desire1: " é por isso que eu faria o trabalho.",
  sustainable: "Além disso, os seguintes fatores tornam o engajamento no trabalho sustentável para mim a médio e longo prazo: ",
  sustainableT: "Confirme: Esses fatores são cruciais para manter seu interesse, motivação, financiamento, saúde e sanidade? A menos que você queira procurar outro emprego daqui a 6 meses, certifique-se de acertar esses pontos.",
  companyOffering: "A empresa parece sustentar isso fornecendo ",
  companyOfferingT: "Confirme: Nomeie os benefícios ou ofertas da empresa que atendem aos fatores importantes mencionados anteriormente.",
  companyNotOffering: "A empresa parece não fornecer ",
  companyNotOfferingT: "Confirme: Nomeie os benefícios ou ofertas que você está perdendo ou tem dúvidas se algo nesse sentido é oferecido. Dica profissional: Este ponto é útil durante uma entrevista. Levante isso e você terá uma ótima chance de ganhar controle na entrevista.",
  companyNotOffering2: ", ou pelo menos ainda não tenho conhecimento disso. ",
  conclusion1: "Devido a esses fatores dados como mencionado acima, acredito que posso apoiar ",
  conclusion2: " em sua missão de enfrentar o problema de ",
  conclusion3: " o que desperta a motivação de que estou interessado na posição anunciada como ",
  start: "Para um início bem-sucedido, eu provavelmente teria que aprender: ",
  startT: "Confirme: Essa é a lacuna entre suas habilidades e experiência em relação à realidade e requisitos do trabalho? Dica profissional: Iniciar um novo emprego sempre envolverá novidades específicas da empresa a serem absorvidas, independentemente do seu nível de experiência no assunto. Apontar suas expectativas sobre o que isso vai ser pode gerar insights interessantes no processo de entrevista a seu favor.",
  learn: "No passado, provei que consigo aprender o que é necessário no trabalho. Por exemplo, por meio de minhas atividades e/ou experiências em ",
  learnT: "Confirme: Essas atividades realmente comprovam que você é capaz de aprender as habilidades necessárias?",
  change: "O que eu suspeito que mudará para mim, dada minha compreensão atual do trabalho, é: ",
  changeT: "Confirme: Essas diferenças resultam de suas experiências anteriores em comparação com sua expectativa do que este trabalho será?",
  preClimaxParagraph: "Para um início bem-sucedido, eu provavelmente teria que aprender: {{start}}. No passado, provei que consigo aprender o que é necessário no trabalho. Por exemplo, por meio de minhas atividades e/ou experiências em {{learn}}. O que eu suspeito que mudará para mim, dada minha compreensão atual do trabalho, é: {{change}}.",
  wrapUpH: "Resolução",
  questions: "Do meu ponto de vista, agora há clareza sobre muitos aspectos, mas alguns itens como ",
  questionsT: "Confirme: Essas perguntas estão consistentes com sua narrativa? São questões fundamentais ou detalhes adicionais?",
  questionMarks: " ainda requerem esclarecimento. Posso imaginar que você também tenha perguntas para mim sobre o meu ",
  questionMarksT: "Confirme: Essas perguntas potenciais estão consistentes com as informações fornecidas em seu material de inscrição?",
  questionMarks1: " por exemplo ",
  questionMarksExamples: " É essencial determinar se essas incógnitas estão alinhadas com nossos interesses mútuos.",
  questionMarksExamplesT: "Confirme: Se você pensar como um advogado do diabo, este é o ponto de interesse mais óbvio?",
  wrapup: "Do meu ponto de vista, agora há clareza sobre muitos aspectos, mas alguns itens como {{questions}} ainda requerem esclarecimento. Posso imaginar que você também tenha perguntas para mim sobre o meu {{questionMarks}} por exemplo {{questionMarksExamples}}. É essencial determinar se essas incógnitas estão alinhadas com nossos interesses mútuos.",
  wrapup2: "Prefiro a oportunidade de contribuir para a realização da missão da empresa prontamente. Para alcançar isso, gostaria de discutir a descrição do trabalho em uma entrevista pessoal. Dessa forma, podemos abordar quaisquer perguntas restantes e aspectos relevantes juntos.",
},
favorites: {
  headline: "Histórias Favoritas",
  note: "Nenhuma história favorita ainda. Encontre suas histórias favoritas salvas ",
  add: "Adicionar à Lista de Favoritos",
},
learn: {
  headline: "Aprenda e Melhore sua Narrativa",
  subHeadline: "Obtenha conhecimento de ponta em poucos passos marcantes.",
  benefit: "RÁPIDO: Vá direto para o tópico que mais interessa a você.",
  benefit2: "SUSTENTÁVEL: Avance passo a passo e questione sua história com o raciocínio e métodos introduzidos.",
  path: "Comece a aprender. O caminho pode levar até 30 minutos",
},
learnSteps: {
  intro: "Introdução",
  introtext1:
    "Para criar efetivamente uma candidatura a emprego, é útil estar familiarizado com os contextos relacionados ao trabalho, a si mesmo, e os conceitos de contar histórias e escrever de forma eficaz. Mas contar histórias e escrever, por si só, são tópicos vastos. Como saber quando você está pronto para criar a melhor aplicação?",
  introtext2:
    "Bem-vindo ao curso ©Storylenses! Este curso amigável para iniciantes, liderado por exemplos, o guiará através do conhecimento prévio para criar a história de como você é o melhor para o trabalho. Você construirá sua história, passo a passo; começando com uma análise do contexto do trabalho e autoanálise, migrando para um diagrama de Venn e narrativa de história.",
  introtext3:
    "Cada seção se baseia na anterior, então você deve seguir a sequência do início ao fim. Este tutorial pressupõe conhecimento em escrever em seu idioma nativo e nenhum conhecimento em contar histórias. Se você já está familiarizado com contar histórias, ainda recomendamos que você não pule nada e siga a sequência.",
  introtext4:
    "Se você tiver dúvidas sobre qualquer coisa relacionada a contar histórias ou este curso, fique à vontade para perguntar à nossa comunidade no ",
  linkText: "Discord",
  introtextcta: "Vamos começar!",
  framework: "Estrutura",
  frameworkGraph: "Narrativa atrai atenção e compartilhamento",
  frameworkGraph1: "O Quadro",
  frameworkHeadline: "O que é este método?",
  framework1:
    "Este é um framework flexível de contar histórias que fornece blocos de construção para criar 'a' história para suas candidaturas a emprego. Mas o que exatamente queremos dizer com isso? Vamos passar um tempo expandindo sobre o que este framework e Contar Histórias são e como podem ajudar.",
  frameworkSubHeadline2: "Blocos de Construção de uma Candidatura a Emprego",
  framework2:
    "Há algumas coisas que você precisa considerar ao criar candidaturas a emprego, tais como:",
  framework2li1:
    "Compreensão do contexto do trabalho - quais narrativas de contexto estão vinculadas a você e ao trabalho.",
  framework2li2:
    "Compreensão de si mesmo - Qualificação e motivação para o trabalho",
  framework2li3:
    "Como você se conecta - o que o trabalho, chefe, contexto podem fazer por você e vice-versa",
  framework2li4:
    "Narrativa - estruturando as informações e a mensagem que você quer transmitir.",
  framework2li5:
    "Entregáveis - Os formatos necessários para apresentar a história da sua candidatura",
  frameworkSubHeadline3: "O que é este framework?",
  framework3:
  "Este é um framework de comunicação para construir histórias que transmitam sua mensagem ao seu público-alvo. Por histórias, queremos dizer as mensagens que o público-alvo vê e interage em um contexto de candidatura a emprego.",
framework4:
  "Por framework, queremos dizer que ele fornece funções úteis para construir as partes e a estrutura da sua história, mas deixa a você decidir quais partes e que estrutura incluir em sua candidatura. O efeito do ©LIC é que sua candidatura tem uma probabilidade maior de chamar atenção, que sua narrativa, e não a de mais ninguém, seja adotada e compartilhada.",
framework5:
  "Parte do sucesso deste framework é que ele é relativamente neutro em relação aos outros aspectos da elaboração de candidaturas a emprego. Isso resultou em um ecossistema próspero de ferramentas e soluções de terceiros que podem ser usadas em conjunto com esse processo e ferramentas.",
framework6:
  "Isso também significa, no entanto, que construir uma candidatura completa a emprego desde o início requer algum esforço. Os candidatos precisam gastar tempo preenchendo o questionário, passando por um processo editorial de segunda revisão e seguindo as listas de verificação para alinhar os documentos da candidatura e a entrevista com a narrativa.",
frameworkSubHeadline7: "O que é um framework de Contar Histórias?",
framework7:
  "Construir uma história significa: definir um objetivo ou um apelo e direcioná-lo a um grupo-alvo por meio de mídia, conteúdo, mensagem, dramaturgia para criar um efeito. Por framework, queremos dizer que ele lida com as ferramentas e configurações necessárias para uma história de candidatura e fornece estrutura adicional, recursos e otimizações para construir as saídas da sua história.",
framework8:
  "Seja você um cientista de foguetes ou uma pessoa de vendas, você pode aproveitar esta ferramenta para construir sua melhor história de candidatura a emprego. Nas próximas lições, discutiremos como você pode começar.",
process: "O Processo",
processIntro: "Vamos ver o que precisa ser feito para ter a melhor história concluída:",
processLi1: "Entenda POR QUE você precisa fazer o trabalho.",
processLi1p:
  "Promessas enganosas - Seria incrível ouvir um vídeo no YouTube ou ler um post que diz: sua candidatura perfeita em 3 minutos! Seria. Mas esse tipo de coisa não o levará pelo processo.",
processLi1p1:
  "A verdade é... - 'Não há almoço grátis em Wall Street' e 'não há atalhos na vida' são ditados que qualquer investidor no planeta inteiro já ouviu falar. O mesmo se aplica à elaboração de sua história. Não há bala de prata. Vai exigir esforço. Isso é uma boa notícia, aliás, se fosse fácil, como você superaria outros candidatos?",
processLi1p2:
  "Aprender 'Por que' é tudo o que você precisa para passar pelo processo. Então, siga em frente.",
process1: "POR QUE",
process2: "O QUÊ",
processLi2: "O Trabalho e o Contexto",
processLi2p1: " - Analise a descrição do trabalho e o contexto do trabalho",
processLi2p2: " - Afie seu machado mergulhando no material detalhado",
processLi2p3: " - Faça sua lição de casa e preencha o questionário",
process3: "CONHEÇA A SI MESMO",
processLi3: "VOCÊ",
processLi3p1: " - Analise a si mesmo, em muitos níveis.",
processLi3p2: " - Faça sua lição de casa e preencha os questionários e frameworks.",
process4: "CONECTAR",
processLi4: "Conectar você e o trabalho",
processLi4p1: " - Conectar onde você e o trabalho se encontram",
processLi4p2: " - Faça sua lição de casa e preencha o Diagrama de Venn",
process5: "Craft da História",
processLi5: "Elabore a narrativa da sua história",
processLi5p1: " - Colocando as peças em uma estrutura de narração",
processLi5p2: " - Faça sua lição de casa e preencha a linha de história",
process6: "Candidatura",
processLi6: "Crie o material de candidatura com base na linha de história.",
processLi6p1: " - Escreva um currículo",
processLi6p2: " - Escreva uma carta de apresentação",
processLi6p3: " - Selecione uma foto",
processLi6p4: " - Modele sua identidade online",
processLi6p5: " - Modele sua expressão física",
process7: "Entrevista",
processLi7: "A entrevista.",
processLi7p1: " - Entre na entrevista com um plano e escopo.",
processLi7p2: " - Use a lista de verificação de ferramentas para preparar suas perguntas e lidar com potenciais fraquezas da melhor maneira.",
process8: "Fim e Começo",
processLi8: "Negocie, assine o contrato ou repita o processo.",
processLi8p1: " - Como resultado dessa abordagem, você valorizará o que traz para a mesa, qual é a correspondência e as deficiências do empregador.",
processLi8p2: " - Use seu conhecimento para negociar um acordo sustentável que reflita o quão forte é seu relacionamento com o empregador.",
processLi8p3: " - Se decidir contra a oferta, você exercitou seus músculos de candidatura e está em uma forma ainda melhor para a próxima.",
why: "O POR QUE",
whyH1: "Por que se preocupar com isso?",
whySubH: "Entendendo o POR QUE",
whyp1: "Para entender como funciona, primeiro precisamos de uma compreensão básica de como os vieses comuns o impedem de contar a melhor história e quais são suas chances se deixar os recrutadores interpretarem suas informações, em vez de entregar uma narrativa.",
whyp2: "Ao lado do texto, há uma imagem retratando apenas alguns exemplos de 'peças de Lego' de história que você e o recrutador têm à disposição para criar uma história. Em termos de Lego, juntar as peças significa construir uma impressão de sua capacidade de realizar o trabalho, ou não. Existem muitas mais peças de 'Lego' nas quais 'sua aplicação' é construída.",
whySub1: "É seu trabalho descrever essas peças de Lego",
whyp3: "O viés da 'maldição do conhecimento' é uma das razões para este curso, ele descreve a tendência de nossos cérebros de assumir que todos sabem o que sabemos. Em outras palavras, pensamos que nossas peças de Lego são conhecidas 'de alguma forma'. Não caia nessa armadilha em nosso cérebro. É encorajador saber que também há o viés da verdade, que descreve a inclinação das pessoas para acreditar, até certo ponto, na comunicação de outra pessoa. Então, em outras palavras, se você se esforçar, há uma grande probabilidade de as pessoas acreditarem em sua história. Existem inúmeros outros modelos mentais e conhecimentos que sustentam a importância de possuir uma história:",
whyQuote1: "“Não existe realidade objetiva. Informações ausentes estão sendo preenchidas pela experiência do leitor/ouvinte.” (Max Wertheimer, Kurt Koffka e Wolfgang)",
whyQuote2: "Nossos cérebros são conectados para narrativas (Carl Alviani)",
whyp4: "Por algum tempo, eu iniciava cada reunião física entregando as seis peças de Lego aos participantes pedindo para construir um pato. Mais de quarenta pessoas diferentes fizeram isso, e nenhum pato era igual:",
whyQuote3: "A matemática por trás do desafio do pato de Lego: 6 peças ⇾ 1x2, 2x2, 2x2, 2x3, 2x3, 2x4 pontos de conexão. Segundo um professor de matemática, para uma combinação semelhante de 6 peças 2x4, o número de maneiras de combinar seis tijolos de Lego de dois por quatro pregos da mesma cor é 102.981.500",
whySub2: "Moral da história",
whyp5: "Uma peça de Lego representa uma 'dimensão' sua. A combinação de peças é a 'história' de você sendo a pessoa certa para o trabalho. Em sua aplicação, há muitos mais fatores do que 6 peças 2x4 que compõem sua história. Portanto, existem mais de 102.981.500 possibilidades que um recrutador pode criar. Neste curso, você aprenderá a contar a história mais forte de todas.",
whySub3: "Recomendação",
whyp6: "Elaborar sua história 'sendo a melhor correspondência' é, portanto, a abordagem que você aprenderá para melhorar suas chances, vencer a armadilha da informação ausente e seguir no veículo certo para o cérebro dos recrutadores.",
whyGraph1: "Tudo começa com o POR QUE",
whyGraph2: "Peças de Lego tangíveis no contexto da aplicação:",
whyGraph3: "6 Peças de Lego ⇾ Resultados infinitos",
context: "Contexto",
contextH1: "Como parte 1 - Trabalho e contexto",
contextSubH: "Compreendendo o contexto do trabalho de espaço e tempo",
contextGraph: "Estrutura da História",
contextGraph1: "Contexto do Trabalho",
contextp1: "Agora que você sabe por que precisa assumir a responsabilidade e fazer o trabalho, vamos ver como fazer isso.",
contextp2: "Este 'ingrediente' é provavelmente o material mais comumente esquecido para construir uma história de candidatura a emprego. Investigar o contexto o leva do quadro geral aos detalhes. Este é um caminho pavimentado pelo trabalho árduo. No entanto, vale a pena. Usar isso irá beneficiá-lo de várias maneiras:",
contextLi1: "Filtro: esta é realmente uma indústria, organização, .. que eu quero para mim. Esta pesquisa pode economizar potencialmente muito tempo para empregos nos quais você poderia ter continuado se candidatando 'para ver como está indo'",
contextLi2: "Pró-argumento em sua história: Quanto mais aprofundada e ampla for a ancoragem de sua visão sobre o trabalho e seu impacto, mais forte você parecerá",
contextLi3: "Você demonstra que tem visão geral e olhar para detalhes sem precisar dizer isso",
contextLi4: "Ponto de partida essencial: para explicar uma 'correspondência', você precisa entender 'com o que você está correspondendo'!",
contextLi5: "Escolher um contexto forte é algo que seu futuro empregador vai apreciar. Use isso para se conectar! Eles estão nisso porque acreditam em seu futuro, obviamente.",
contextLi6: "Entender por que o contexto é importante para mim e por que sou importante para o contexto... a lista é literalmente interminável e o ponto é claro, invista o tempo, e você terá um material de construção sólido",
contextSubH3: "Começa com a descrição do trabalho",
contextp3: "A pesquisa do contexto do trabalho começa com a descrição do trabalho. Aqui você (pode) obter informações sobre megatendências, indústria, organização, trabalho e candidato. Pode até comentar sobre o passado, presente e futuro das respectivas dimensões. O que não é revelado, você precisa preencher.",
contextSubH4: "Contexto como ingrediente principal: Encontre um cavalo para montar!",
contextp4: "Esta é a história de você escolhendo conscientemente sua dinâmica de contexto. Seja um contexto 'ascendente, crescente, à prova de futuro' ou outro contexto que se ajuste à sua narrativa.",
contextp4Quote: "'A verdade é que o caminho para a fama e fortuna raramente é encontrado dentro de si mesmo. A única maneira certa de ter sucesso é encontrar um cavalo para montar. Pode ser difícil para o ego aceitar, mas o sucesso na vida se baseia mais no que os outros podem fazer por você do que no que você pode fazer por si mesmo.' (Do livro Positioning)",
contextp5: "Apenas essa ideia é uma excelente 'peça de destaque' em sua história. Há mais material para aprofundar sua percepção e compreensão das forças por trás de 'conectar e entender' a situação de alguém. Você pode pular adiante e preencher o questionário sem ler o material aprofundado. Se, no entanto, você deseja 'afiar o machado', recomendamos as seguintes duas explorações mais aprofundadas:",
contextH6: "Exploração aprofundada do especialista 1:",
contextp6: "Never Split the Difference: Negociando como se Sua Vida Dependesse Disso - O negociador de reféns do FBI Chris Voss defende nesta exploração aprofundada como você pode usar a 'empatia tática', que é basicamente sua compreensão do que é importante para 'eles'",
contextH7: "Exploração aprofundada do especialista 2:",
contextp7: "Spiral Dynamics: Dominando Valores, Liderança e Mudança. Este livro orienta você sobre como avaliar um contexto organizacional.",
contextp8: "De qualquer maneira, a compreensão neste caso significa colocar trabalho na pesquisa dos contextos no passado, presente e futuro. Perguntas orientadoras para responder na folha anexa.",
contextLi7: "Quais megatendências estão afetando o trabalho, organização, indústria e como",
contextLi8: "Em qual indústria eles estão e como ela está se saindo?",
contextLi9: "Qual é a organização, quais organizações concorrentes e colaboradoras existem?",
contextLi10: "Qual é o contexto do trabalho na organização, quem, processos, criação de valor,...",
contextLi11: "Quem será seu chefe? Para onde ele está indo? O que ele pode fazer por você?",
contextLi12: "Qual é o trabalho? Perfil? (Personalidade), Habilidades, qualificações, quem é a concorrência, como são os outros candidatos?",
contextH9: "Aprofundamento para 'entrar no espírito':",
contextp9: "A inspiração está em todos os lugares. Para acessar o 'poder' do contexto por um caminho diferente, recomendamos o seguinte conto de fadas dos irmãos Grimm para entrar no espírito do poder do contexto: O Gato de Botas: Embora seja um conto de fadas, O Gato de Botas é uma história sobre o poder de entender o contexto, a demanda e alavancar esses elementos. https://www.grimmstories.com/language.php?grimm=211&l=en&r=de",
you: "VOCÊ",
youH1: "Parte 2, Conheça a si mesmo!",
youSubH1: "O que (e quem) você é?",
youp1: "A pergunta sobre o que ou quem uma pessoa é é realmente complicada. Existem muitos aspectos para 'identidade'. No contexto da candidatura a emprego, destacamos as seguintes dimensões, pois definem suas peças de Lego em termos de:",
youLi1: "Qual é a sua personalidade?",
youLi2: "Destaques e baixos?",
youLi3: "Fatos concretos?",
youLi4: "O que os outros dizem sobre você?",
youp2: "Juntas, essas peças lhe dão uma base sólida para criar a história de ser a melhor combinação para um trabalho. Vamos analisar cada uma delas.",
youSubH3: "Mapa de Peças de Lego",
youp3: "As peças de Lego são uma analogia para o que você 'tem'. Para você perceber todas as coisas que você 'tem' é um processo criativo e analítico que pode ser estruturado e feito de várias maneiras. Aqui estão estruturas diferentes para começar:",
youp3Li1: "Tipo de personalidade",
youp3Li2: "Análise SWOT",
youp3Li3: "Destaque Profissional - Pessoal, Baixo",
youp3Li4: "Fatos sobre você - Questionário",
youSubH4: "Entendendo a Personalidade",
youp4: "Há muito a ser dito sobre aprender sobre a personalidade de cada um. Este curso recomenda os seguintes conceitos para começar a ter um 'conceito' sobre a dinâmica de sua personalidade:",
youp4BoxH: "Conceito básico: Os 4 tipos de personalidade de Carl Jung",
youp4Boxp: "Livro recomendado: Rodeado de Idiotas: Os Quatro Tipos de Comportamento Humano",
youGraph: "Os 4 tipos de personalidade de Carl Jung",
you4p1: "O conceito de quatro cores para descrever quatro estilos diferentes de personalidade: ",
you4p1Li1: "precise cool blue",
you4p1Li2: "caring earth green",
you4p1Li3: "sociable sunshine yellow",
you4p1Li4: "confident fiery red",
you4SubH: "Short intro on the Jung colors:",
you4p2: "Uma maneira de pensar nisso é do site: thediscoverypartnership.ie. Uma das ferramentas mais poderosas que você pode usar em vendas, ou em qualquer forma de comunicação, é entender os tipos de personalidade, tanto o seu quanto o dos outros. Você tem uma personalidade única e ela pode ser classificada como a maneira como você faz as coisas, seu comportamento ou temperamento observável.",
you4p3: "Tem pouco a ver com por que você faz as coisas/seus motivadores, nem tem a ver com o que você faz em termos de habilidades, conhecimento e competências. A teoria da personalidade hoje é amplamente baseada no psicólogo suíço Carl Gustav Jung e sua vida de pesquisa e publicações nessa área. Eu tive o grande privilégio de poder estudar e me qualificar em um dos sistemas mais importantes do mundo chamado Insights Discovery, que se baseia em seu trabalho.",
you4p4: "Posso afirmar sem contradição que, de todas as habilidades que desenvolvi ao longo dos anos, o perfil psicométrico, como é conhecido, é provavelmente a ferramenta mais eficaz para se ter em seu arsenal. Por quê? A pesquisa confirma que ter uma compreensão profunda de si mesmo, de suas forças, fraquezas, pontos cegos, preferências de estilo, como você gosta de aprender, comunicar, gerenciar e assim por diante, lhe dá controle.",
you4p5: "Aquilo do qual você está ciente, dá a você controle. Aquilo do qual você não está ciente, o controla. Então, a consciência é a chave e as ferramentas de perfil psicométrico, como o Insights Discovery, lhe dão esse controle. Em seu formato mais simples, o Insights Discovery é gerado a partir de várias centenas de milhares de permutações de declarações e o posiciona em uma roda em uma das 72 localizações diferentes. As pessoas são ou introvertidas ou extrovertidas em seu comportamento. As pessoas também são tipos de pensamento (cabeça) ou tipos de sentimento (coração). Essa abordagem de eixo X e Y cria quatro estilos altamente diferentes, aos quais atribuímos quatro cores.",
you4p6: "Pense nas quatro cores no título do artigo acima. O vermelho vibrante e o amarelo ensolarado são cores extrovertidas, enquanto o verde terra e o azul fresco são introvertidos. O vermelho vibrante e o azul fresco são o que consideramos como energias de pensamento ou cabeça, enquanto o verde terra e o amarelo ensolarado são mais quentes, amigáveis e emocionais, em outras palavras, sentimentos/coração. Isso é ilustrado no slide a seguir.",
you4p7: "Se você achar que o conceito de cor de Jung é 'suficiente' para trabalhar e usar 'personalidade' como um bloco de construção em sua história, sinta-se à vontade para pular o Enneagramm e continuar com 'Como usá-lo na história'.",
you4SubH1: "Conceito avançado: O Eneagrama",
you4p8: "Se você gosta de explorar mais suas dimensões de personalidade, recomendamos o Eneagrama como próximo passo. É um conhecimento muito 'antigo' e muito mais complexo do que as cores de Jung. No entanto, oferece uma explicação mais completa e dinâmica de diferentes tipos de personalidade. Estudantes do eneagrama têm uma compreensão sustentada do que precisam para prosperar e quais são os fatores que os colocam em uma espiral descendente. Ambos os fatores servem como excelentes peças de sua história, então consideramos a exploração aprofundada do eneagrama como promissora.",
you4p8Li1: "https://www.enneagraminstitute.com/how-the-enneagram-system-works",
you4p8Li2: "Livro recomendado: Tipos de Personalidade: Usando o Eneagrama para Autodescoberta",
you4SubH2: "Como usar os tipos de personalidade na história",
you4p9: "Use os tipos de personalidade para ter uma 'ideia' de quais 'cores' são mais fortes em você e que tipo de cores são 'solicitadas' na descrição do trabalho. Contrastar e comparar sua percepção disso faz uma ótima parte de sua história.",
you4BoxH9: "Obtenha seu feedback de cor:",
you4Boxp91: "Rota curta: Faça um teste, por exemplo, este é gratuito https://www.bemotion.nl/insights-kleurentest/free-personality-color-test/",
you4Boxp92: "Rota longa: Leia o livro recomendado ou um livro semelhante sobre as quatro cores",
youSubH5Graph: "Modelo Vazio",
youSubH5Graph1: "Matriz SWOT da Mind Tool",
youSubH5Graph2: "Exemplo",
youSubH5: "Análise SWOT",
youp5: "Uma análise SWOT é uma ferramenta de planejamento estratégico usada para avaliar as forças, fraquezas, oportunidades e ameaças envolvidas em um projeto ou em um empreendimento comercial. Envolve identificar fatores internos e externos que podem impactar o sucesso do empreendimento. A sigla SWOT significa Forças, Fraquezas, Oportunidades e Ameaças. A análise é usada para determinar como alavancar as forças, abordar as fraquezas, aproveitar as oportunidades e mitigar as ameaças. É uma ferramenta útil para entender a posição de uma [empresa] ou pessoa no mercado e para identificar áreas potenciais de crescimento e melhoria.",
youp51: "Recomendamos que você preencha a SWOT sobre si mesmo como uma forma de estruturar sua situação em relação a este trabalho. A esperança é extrair informações que você não teria pensado de outra forma.",
you5H2: "Matriz Destaque - Baixo Desempenho - Profissional - Privado",
youp52: "Já ouviu falar de 'quando a vida lhe dá limões, faça limonada'? Em termos de narração, pontos baixos e altos são igualmente importantes para uma narrativa forte e, o mais importante, transformadora. Vamos explorar seus pontos baixos e altos. Ensinamos você a apreciá-los em um capítulo posterior: passo 5 narrativa. Por enquanto, o que você precisa saber sobre ambos os pontos baixos e altos.",
youp52Li1: "Um ponto baixo e um ponto alto são ambos um status de uma situação",
youp52Li2: "Mudanças de status criam atenção. Em resumo, nossos cérebros são programados para a) reconhecer e b) prestar atenção a mudanças de status.",
youp52Li3: "A atenção é o único pré-requisito mais importante para que o 'destinatário' receba sua mensagem.",
youp53: "Você quer essa atenção em seus leitores, ouvintes! Então vamos caçar por pontos baixos e altos. Ensinamos você no passo 5 como usar esses pontos em sua narrativa. A estrutura da matriz está lá para fazer você pensar sobre essas dimensões aparentemente desconectadas da narração quando se trata de candidaturas a empregos: privado, profissional e pontos baixos e altos.",
youp54: "Espere aí? Eu entendi a parte da 'atenção', mas não deveria uma candidatura a emprego ser apenas um registro impecável de 'vitórias' e 'desempenho perfeccionista'? Um registro de super-humano e nada menos? Bem, ... não. O recrutador sabe, assim como você, que a vida não é uma linha reta. Como Goethe disse famosamente: 'Você os reconhecerá pelas fraturas'. Por enquanto, vamos apenas estabelecer que o verdadeiro poder da sua história está nos momentos de status extremo (baixo, alto), onde um status mudou, como você respondeu e o que você obteve com isso. Vamos preencher a matriz para obter esse material altamente precioso.",
youp55: "Como em tudo, a prática leva à maestria. Vamos dar uma olhada em alguns exemplos de que tipo de pontos baixos ou altos poderíamos começar para estimular a criatividade e treinar nossa percepção.",
youSubH6: "Questionário: Fatos sobre você",
youSubH6Graph: "Modelo:",
youp6: "Agora vem a parte fácil: vamos escrever os fatos simples que importam no contexto profissional:",
youp6Li1: "Pessoal",            
youp6Li2: "Origem, background",            
youp6Li3: "Estilo de vida",            
youp6Li4: "Educação",            
youp6Li5: "Trabalho, experiência profissional",            
youp6Li6: "Metas",            
youp6Li7: "Referências", 
youp61: "Para ajudar você com isso, fizemos um modelo que você literalmente só precisa preencher. O documento em profundidade e amplitude contém os seguintes pontos:",
you62Li1: "Informações de Contato: Onde você mora fisicamente e virtualmente? Como podem entrar em contato com você?",           
you62Li2: "Fatos Concretos: De onde você é? Qual é o seu background cultural?",
you62Li3: "Objetivo: Quais metas ou sonhos você tem? O que te impulsiona? Qual é o seu propósito? Quais são seus destaques? Quais são suas qualificações e objetivos de carreira?",
you62Li4: "Educação: Qual é o seu nível de educação? Quais graduações, área de estudo e cursos ou honrarias relevantes você possui?",
you62Li5: "Experiência Profissional: Quais experiências profissionais você possui? Uma lista de seus empregos anteriores, incluindo títulos de cargos, nomes das empresas e datas de emprego. Quais responsabilidades, aprendizados e conquistas você possui? Você está qualificado para o que está descrito no anúncio de emprego? Ou, em outras palavras, você já fez algo semelhante ao trabalho descrito no anúncio de emprego? Com quem naquela organização podemos falar sobre o seu desempenho?",
you62Li6: "Habilidades: Uma lista de habilidades relevantes, incluindo habilidades técnicas, proficiência em idiomas e certificações. Quais habilidades você possui que podem ser úteis para este trabalho ou para nossa organização? Você pode aprender?",
you62Li7: "Realizações: Uma lista de realizações notáveis, como prêmios, publicações ou outras honrarias. No que você se destaca? Você participa de algo?",
you62Li8: "Estilo de Vida e Experiências que Moldam a Vida. Você faz algo pela sua comunidade? Por exemplo, experiência como voluntário, treinamento, ensino,... Hobbies e interesses? Cursos, projetos relevantes,... Associações profissionais? Clubes, assinaturas, associações,...",
connect: "Conectar",
connectH: "Conectando. As. Peças. Correspondentes.",
connectSubH: "",
connectGraph: "Diagrama de VENN Genérico",
connectGraph1: "Diagrama de VENN do Contexto de Aplicação",
connectp: "A imagem acima é da lendária série dos anos 80 'Esquadrão Classe A'. Em resumo, o Esquadrão Classe A é uma equipe de veteranos do Vietnã que, de volta aos EUA, lutam por justiça para os pequenos contra valentões e vilões. Seu capitão, Hannibal, é o 'estrategista' que planeja como alcançar seus objetivos coordenando ação em múltiplos passos e diversas operações. Cada episódio termina com ele acendendo um charuto dizendo: 'Eu adoro quando um plano dá certo'. Exatamente esse 'dar certo' é onde estamos agora.",
connectSubH1: "Quem está chegando?",
connectp1: "Vamos ver o que você trouxe para a mesa. Você pesquisou o trabalho e a personalidade que estão procurando, a organização, a indústria,",
connectp1Li4: "Megatendências",
connectp1Li5: "Concorrência",
connectp1Li6: "Sua personalidade",
connectp1Li7: "Suas forças, fraquezas, oportunidades e ameaças em relação ao trabalho",
connectp1Li8: "Seus pontos baixos e altos pessoais e profissionais",
connectp1Li9: "Ter suas informações pessoais corretas",
connectp2: "O método para estruturar as conexões é o Diagrama de Venn.",
connectSubH4: "Diagrama de Venn do contexto da sua aplicação",
connectp4: "Vamos primeiro entender o que é um diagrama de Venn e quais conjuntos de itens temos. Um diagrama de Venn é uma representação gráfica das relações entre conjuntos de itens. Consiste em círculos, sendo que cada círculo representa um conjunto. A área onde os círculos se sobrepõem representa os itens que pertencem a ambos os conjuntos. Diagramas de Venn são usados para organizar e comparar visualmente conjuntos de dados. Eles são frequentemente usados em matemática, estatística e lógica para ilustrar as relações entre conjuntos.",
connectSubH5: "Conjuntos de itens",
connectp5: "Vamos ver com quais conjuntos estamos trabalhando: A - Você | B - Trabalho | C - Contexto maior",
connectp5y: "A - Você",
connectp5j: "B - Trabalho",
connectp5b: "C - Contexto maior",
connectp5Li1: "Sua personalidade",
connectp5Li2: "Suas forças, fraquezas, oportunidades e ameaças em relação ao trabalho",
connectp5Li3: "Seus pontos baixos e altos pessoais e profissionais",
connectp5Li4: "Ter suas informações corretas",
connectp5Li5: "Pesquisou a organização por trás do trabalho",
connectp5Li6: "e a personalidade que estão procurando",
connectp5Li7: "Organização",
connectp5Li8: "Indústria",
connectp5Li9: "Megatendências",
connectp5Li10: "Concorrência",
connectSubH6: "Ferramentas para facilitar",
connectp6: "Criamos uma matriz para ajudar você a estruturar as sobreposições entre os três conjuntos diferentes.",
connectp6BoxH: "Ação: preencha suas notas de pesquisa",
connectp6BoxP: "Copie a Planilha do Google incorporada para anotar todos os pontos mencionados acima.",
connectCTA: "Verificar a planilha auxiliar",
connectGraphHelper: "Planilha Auxiliar",
narrative: "Narrativa",
narrativeH: "Contar Histórias",
narrativeSubH: "Mostrar, não dizer - se você não consegue explicar, mostre",
narrativeP: "Uma história transmite uma mensagem. Não é científica ou arquivística, mas trabalho dramatúrgico. Trata-se de tensão e resolução!",
narrativeH1: "Os Cinco Atos segundo Freytag",
narrativeP1: "A estrutura de cinco atos especifica ainda mais precisamente o que, quando e como algo acontece na trama. O dramaturgo e romancista alemão Gustav Freytag descobriu esse padrão de trama em dramas de Shakespeare e na Grécia antiga.",
narrativeP2: "1 - Exposição: (Gênesis) A exposição novamente apresenta todos os personagens principais, jogadores e antagonistas, locais e o gatilho para o conflito. No entanto, isso nem sempre precisa ser óbvio.",
narrativeP3: "2 - Clímax da Ação: (Nêmesis) São adicionadas complicações adicionais que impedem a resolução do conflito e dão início à trama.",
narrativeP4: "3 - Clímax: (Ápice) O clímax é a parte mais emocionante da história. Assim como na peça de três e quatro atos, a trama e o conflito são levados ao auge.",
narrativeP5: "4 - Declínio da ação: (Catástrofe) A tensão é gradualmente reduzida, e os efeitos posteriores do clímax são revelados.",
narrativeP6: "5 - Desfecho/resolução: (Catarse) Após o desaparecimento da trama, o conflito é finalmente resolvido. Às vezes, é transmitida uma moral da história ou uma lição.",
narrativeH2: "Peça de Três Atos",
narrativeP7: "1. Existem outros sistemas de atos em peças e narrativas, como 5 e até mais atos. Portanto, para o seu desempenho dentro do seu objetivo de aplicação, sugerimos a peça de 3 atos porque é rápida, direta e economiza tempo. Assim, a peça de 3 atos funciona para qualquer um dos dez tipos mencionados de contar histórias.",
narrativeP8: "2. Dado que a Pirâmide de Freytag sempre funciona, especialmente em uma peça compacta de 3 atos, a pergunta se minha história deve ser consistente, tensa e resolver-se em alívio já está respondida: Sim!",
narrativeP9: "3. O que ainda resta a ser definido é sua configuração em relação ao problema (falta de conhecimento, falta de capacidade da equipe, falta de gestão, etc. - ou em outras palavras, POR QUE alguém deve trabalhar nesta empresa, por que o recrutador está procurando funcionários. Isso significa que a Introdução e, mais ainda, o alívio alcançado do problema/conflito quase permanecem os mesmos para todos os 10 tipos de histórias mencionados - mas o problema/conflito varia. Portanto, você deve identificar o problema/conflito atual!",
narrativeP10: "4. A configuração mencionada acima consiste - em nosso contexto, ou seja, seu objetivo de conseguir o emprego - em reconhecer qual tipo de problema, conflito, urgência, meta sua empresa-alvo, recrutador, equipe... está situado no momento em que o anúncio de emprego foi publicado e/ou quando você recebeu sua entrevista de emprego.",
narrativeH11: "Observação",
narrativeP11: "Muitas vezes, embora nem sempre, há um intervalo de tempo entre o anúncio de emprego e a entrevista de emprego que pode levar a diferentes expectativas por parte da gestão de RH, portanto, a diferentes configurações quando você lê o anúncio de emprego pela primeira vez e, em segundo lugar, quando você chega para a entrevista de emprego.",
narrativeH12: "Conclusão",
narrativeP12: "Para mensagens cativantes, de recepção rápida e mais simples, por exemplo, em blogs, apresentações ou boletins informativos, os três níveis clássicos de ação mencionados anteriormente geralmente são suficientes. Estes podem ser estruturalmente divididos em:",
narrativeP12Li: "uma introdução,",
narrativeP12Li1: "uma seção principal",
narrativeP12Li2: "e uma resolução.",
narrativeP13: "Nos estudos de literatura, cinema e teatro, esse padrão de acordo com a pirâmide de tensão de Gustav Freytag é chamado de estrutura de três atos ou épico de três atos. Isso se mostra extremamente funcional e, portanto, é usado igualmente em peças teatrais, romances, comerciais, artigos de blog e concertos, entre outros.",
narrativeP14: "Assim como uma oração principal consiste em um predicado e um sujeito, qualquer história deve ter pelo menos três atos em que é estruturada!",
narrativeP15: "Esta forma clássica de contar histórias dá a você muito espaço para trama, perspectiva e criação. Como você precisa de menos tempo e esforço aqui do que em uma história de cinco atos ou mesmo (bastante rara) de 7 atos, você pode avançar rapidamente - e a história é fácil de lembrar.",
narrativeIntroH: "Introdução",
narrativeIntroP: "No início de sua história, apresente e descreva seus protagonistas, seus motivos, o local, o tempo e o conflito central/a pergunta central. Use a lista de verificação abaixo para começar:",
narrativeIntroP1: "1. Os Protagonistas: (Nome da empresa e seu ecossistema, ou seja, seu pessoal de liderança, como CEO, a equipe, gerentes, concorrentes, clientes, contribuidores etc.",
narrativeIntroP2: "2. O tesouro (produto, serviço, caso de negócios, missão comercial da empresa",
narrativeIntroP3: "3. O palco (o mercado) Qual é a situação atual e, se possível, qual era a situação passada e o que deveria ser a situação futura do mercado?",
narrativeIntroP4: "4. O problema (o fardo do protagonista): por exemplo: o CEO não encontra sucessores, a equipe é muito jovem e inexperiente, os preços dos recursos estão ficando caros, os clientes exigem mais serviço etc. Observe aqui: Esses problemas são irritantes para qualquer equipe da empresa, mas não são nada de especial, apenas o dia a dia. O CONFLITO a ser resolvido aparece na parte principal.",
narrativeMainH: "Parte Principal",
narrativeMainP: "À medida que a trama avança, o conflito se torna cada vez mais agudo. Qual é o caminho para a solução, então? Como você resolve o problema? Qual papel você escolhe para o seu caminho, sua forma de solução? Geralmente, o cotidiano, já repleto de obstáculos típicos como clientes que desaparecem, contas não pagas, problemas na cadeia de suprimentos, membros da equipe que se aposentam etc., há uma combinação de problemas novos e/ou conhecidos:",
narrativeResolutionH1: "Resolução/conclusão",
narrativeResolutionP1: "No final, seu protagonista/personagem principal consegue resolver o conflito/problema e assim supera o caso ou o ponto baixo. Aqui você precisa encerrar ou responder a perguntas em aberto e linhas de trama que você introduziu nos dois primeiros atos de sua história para não deixar seus leitores, ouvintes, espectadores na fria e fornecer uma resolução conclusiva.",
narrativeResolutionP2: "Isso não deve ser muito sobrecarregado, porque o final também deve ser o final, a menos que você esteja trabalhando em uma série - nesse caso, um cliffhanger seria uma boa ideia. Mas mesmo isso deve terminar firmemente aqui! Isso explica por que em muitas histórias é introduzido um número gerenciável de personagens, porque a resolução deles custa muita tensão, espaço e tempo no final, ou seja, orçamento.",
narrativeResolutionP3: "No nosso caso, seu objetivo, alcançar o emprego, significa que você revela respostas para obstáculos futuros ou e o que você pode precisar para resolver o problema/o conflito (tecnologia, equipamento, equipe, tempo...). Existem obstáculos iminentes que você já poderia mencionar? O que é importante do seu lado para organizar, antes de o possível emprego começar, por exemplo, mudar para outra cidade, concluir seu contrato atual, aprender uma habilidade especial como outro idioma, etc.",
narrativePatternH: "Selecione um gênero",
narrativePatternP: "Agora, tudo o que resta para você começar é decidir qual dos dez padrões de histórias você, como protagonista que poderia e resolveria esse monte de problemas, se encaixa melhor? Aqui, como segue abaixo, são apresentados os 10 caras épicos de acordo com Blake Snyder para um trabalho bem-sucedido no gênero da história.",
narrativePatternH1Box: "Observação",
narrativePatternP1Box: "Independentemente do gênero ao qual sua escrita pertence ou se você está projetando um blog para marketing - assim que você cria uma narrativa, ou seja, aplica o storytelling, você usará um dos padrões e gêneros narrativos mencionados.",
narrativePatternH2: "Exemplo: Função no Trabalho e Seleção de Gênero",
narrativePatternP1: "Dependendo da mensagem pretendida, do problema e do objetivo, diferentes histórias muitas vezes funcionam para o candidato, por exemplo.:",
narrativePatternH1Table: "Função no Trabalho",
narrativePatternH2Table: "Gênero da História",
narrativePatternP1TableC1: "Gerente de Projeto",
narrativePatternP1TableC2: "Whydunit",
narrativePatternP1TableC3: "Especialista",
narrativePatternP1TableC4: "Gênio na Garrafa",
narrativePatternP1TableC5: "Adição à Equipe",
narrativePatternP1TableC6: "Felpa Dourada ou História de Estrada",
narrativePatternP1TableC7: "Gerente, Engenheiro",
narrativePatternP1TableC8: "Cara com um Problema",
narrativePatternH3: "Os 10 Gêneros de Histórias de Acordo com Blake Snyder",
narrativeGenre1H: "1. Cara com um Problema",
narrativeGenre1P: "Este gênero inclui tudo, desde filmes como Duro de Matar até Titanic e A Lista de Schindler. As histórias são sempre sobre um cara com um problema. Mas esse gênero em particular prescreve um tipo específico de problema: ou seja, um que geralmente é uma questão de vida ou morte e deve ser resolvido por meio de algum tipo de luta física, e imediatamente! Essas histórias são essencialmente uma crônica dessa luta (que pode consistir em uma série de mini-batalhas). Pense em Duro de Matar, Identidade Bourne, Misery, 2012 ou Apollo 13.",
narrativeGenre1P1: "O padrão em um cara com um problema é, em quase todos os casos, impulsionado por uma situação a) extrema e b) decisiva. Portanto, um terceiro problema aparece, geralmente c) falta de tempo e/ou dinheiro. Mas em muitos casos, em relação a uma oportunidade de emprego, é o tempo.",
narrativeGenre1P2: "O aspecto negativo para você nesse tipo de candidatura a emprego é que você precisa agir imediatamente, e não pode lidar com aspectos preparados como 'No futuro, sua empresa deveria fazer isso e aquilo, e posso contribuir com isso e aquilo... - NÃO - você precisa começar agora e imediatamente.",
narrativeGenre1P3: "Mas o aspecto positivo é que você tem uma oportunidade única na vida de mostrar suas habilidades do zero e entrar na empresa com o papel e o título de 'Provedor de Soluções', o que o impede de começar em uma escala baixa, passando muitos anos para se desenvolver dentro dessa empresa - não, você começa imediatamente e diretamente diante da equipe de gerenciamento, entregando uma solução graças à experiência, criatividade e rede de negócios, seguindo nosso exemplo acima, por exemplo. Você começa como um foguete, tanto física quanto intelectualmente, para lidar com 'o problema do cara (o gerente)'",
narrativeGenre1H1Box: "Em resumo: Este padrão pode ser caracterizado como 'Acelere!' A fórmula para o clímax da sua história é: Cara com um problema = Acelere!",
narrativeGenre2H: "2. O Velo de Ouro",
narrativeGenre2P: "Este gênero é frequentemente referido como uma espécie de 'pau para toda obra' quando nenhum outro se encaixa. Mas esse gênero também tem seus próprios requisitos específicos que devem ser atendidos para realmente funcionar. A chave é que o 'time' do personagem principal está perseguindo um 'prêmio' muito claro e definível que parece incrivelmente difícil de alcançar. A especificidade do prêmio é importante para o arco de suspense: você sabe que o filme/história acabou porque eles alcançaram o prêmio - ou não. Muitas vezes, em romances, epopeias ou roteiros que se dizem 'velo', o 'prêmio' é muito ambíguo ou não grande ou desafiador o suficiente; então, infelizmente, a jornada não se torna tão cativante quanto poderia ser. Pense em Star Wars, De Volta para o Futuro, Procurando Nemo, O Resgate do Soldado Ryan, Onze Homens e Um Segredo ou Náufrago.",
narrativeGenre3H: "3. Amor de Amigos",
narrativeGenre3P: "Esse gênero se refere tanto a histórias de amor quanto a amizades entre dois parceiros caracteristicamente diferentes, como os bem-sucedidos duos de atores Stan Laurel & Oliver Hardy, Terence Hill & Bud Spencer, Walter Matthau e Jack Lemmon, etc. Filmes de policial e parceiro, bem como podcasts ou programas de TV de dupla bem-sucedidos, como programas noturnos, também fazem parte disso, pense na figura do 'companheiro' em oposição travessa ao apresentador do programa. Observação: 'Amor de Amigos' não é um relacionamento com problemas. A menos que o problema principal do filme tenha a ver com um relacionamento chave que parece ser essencial para o personagem principal e que é ameaçado por algo. - Eles vão ficar juntos ou não? - é a pergunta central da história e o problema principal é explorado ao longo do filme, veja Dom Quixote & Sancho Pança, Tristão & Isolda, Starsky e Hutch, Sr. & Sra. Smith.",
narrativeGenre4H: "4. Por que fez / institucionalizado",
narrativeGenre4P: "A 'instituição' no sentido de uma autoridade, por exemplo, pode, mas não precisa ser levada ao pé da letra. A questão é se há um grupo com suas próprias regras e normas nos quais o personagem principal explora os custos e benefícios da adesão - e finalmente decide se pertencer ou não: 'Por que fez' (= gíria em inglês americano para 'Como se tornou o que é') trata-se de decidir quem esses personagens querem ser em sua relação com esse grupo e sobre os riscos e benefícios envolvidos. Assim, uma instituição também pode ser a indústria editorial, uma gangue de contrabandistas, a máfia ou uma empresa funerária; pense aqui em séries como Six Feet Under, The Ozarks, filmes como Full Metal Jacket, Goodfellas, O Diabo Veste Prada ou romances como 'A Montanha Mágica', de Thomas Mann, ou 'Los Angeles - Cidade Proibida', de James Ellroy.",
narrativeGenre5H: "5. Ritos de Passagem",
narrativeGenre5P: "Os critérios para este gênero não são simplesmente atendidos com um personagem em algum tipo de rito de passagem, como uma cerimônia de formatura. É crucial que seja um problema de vida compreensível (como: puberdade, divórcio, crise de meia-idade, perda de um ente querido ou vício) que o personagem principal evita perseguindo algo mais! Esses personagens estão claramente no caminho errado, pois passam a maior parte da história perseguindo um objetivo desafiador que é divertido de assistir, mas que no final não termina bem. No final, os protagonistas têm que enfrentar a seriedade da vida e, esperançosamente, aprender algo no processo. Pense no romance 'Os Mistérios de Pittsburgh', de Michael Chabon, ou 'O Apanhador no Campo de Centeio', de J.D. Salinger, ou filmes como 10 Coisas que Eu Odeio em Você, Trainspotting ou American Pie.",
narrativeGenre6H: "6. Super-herói",
narrativeGenre6P: "A chave aqui é um némesis e um problema aparentemente maior do que os próprios protagonistas. Nunca é emocionante ler ou ver pessoas incríveis (reais ou inventadas) terem sucesso repetidamente. Boas histórias sempre tratam dos personagens sendo levados ao limite e sobrecarregados - basicamente descendo ao inferno - até o final. Lembre-se: as histórias sempre tratam de superar grandes problemas que só pioram quando você tenta superá-los - ou não superá-los. Lembre-se do nosso exemplo mencionado anteriormente com a cobra no Vale de Neander. O enfrentamento também se aplica a episódios individuais, capítulos, seções de blog, cenas. Esse é o principal problema que os escritores enfrentam em quase qualquer história: garantir que seja um problema convincente o suficiente, difícil o suficiente, complicado o suficiente para preencher uma história inteira. Pense em romances como 'Drácula', de Bram Stoker, 'Frankenstein', de Mary Shelley, 'Fausto - Uma Tragédia', de Johann Wolfgang von Goethe, mas também filmes como Erin Brockovich, a saga Harry Potter, Matrix, Gladiador ou Homem-Aranha.",
narrativeGenre7H: "7. Gênio fora da garrafa / Deus ex Machina",
narrativeGenre7P: "Um objeto mágico, um catalisador 'mágico' evoca complicações e desafios que nunca teriam existido sem ele. Novamente, eles tornam a vida do herói mais difícil, de uma maneira que exige resolução. Geralmente é mais fácil para os leitores digerirem se a magia surge de uma razão compreensível, semi-explicável (ou seja, não muito arbitrária ou forçada), como uma máquina de fazer desejos na feira, uma tempestade ou uma mitologia estabelecida, como fantasmas ou bruxaria. E a magia deve desaparecer ou ser resolvida no final, para que o personagem principal possa voltar a uma vida 'normal' que ele ou ela consiga lidar de alguma forma. Pense em histórias como Aladim e a Lâmpada Mágica, 'Joãozinho o Esperto (Irmãos Grimm) ou os filmes O Professor Aloprado, Todo Poderoso e a série de TV cult dos anos 1960, Jeannie É um Gênio.",
narrativeGenre8H: "8. Tolo Triunfante",
narrativeGenre8P: "Esse gênero também se refere a um tipo antigo e bem-sucedido de narrativa, também chamado de romance picaresco na história literária alemã, veja o romance picaresco barroco 'Simplicissimus Teutsch' (publicado em 1669) por Hans Jakob Christoffel von Grimmelshausen, o 'Kater Murr' de Ernst Theodor Wilhelm (E. T.A.) Hoffmann, ou o romance picaresco mais recente 'O Tambor', do escritor Günter Grass, que foi filmado pelo diretor Volker Schlöndorff. Em ambos os romances picarescos, os horrores inconcebíveis da guerra, seja a Guerra dos Trinta Anos (Simplicissimus) ou a Primeira e Segunda Guerras Mundiais (O Tambor), são contados a partir do desapego ingênuo de uma criança ou brincalhão/bobo que tem o luxo da zombaria e perguntas mordazes, pois eles não enfrentam nenhuma responsabilidade.",
narrativeGenre8P1: "Esse gênero depende e se destaca com os personagens, ele precisa de protagonistas credíveis que possam transportar o papel do brincalhão ou bobo e que estejam ancorados em um contexto apropriado (família, crise, guerra, corte real...). Já no início do cinema, esses personagens eram incorporados por atores como Buster Keaton, Harold Lloyd, Charles Chaplin. Hoje, Jim Carrey (Charada), Heath Ledger, Joaquin Phoenix (Coringa) se destacam na série Bat Man. No geral, o protagonista de Bat Man vive fortemente através do antagonista do Coringa (Narrs), porque apesar ou por causa de sua malícia, o Coringa muitas vezes é aquele que fala a verdade. Modelos míticos para a qualidade muitas vezes transcendente na figura do Bobo em relação ao anti-herói ou deus sombrio podem ser encontrados em todas as sagas dos deuses, desde o",
narrativeGenre8P2: "mundo mitológico nórdico e a figura do deus Loki, até a antiguidade grega e as figuras de Pã, Baco e Prometeu, até a Bíblia (o arcanjo Lúcifer). É a dialética da provocação, caos e verdade que torna a figura do Bobo tão poderosa na narrativa e, especialmente no caos (guerra, crise), fala verdades que ninguém quer ouvir, mas que contribuem para a ordem a partir do caos.",
narrativeGenre9H: "9 - Encarcerado",
narrativeGenre9P: "Esse gênero trata principalmente do que parece: grupos de pessoas originalmente diferentes que daqui em diante levam um destino comum em um espaço desvinculado da vida cotidiana com regras fixas e restritivas. Como, quando e se eles encontram o caminho para fora e, acima de tudo, encontram o caminho juntos, falham ou vencem, e no processo usam novas realidades e/ou habilidades antigas de suas vidas antigas, não é tão emocionante quanto os eventos reais que carregam o suspense: A tarefa insolúvel que o grupo enfrenta é resolver, 'descer ao inferno', acreditando em uma vida melhor após a luta. Um clássico é a história (romance: Ken Kesey/filme: Milos Forman) 'Um Estranho no Ninho', o romance (Mario Puzo) e o filme (Francis Ford Coppola) 'O Poderoso Chefão', de certa forma o romance (Herman Melville) e o filme (John Huston) 'Moby Dick', Moby Dick sendo uma verdadeira obra-prima de muitos gêneros.",
narrativeGenre10H: "10 - O monstro na casa",
narrativeGenre10P: "Honestamente, esta é a história mais antiga, mas uma que sempre funciona, especialmente em longas-metragens. Conhecemos isso desde a infância, seja nos contos de fadas dos Irmãos Grimm, nas histórias de lobisomens ou, como adolescentes, nos típicos 'teen shockers' como A Hora do Pesadelo, Halloween, Todo Mundo em Pânico. Mas os adultos também gostam de levar sustos e ver quem, se, quando e como esse monstro onipotente será eliminado do mundo: veja as séries Alien, Tubarão, O Quarto do Pânico ou novelas como 'Dr Jekyll & Mr Hyde', do autor escocês Robert Louis Stevenson, ou os thrillers 'O Iluminado' e 'It', do autor Stephen King, ambos também adaptados para o cinema.",
narrativeToolingH: "Ferramentas de apoio",
material: "Application",
materialH: "Criar Material de Candidatura",
materialP: "A História serve como sua estrela guia e meio para criar o material de candidatura real.",
materialGraph: "Etapa Final do Processo",
materialH1: "Ferramentas de Apoio",
materialP2: "O StoryLenses Create Story flow cria uma lista de verificação para o material de candidatura. Siga o Questionnaire Flow e você receberá as seguintes listas de verificação:",
},
        }
      },
      de: {
        translation: {
          login: {
            title: "Formular",
      email: "E-Mail eingeben",
      password: "Passwort eingeben",
      signup: "Sie haben noch kein Konto? Registrieren Sie sich",
      remember: "Erinnere dich an mich",
      forget: "Passwort vergessen?"
      },
      nav: {
        login: "Anmelden",
        register: "Registrieren",
        logout: "Abmelden"
      },
      subnav: {
        create: "Geschichten erstellen",
        job: "Bewerbung",
        saved: "Gespeichert",
        favorites: "Lieblingsgeschichten",
        knowledge: "Geschichtswissen",
        course: "Online-Kurs",
        ebook: "E-Book",
        cta: "Verbessere deine Geschichte",
        button: "Starte den Online-Kurs"
      },
      lp: {
        headline: "Bekomme",
        headlineBold: "deine Geschichte,",
        headline2: "werde",
        headlineBold2: "erhört.",
        herotext: "Du suchst nach einem Job? Willkommen bei Story Lenses, dem ersten Werkzeug, das Dir dafür deine Geschichte erzählt:",
        herotext2: "Passende Story - passender Job! Deine eigene Geschichte, professionell strukturiert für Bewerbungen, Lebensläufe, Verhandlungen und Vorstellungsgespräche.",
        cta: "Los geht's",
        ctaContact: "Kontaktiere uns",
        featH1: "Eigenschaften",
        featH2: "Was du bekommst",
        featH3: "Hol dir deine personalisierte Geschichte: auf dich, dein Profil und deine Situation zugeschnitten. Im Format einer professionell geschriebenen Story. Um verstanden und gehört zu werden. Geeignet für alle Aspekte der Bewerbung, des Vorstellungsgesprächs - und darüber hinaus...!",
        feat1H: "Story-Editor",
        feat1P: "Professionelles Storytelling, das den Zuhörer anspricht, fesselt, den Anforderungen des Zielpublikums entspricht und jederzeit die gebotene Aufmerksamkeit beibehält.",
        feat2H: "Script Doctor",
        feat2P: "Geleitete Fragebögen, unterstützende Tools, Grammatikhilfe, Sprachverbesserer, Stilguide, mehrere Sprachen, E-Book und Expertenrat, um alles Wichtige zu vermitteln.",
        feat3H: "Bewerbungs-Guide",
        feat3P: "Die Geschichte ist die Grundlage für alles. Für deine Kommunikation, deine Bewerbungsunterlagen, besonders das Anschreiben. Alles. Story Lenses erstellt deine Geschichte. Diese enthält dein persönliches sowie prägnantes Anschreiben! Mit gezielten Informationen für deinen erfolgreichen Ansatz im Vorstellungsgespräch. Story Lenses erhöht deine Chancen, gehört und anerkannt zu werden.",
        stepsH1: "SCHRITTE",
        stepsH2: "Einfach loslegen.",
        step1H: "Registrieren",
        step1D: "Erstelle ein Konto mit deiner E-Mail-Adresse in 30 Sekunden.",
        step2H: "Bezahlung",
        step2D: "Abonnieren und mit einer 7-tägigen kostenlosen Testversion beginnen.",
        step3H: "Geschichte hoch",
        step3D: "Folge dem Ablauf, beantworte die Fragen und überarbeite deinen Text mit Hilfe der Story Lenses-Tipps bis zur Perfektion.",
        pricingH1: "PREISE",
        pricingH2: "Flexibler Plan",
        pricingH3: "Beginne mit einer kostenlosen Testversion und abonniere monatlich, solange du das Tool verwendest.",
        pricingMo: "Monat",
        pricingM: "Monatlich",
        pricingYo: "Jahr",
        pricingY: "Jährlich",
        pricingButton: "Jetzt kaufen",
        pricingPlan: "Story-Erstellungsplan",
        pricingPlanDesc: "Geeignet für Jobsuchende",
        pricingPlanD1: "Bewerbungs-Story-Ersteller",
        pricingPlanD2: "E-Book über Geschichtenerzählen",
        pricingPlanD3: "Unterstützung mehrerer Sprachen",
        pricingPlanD4: "Keine Nutzungsbeschränkung",
        pricingPlanD5: "Support",
        testimonials: "Erfahrungsberichte",
        testimonialsH: "Unsere Benutzer lieben uns.",
        testimonialsD: "Bewerber aus der ganzen Welt überschütten uns mit Lob. Hier stellen wir Ihnen gerne einige von ihnen vor.",
        testimonialsH1: "Starke Grundlage",
        testimonialsD1: "Ich war gerade von Südamerika nach Europa gezogen und hatte keine Erfahrung mit Bewerbungen, also suchte ich nach einer Anleitung, wie ich vorgehen sollte. Die App war erstaunlich einfach zu bedienen und lieferte schnell Ergebnisse. Ich verwendete die Geschichte als Grundlage für mein Anschreiben, die Struktur meines Lebenslaufs und die Erzählung im Vorstellungsgespräch. Ich erhielt sofort Feedback zu allen Bewerbungen und schließlich ein Jobangebot, das ich gerne annahm.",
        testimonialsName1: "Danielle Silva",
        testimonialsPosition1: "Verkauf, H&M",
        faqH: "Häufig gestellte Fragen",
        faqD: "Dies ist ein neuartiges Tool und Ansatz. Natürlich gibt es Fragen. Hier sind die häufigsten.",
        faqH1: "Warum empfehlen Sie, den ersten Entwurf nicht zu verwenden?",
        faqD1: "Zusammenfassend ist ein erster Entwurf ein notwendiger Ausgangspunkt, aber nur der Anfang des Schreibprozesses. Das Tool ist bereits darauf ausgelegt, diesen Prozess zu verkürzen. Dennoch sind Überarbeitung und Bearbeitung entscheidende Schritte, um die Arbeit zu verfeinern und zu verbessern, bevor sie mit einem Publikum geteilt wird, um sicherzustellen, dass sie den Erwartungen des Autors und des Publikums entspricht. Das Tool ist darauf ausgelegt, Ihnen dabei zu helfen, Aufmerksamkeit zu lenken, um Konsistenz zu überprüfen, und bietet Ressourcen, um reflektieren und einen noch höheren Einfluss zu erzielen.",
        faqH2: "Wie verwende ich die Geschichte im Bewerbungsprozess?",
        faqD2: "Die Geschichte ist die grundlegende Erzählung, die alle relevanten Informationen darüber enthält, wer du und das anbietende Unternehmen sind und wonach du suchst. Sie beschreibt potenzielle Höhepunkte und Herausforderungen sowie einen Lösungsweg.",
        faqH3: "Kann ich Feedback zu meiner Geschichte erhalten?",
        faqD3: "Auf jeden Fall. Wir bieten einen 48-Stunden-Feedback-Service gegen eine einmalige geringe Gebühr für Ihr Anschreiben, Ihren Lebenslauf und Ihre Geschichte.",
        faqH4: "Dies ist ein leistungsstarkes Werkzeug, warum ist es so preiswert?",
        faqD4: "Wir haben dies entwickelt, um Menschen dabei zu helfen, die bestmögliche Chance auf den gewünschten Job zu bekommen. Ein niedriger Preis ist buchstäblich die Art und Weise, wie wir glauben, es so weit wie möglich zugänglich zu machen.",
        faqH5: "Worauf basiert dies?",
        faqD5: "Story Lenses ist ein System, das die richtige Architektur für deine Geschichte auf der Grundlage von Modellen erstellt, die seit den ersten Sagen, der griechischen Tragödie, der Bibel und dem Druck weitergegeben und erfolgreich verwendet wurden. Storymuster professioneller Autoren, Bühnen- und Regieanweisungen sowie Geschichtenerzähler wie der Drehbuchautor und Dramaturg Blake Snyder. Viele dieser Storymuster basieren hauptsächlich auf der griechischen Mythologie und sie sind „bulletproof“ und millionenfach getestet, da sie professionell in den Bereichen Medien, Verlagswesen, Film und Drehbuch verwendet wurden.",
        faqH6: "Wer hat dies entwickelt?",
        faqD6: "Wir sind ein Team von Fachleuten aus den Bereichen Film, Medien, Verlag und Softwareentwicklung, die sich vereint haben, um Ihnen zu helfen, die beste Geschichte zu erzählen, die Sie haben.",
        valueH: "DIE GESCHICHTE RUHT IN DIR!",
        valueH1: "Es ist Zeit, es herauszuholen... und mehr.... daraus zu machen!",
        valueD: "Sie müssen das Rad nicht neu erfinden, um zu schreiben, Sie müssen nicht aus 100 Büchern und Kursen Geschichtenerzählen lernen: Folgen Sie den Anweisungen in Story Lenses - und Sie erhalten alles, was Sie brauchen, eine vollständige Geschichte.",
        valueH2: "Sehr erschwinglich",
        valueD2: "Wir möchten das Tool für jeden zugänglich machen! Einfach. Überall. Jederzeit. Für jede Art von Bewerbung, Interview oder Geschichte. Und: Die ersten 7 Tage sind sogar kostenlos.",
        valueH3: "Eine Geschichte hat viel Potenzial",
        valueD3: "Eine wirklich gut erzählte, authentische, überzeugende und aufregende Geschichte hat viel Potenzial - und hält für viele Gelegenheiten: Nehmen Sie Ihre Geschichte und verwenden Sie sie von nun an für alle relevanten Bewerbungsunterlagen: Anschreiben, Lebenslauf, Vorstellungsgespräch ...",
        valueCTA: "Los geht's",
        ctaBoxH: "Bist du bereit für deine Geschichte?",
        ctaBoxD: "Probier es aus.",    
      },
      home: {
        mainHeadline: "Lass uns deine Geschichte erstellen",
        subHeadline: "Tipps für Erfolg",
        description: "",
        herostory: "Der perfekte Typ Geschichte für eine Bewerbung ist die sogenannte Superheldengeschichte. Der Schlüssel hier ist ein Widersacher und ein scheinbar größeres Problem als die Protagonisten selbst. Es ist also eine perfekte Übereinstimmung für die meisten Stellenbeschreibungen, sich in ein größeres Schema einzufügen, nämlich den neuen Job und das Unternehmen.",
      },
      storyform: {
        headline: "Beantworten Sie die Fragen und erhalten Sie Ihren ersten Entwurf in etwa 30 Minuten.",
        description: "Lesen Sie die obigen Erfolgshinweise, holen Sie sich die Stellenanzeige und legen Sie los.",
        placeholder: "Noch keine Antwort.",
        tipsH: "",
        tips0: "Beginnen Sie mit der Stellenanzeige. Dieses Tool ist darauf ausgelegt, Ihre beste Geschichte basierend auf der Stellenanzeige zu erarbeiten, für die Sie sich bewerben möchten.",
        tips1: "Befolgen Sie die Richtlinien des Frageassistenten. Wo es Informationenstiefe heißt, denken Sie groß! Wo es exakte Übereinstimmung heißt, seien Sie detailliert und präzise.",
        tips2: "Verwenden Sie keine Interpunktion am Ende Ihrer Antworten.",
        tips3: "Informationen über das Unternehmen und die Stelle können aus der Stellenanzeige, deren Website, Presse und Social-Media-Seite entnommen werden.",
        qname: "Wie ist Ihr Name?",
        qnameHelper: "Informationstiefe: 2-3 Namen. Wenn Sie mehr haben, lassen Sie die mittleren aus. Format: Vorname, Nachname. Tipp: Geben Sie Ihren Namen so ein, wie er in Ihrem Lebenslauf steht.",
        qworkexperience: "Wie viele Jahre Berufserfahrung haben Sie?",
        qworkexperienceHelper: "Informationstiefe: auf volle Jahre gerundet. Format: Geben Sie eine Zahl ein, die die Anzahl der Berufserfahrung in Jahren ausdrückt. Stellen Sie sicher, dass dies zu Ihrem Lebenslauf passt!",
        qdate: "Was ist das Datum der Stellenanzeige?",
        qdateHelper: "Informationstiefe: hoher Level oder genau bis zum genauen Datum. Beides ist in Ordnung. Format: Monat/Jahr oder genaues Datum, Monat und Jahr. Geben Sie entweder das angegebene Datum ein, oder fügen Sie einfach einen Monat und ein Jahr ein, in dem Sie es zuerst gelesen haben.",
        qmedia: "Wo haben Sie die Stellenanzeige gelesen?",
        qmediaHelper: "Informationstiefe: Genauer Name, wenn Sie sich erinnern können, sonst hoher Level in welcher Medienkategorie. Format: Medienname. Nennen Sie den Medienausgang.",
        qjobtitle: "Für welche Position bewerben Sie sich?",
        qjobtitleHelper: "Informationstiefe: Exakte Übereinstimmung (dies muss so genau wie möglich sein) Format: Begriff, der die Arbeitsposition beschreibt. Tipp: Nehmen Sie den Titel aus der Stellenanzeige.",
        qworkExperiences: "Was sind Ihre relevantesten früheren beruflichen Erfahrungen?",
        qworkExperiencesHelper: "Informationstiefe: Hoher Level, bis zu den drei relevantesten Erfahrungen. Format: Begriffe früherer Jobpositionen und Firmenname. Nennen Sie bis zu drei frühere Arbeitsrollen, die für diese Stelle relevant sind.",
        qspecialty: "Was ist Ihr Beruf?",
        qspecialtyHelper: "Informationstiefe: Exakte Übereinstimmung. Format: Begriff, der Ihren Beruf am besten beschreibt.",
        qdegree: "Was ist Ihr höchster akademischer Abschluss oder formale Qualifikation?",
        qdegreeHelper: "Informationstiefe: exakte Übereinstimmung. Format: Liste der Titel. Nennen Sie die Titel Ihres Abschlusses oder Ihrer Qualifikationen.",
        qtreasure: "Was ist für Sie am kostbarsten?",
        qtreasureHelper: "Informationstiefe: Hoher Level. Format: Eine Liste von Dingen, die Ihnen am wichtigsten sind. Die Idee hierbei ist, klarzustellen, was für Sie am wichtigsten ist. Konzentrieren Sie sich idealerweise auf die drei wichtigsten Dinge.",
        qjobDeliverable: "Was ist das Jobergebnis?",
        qjobDeliverableHelper: "Informationstiefe: Hoher Level. Format: Eine Liste von definierten Ergebnissen. Schauen Sie sich die Stellenanzeige an und geben Sie hier an, welches Ergebnis von dieser Position erwartet wird. Mit Ergebnis ist gemeint, was ist das konkrete Ergebnis der Arbeitsbemühungen.",
        qapplicantChallenge: "Was hat Sie dazu veranlasst, nach einer neuen Berufschance zu suchen?",
        qapplicantChallengeHelper: "Informationstiefe: Hoher Level. Format: Beschreibender Satz, der Ihre Hauptmotive auflistet. Es könnte mehr als einen geben. Fügen Sie den größten und wichtigsten hinzu.",
        qapplicantGoal: "Was suchen Sie?",
        qapplicantGoalHelper: "Informationstiefe: Hoher Level. Format: Beschreibender Satz. Beschreiben Sie die Art von Arbeit oder Aufgabe oder Umgebung, nach der Sie suchen, um die von Ihnen beschriebene Herausforderung zu meistern.",
        qapplicantSearch: "Was haben Sie unternommen, um zu finden, wonach Sie suchen?",
        qapplicantSearchHelper: "Informationstiefe: Hoher Level. Format: Medienname oder Kategorie. Welche Methode verwenden Sie oder haben Sie ausprobiert, um einen geeigneten Job zu finden?",
        qcompanyName: "Wie lautet der Name des Unternehmens, bei dem Sie sich bewerben?",
        qcompanyNameHelper: "Informationstiefe: exakte Übereinstimmung. Format: Name. Nehmen Sie den Namen entweder aus der Stellenanzeige oder dem Unternehmensregister.",
        qcompanyProblem: "Welches Problem versucht das Unternehmen zu lösen?",
        qcompanyProblemHelper: "Informationstiefe: Hoher Level. Format: Begriff. Dies ist eine große Bildantwort. Jedes Unternehmen hat einen Zweck. Nennen Sie ihn.",
        qcompanyThreat: "Welche Bedrohung sieht das Unternehmen, wenn es nicht erfolgreich ist? (z.B. kein Zugang zu nachhaltiger Kleidung)",
        qcompanyThreatHelper: "Informationstiefe: Hoher Level. Format: Liste von Bedrohungen. Was passiert, wenn sie scheitern? Wieder ein Gesamtbildpunkt, der zeigt, dass Sie die Grenzen dessen kennen, was auf dem Spiel steht.",
        qhiringReason: "Was ist der Grund, warum das Unternehmen gerade jetzt einstellt?",
        qcityHelper: "Informationstiefe: exakte Übereinstimmung. Format: Name. Wenn es nicht an einen bestimmten Ort gebunden ist, sagen Sie etwas wie Remote-Arbeit oder ein anderes Substantiv, das beschreibt, wo die Arbeit erledigt wird.",
        qqualification: "Was sind Ihre relevanten formellen Qualifikationen für den Job?",
        qqualificationHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Qualifikationen. Eine Jobqualifikation bezieht sich auf die Zertifikate und Attribute, die ein Arbeitgeber von einem Bewerber verlangt, um als geeignet für eine bestimmte Jobposition betrachtet zu werden. Diese Qualifikationen werden in der Regel in der Stellenanzeige oder der Jobbeschreibung des Arbeitgebers oder der einstellenden Firma aufgeführt.",
        qlanguages: "Welche Sprachen sprechen Sie fließend oder gut?",
        qlanguagesHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Sprachen mit Niveau. Erwähnen Sie nur Sprachen, die Sie im beruflichen Kontext aufrechterhalten können.",
        qskill3: "Was sind Ihre drei wichtigsten relevanten Fähigkeiten für den Job?",
        qskill3Helper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Fähigkeiten. Nennen Sie die Hauptfähigkeiten, die Sie beherrschen und die die Arbeit erledigen.",
        qreferences: "Was sind Ihre wichtigsten relevanten Referenzen für diesen Job?",
        qreferencesHelper: "Informationstiefe: Hoher Level. Format: Liste von bis zu vier relevanten Fachleuten mit Name, Position und Unternehmen. Diese Kontakte sollten in der Position von Autorität und Erfahrung sein, um Sie für das, was in Frage steht, zu empfehlen. Ihre Kontaktdaten sollten in Ihrem Lebenslauf aufgeführt und von Ihnen informiert sein, dass Sie ihre Informationen in diesem Kontext teilen.",
        qdesire: "Was sind die Jobversprechen, die Sie dazu bringen, den Job machen zu wollen?",
        qdesireHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Versprechen nach Namen. Wir empfehlen, sich auf diejenigen zu konzentrieren, die Ihnen am wichtigsten sind, und bis zu drei aufzulisten.",
        qsustainable: "Was macht das Jobangebot nachhaltig für Sie?",
        qsustainableHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Faktoren. Denken Sie mittelfristig. Was bieten sie an, bei dem Sie sicher wissen, dass dies dazu beiträgt, Ihr Engagement aufrechtzuerhalten.",
        qcompanyOffering: "Was bietet das Unternehmen, das Ihnen hilft, den Job aufrechtzuerhalten?",
        qcompanyOfferingHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Angebote nach Namen. Recherchieren Sie in der Stellenanzeige oder im Karrierebereich des Unternehmens und zitieren Sie Angebote von dort.",
        qcompanyNotOffering: "Was bietet das Unternehmen scheinbar nicht an, was Ihnen helfen würde, den Job aufrechtzuerhalten?",
        qcompanyNotOfferingHelper: "Informationstiefe: Hoher Level. Format: Liste von relevanten nicht angebotenen Elementen nach Namen. Nennen Sie hier klar, welches Angebot fehlt oder bei dem Sie unsicher sind, ob es angeboten wird.",
        qstart: "Was müssten Sie für einen erfolgreichen Start lernen?",
        qstartHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Lerninhalte nach Namen. Stellen Sie sich vor, Sie bekommen den Job. Welche neuen Fähigkeiten oder Kenntnisse müssen Sie aufbauen?",
        qlearn: "Nennen Sie bis zu drei Beispiele, bei denen Sie notwendige Fähigkeiten im Job gelernt haben.",
        qlearnHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Beispiele. Stellen Sie sicher, dass dies zu Ihrem Lebenslauf oder Ihrer Bewerbung passt.",
        qchange: "Was wird sich für Sie im neuen Job ändern?",
        qchangeHelper: "Informationstiefe: Hoher Level. Format: Liste der relevanten Änderungen. Im Vergleich zu dem, was Sie in Ihren aktuellen oder früheren Rollen gewohnt sind. Nennen Sie bis zu drei wesentliche Veränderungen.",
        qquestions: "Welche Fragen möchten Sie in einem Vorstellungsgespräch klären?",
        qquestionsHelper: "Informationsintensität: hoher Level. Format: Liste der Interessenspunkte. Listen Sie die Punkte auf, die Sie im Gespräch mit dem Arbeitgeber genauer erfahren möchten.",
        qquestionMarks: "Welche Fragezeichen könnte der Arbeitgeber Ihrer Meinung nach in Bezug auf Sie haben?",
        qquestionMarksHelper: "Informationstiefe: hoher Level. Format: Liste potenzieller Fragezeichen. Denken Sie daran: Was möchten Sie wissen, wenn Sie sie wären und Ihren Lebenslauf lesen?",
        qquestionMarksExamples: "Nennen Sie ein paar Beispiele.",
        qquestionMarksExamplesHelper: "Informationstiefe: exakte Übereinstimmung. Format: Liste von Besonderheiten, die Neugierde auf Seiten des Arbeitgebers wecken. Denken Sie daran: Was möchten Sie wissen, wenn Sie sie wären und Ihren Lebenslauf lesen.",
        },
        stepper: {
          back: "Zurück",
          next: "Weiter",
          complete: "Schritt abschließen",
          exposition: "Exposition",
          inciting: "Anstoßendes Ereignis",
          suspense: "Suspense",
          preclimax: "Vorklimax",
          climax: "Höhepunkt",
          resolution: "Auflösung",
          story: "Deine Geschichte",
          check: "Bewerbungs-Checkliste",
          apply: "Bewerben",
          input: "Deine Eingabe",
          inputCheck: "Eingabeüberprüfung",
          inputCheckH: "Überprüfen Sie, ob Ihre Eingabe im obigen Satz gut lesbar ist. Andernfalls bearbeiten Sie diese.",
          example: "Beispiel-Eingabe",
          exampleD: "Die Benutzereingabe ist so markiert: __Eingabe__",
        },
        example: {
          name: "Frank Smith",
          nameL: "Mein Name ist",
          nameS: "Beispiel Vor- und Nachname",
          workExperience: "7",
          workExperienceL: "Ich habe __7__ Jahre Berufserfahrung.",
          workExperienceS: "Beispiel für 7 Jahre berufliche Erfahrung",
          date: "12.02.2023",
          dateL: "... das Stellenangebot vom ",
          dateS: "Beispiel Stellenanzeigen-Datum",
          media: "LinkedIN",
          mediaL: "Ich habe von der Stelle auf ",
          mediaS: "Beispiel Medium",
          jobtitle: "Vertriebsleiter Unterhaltungselektronik",
          jobtitleL: "... für die Stelle als ",
          jobtitleS: "Beispiel für einen Stellentitel, wie er in einer Anzeige erscheinen würde.",
          workExperiences: "Vertriebsleiter Unternehmen X, Vertriebsteamleiter Elektronik Unternehmen Y, Telesales Unterhaltungselektronik",
          workExperiencesL: "... relevante Erfahrung aus meinen vorherigen Rollen als ",
          workExperiencesS: "Beispiel, wie man vorherige Jobrollen am besten auflistet.",
          specialty: "Vertriebsmitarbeiter",
          specialtyL: "Der Begriff, der meinen Beruf beschreibt, lautet ",
          specialtyS: "Beispiel für einen Beruf",
          degree: "Bachelor B.A. Marketing",
          degreeL: "Mein höchster akademischer Abschluss oder formale Qualifikation ist ",
          degreeS: "Beispiel für einen akademischen Abschluss",
          treasure: "Mein Partner und meine Familie.",
          treasureL: "Was mir am wichtigsten ist, ist: ",
          treasureS: "Beispiel dafür, was für jemanden wichtig ist",
          jobDeliverable: "Umsatz steigern, ein Team aufbauen",
          jobDeliverableL: "Ergebnisse aus der Arbeit:",
          jobDeliverableS: "Beispiel für Ergebnisse aus einem Vertriebsjob",
          applicantChallenge: "Als Profi wachsen, mein Branchenwissen in Richtung xyz erweitern",
          applicantChallengeL: "Die Hauptgründe, warum ich nach einem Job suche, sind: ",
          applicantChallengeS: "Beispiel für Gründe, nach einem neuen Job zu suchen.",
          applicantGoal: "Eine leitende Position. Teamleiter oder Regionalleiter.",
          applicantGoalL: "Ich suche eine ",
          applicantGoalS: "Beispiel für eine Rolle",
          applicantSearch: "Stöbern in meinem Netzwerk, Internetrecherche",
          applicantSearchL: "So habe ich versucht, Jobs zu finden:",
          applicantSearchS: "Beispiel für Maßnahmen zur Suche nach einem geeigneten Job",
          companyName: "Bloomberg L.P.",
          companyNameL: "Der rechtliche Name des Unternehmens lautet ",
          companyNameS: "Beispiel für den rechtlichen Firmennamen",
          companyProblem: "Datenüberlastung im Finanzbereich.",
          companyProblemL: "Das Unternehmen hat sich zum Ziel gesetzt, das Problem der ",
          companyProblemS: "Beispiel für ein Problem, das ein Unternehmen angeht.",
          companyThreat: "Finanzprofis werden sich verteilen und vernetzen.",
          companyThreatL: "Im Falle des Scheiterns wird die Konsequenz sein: ",
          companyThreatS: "Beispiel für eine drohende Bedrohung.",
          hiringReason: "Unternehmensausbau in den Markt xyz.",
          hiringReasonL: "Der Grund, warum das Unternehmen einstellt, ist ",
          hiringReasonS: "Beispiel für einen Einstellungsgrund.",
          companyInitiative: "Eine Lateinamerika-Wachstumsagenda",
          companyInitiativeL: "Das Unternehmen hat initiiert ",
          companyInitiativeS: "Beispiel für eine strategische Initiative",
          city: "Boston, MA",
          cityL: "Die Stelle ist in ",
          cityS: "Beispiel für einen Arbeitsort.",
          qualification: "Zertifizierter Professional Sales Leader (CPSL)",
          qualificationL: "Meine relevantesten Qualifikationen sind: ",
          qualificationS: "Beispiel für eine Jobqualifikation",
          languages: "Englisch Muttersprache, Russisch C2, Spanisch C1",
          languagesL: "Meine beruflichen Sprachkenntnisse sind: ",
          languagesS: "Beispiel für berufliche Sprachkenntnisse",
          skill3: "Effektive Kommunikation, Produktkenntnisse und Verkaufsdemonstrationen",
          skill3L: "Meine relevantesten Fähigkeiten sind: ",
          skill3S: "Beispiel für Berufsfähigkeiten",
          references: "Michael Smith, CFO Unternehmen X, Sarah Wighely, CPO Unternehmen Y",
          referencesL: "Meine relevantesten beruflichen Referenzen sind: ",
          referencesS: "Beispiel für berufliche Referenzen",
          desire: "Aktien erhalten, persönliches Wachstum und Verdienstmöglichkeiten",
          desireL: "Meine wichtigsten Gründe für die Arbeit sind: ",
          desireS: "Beispiel für Prioritäten",
          sustainable: "Flexible Arbeitszeiten, kontinuierliche Schulungen und Elternzeit.",
          sustainableL: "Die folgenden Bedingungen machen die Zusammenarbeit für mich nachhaltig: ",
          sustainableS: "Beispiel für Faktoren, die einen Job nachhaltig machen.",
          companyOffering: "Mentoring- und Schulungsprogramm",
          companyOfferingL: "Diese Unternehmensangebote entsprechen meinen Anforderungen: ",
          companyOfferingS: "Beispiel für Unternehmensangebote.",
          companyNotOffering: "Elternzeit über Feiertage hinaus und flexible Arbeitszeiten.",
          companyNotOfferingL: "Es scheint, dass das Unternehmen nicht anbietet ",
          companyNotOfferingS: "Beispiel für Dinge, die ein Unternehmen möglicherweise nicht anbietet",
          start: "Salesforce CRM verwalten und SMART-Ziele setzen.",
          startL: "Für einen erfolgreichen Start muss ich lernen ",
          startS: "Beispiel für neue Fähigkeiten, die gelernt werden müssen.",
          learn: "Microsoft Dynamics 365 CRM Admin Fähigkeiten, OKR-Ziel-System",
          learnL: "Auf ähnliche Weise habe ich diese Fähigkeiten im Job gelernt: ",
          learnS: "Beispiel für im Job erworbene Fähigkeiten",
          change: "mehr Verantwortung, Einstellungsaufgaben und höheres Budget.",
          changeL: "Auf diesem Job würde ich ",
          changeS: "Beispiel für Veränderungen von einem Job zum anderen",
          questions: "Ist Vaterschaftsurlaub möglich? Welche Optionen gibt es, um die Arbeitszeiten flexibler zu gestalten?",
          questionsL: "Ich möchte wissen: ",
          questionsS: "Beispiel für Fragen zur Klärung.",
          questionMarks: "Habe ich ausreichende Erfahrung? Kann ich Ergebnisse liefern, trotz flexibler Arbeitszeiten?",
          questionMarksL: "Mit Blick auf Ihren Lebenslauf und Ihr Anschreiben frage ich mich: ",
          questionMarksS: "Beispiel für Fragezeichen, die ein Arbeitgeber haben könnte",
          questionMarksExamples: "Flexible Arbeitszeitregelung",
          questionMarksExamplesL: "Sie könnten sich fragen, ",
          questionMarksExamplesS: "Beispiel für eine mögliche Frage.",
        },
        resume: {
          headline: "Lebenslauf-Checkliste",
          subHeadline: "Überprüfen Sie Ihren Lebenslauf auf folgende Übereinstimmungen:",
          benefit: "Stellen Sie sicher, dass Sie relevante Informationen zeigen, die mit Ihrer Erzählung übereinstimmen.",
          name: "Name: ",
          workExperience: "Berufserfahrung in Jahren: ",
          media: "Medienkonto: ",
          specialty: "Beruf: ",
          degree: "Akademischer Abschluss oder Schulabschluss: ",
          applicantChallenge: "Ziele: ",
          applicantGoal: "Gewünschte Position: ",
          qualification: "Qualifikationen: ",
          languages: "Sprachkenntnisse: ",
          skill3: "Fähigkeiten: ",
          references: "Referenzen ",
          workExperiences: "Berufserfahrungen: ",
          change: "Angabe dessen, was geändert werden soll: ",
          questionMarks: "Fragezeichen: ",
          questionMarksExamples: "Beispiele für Fragezeichen",
        },
        search: {
          headline: "Job-Such-Checkliste",
          subHeadline: "Überprüfen Sie, ob dies wirklich das ist, was Sie suchen:",
          benefit: "Sparen Sie Zeit und Mühe. Konzentrieren Sie sich nur auf Jobs, die Sie suchen.",
          jobTitle: "Ist das das, was Sie tun möchten? - Berufsbezeichnung: ",
          workExperience: "Haben Sie Erfahrung damit? - Ihre Berufserfahrung in Jahren: ",
          treasure: "Fördert der Job, was Ihnen wichtig ist? - Ihr Schatz: ",
          specialty: "Entspricht der Job Ihrem Beruf? - Ihr Beruf: ",
          degree: "Ermöglicht Ihnen Ihr Abschluss, diesen Job zu machen? - Ihr Abschluss: ",
          jobDeliverable: "Können und wollen Sie diese Ergebnisse erzielen: ",
          applicantChallenge: "Ermöglicht Ihnen der Job, Ihrer Herausforderung zu begegnen? - Ihre Herausforderung: ",
          applicantGoal: "Ist das das, wonach Sie suchen? - Ihr Ziel: ",
          companyProblem: "Ist die Unternehmensmission für Sie interessant? - Unternehmensmission: ",
          companyThreat: "Kümmert es Sie, was passiert, wenn sie scheitern? - Wenn sie scheitern: ",
          hiringReason: "Ist der Einstellungsgrund überzeugend? - Einstellungsgrund: ",
          companyInitiative: "Stimmen Sie mit der strategischen Initiative überein, die dem Angebot zugrunde liegt? - Unternehmensinitiative: ",
          desire: "Bietet der Job, was Sie sich wünschen? - Ihr Wunsch: ",
          sustainable: "Bietet der Job und das Setup alles, damit Sie ihn über mehrere Jahre hinweg ohne Beeinträchtigung von Wesentlichem wie Gesundheit, Finanzen, Familie oder Hobbys ausüben können? - Ihre Nachhaltigkeitsfaktoren: ",
          companyOffering: "Entspricht dies Ihren Bedürfnissen? - Unternehmensangebot: ",
          companyNotOffering: "Sind diese fehlenden Faktoren ein Ausschlusskriterium? - Unternehmen bietet nicht",
          start: "Möchten Sie diese Dinge lernen? Wird dies langfristig von Vorteil sein? - Lernanforderungen: ",
          learn: "Entsprechen Ihre vergangenen Lernerfahrungen denjenigen, die in diesem Job erforderlich sind? - Ähnliche vergangene Lernerfahrungen:",
          change: "Sind diese Veränderungen in Ihrem Interesse? - Änderungen zu früheren Engagements:",
          references: "Ist das Feedback Ihrer Referenzen unterstützend? Haben sie die Befugnis, Ihnen für diesen Job Anerkennung zu geben? Referenzen: ",
          questions: "Ist diese Gelegenheit es wert, Ihre Fragen beantwortet zu bekommen? - Ihre Fragen lauten: ",
          questionMarks: "Sind die potenziellen Fragezeichen zu groß? - Potenzielle Fragezeichen lauten: ",
          questionMarksExamples: "Würden Sie sich selbst einstellen, wenn Sie diese Beispiele für potenzielle Fragezeichen betrachten? - Beispiele für potenzielle Fragezeichen: ",
        },
        coverletter: {
          headline: "Bewerbungsschreiben und Bewerbungsunterlagen",
          subHeadline: "Überprüfen Sie Ihr Bewerbungsschreiben und Ihre Bewerbungsunterlagen auf Folgendes:",
          benefit: "Konsistente, fokussierte Bewerbung, um die Aufmerksamkeit Ihres Publikums zu erregen.",
          letter: "Ein Bewerbungsschreiben, das eine hochrangige Version Ihrer Geschichte enthält. Alle Teile, angefangen von der Exposition bis zur Resolution, sollten enthalten sein. Wir empfehlen, Ihre Geschichte als Anschreiben zu senden, da es bereits auf einem hohen Niveau ist und sich auf das Wesentliche konzentriert. In einigen Fällen müssen Sie sich jedoch mit Wortgrenzen für Online-Bewerbungen auseinandersetzen. In diesem Fall müssen Sie die Geschichte kürzen, indem Sie sich auf die absoluten Höhepunkte konzentrieren und gleichzeitig die Struktur von der Exposition bis zur Resolution beibehalten.",
          resume: "Ein Lebenslauf, der die Belege für den Inhalt Ihrer Geschichte enthält",
          documents: "Dokumente als Nachweis Ihrer Qualifikation",
        },
        interview: {
          headline: "Interview-Checkliste",
          subHeadline: "Wir empfehlen Ihnen, einen Spielplan rund um die Wesentlichen zu erstellen: Warum?, Wie?, Was?, Potenzielle Risiken und Maßnahmen.",
          benefit: "Sie betreten das Interview mit einem Plan und einem Ziel.",
          benefit2: "Sie haben Werkzeuge, um die Kontrolle zu übernehmen und sich darauf vorzubereiten, potenzielle Schwächen bestmöglich zu behandeln.",
          why: "Alles beginnt mit dem WARUM. Bereiten Sie sich darauf vor, Ihre Gründe für die Übernahme des Jobs zu erläutern: ",
          how: "Wie werden Sie den Job machen? Bereiten Sie Ihr ausführliches Konzept auf der Grundlage Ihrer Fähigkeiten, Qualifikationen und beruflichen Erfahrung vor: ",
          skills3: "Fähigkeiten: ",
          qualification: "Qualifikation: ",
          workExperience: "Berufserfahrung: ",
          Degree: "Höchster akademischer Grad: ",
          what: "Die ultimative Frage, die beantwortet werden muss: Was können Sie für mich tun? Bereiten Sie das Ergebnis vor, das Sie erzielen möchten: ",
          risk: "Gehen Sie auf potenzielle Risiken oder wahrgenommene Schwächen ein: ",
          questions: "Stellen Sie sicher, dass Sie Ihre Fragen ansprechen und eine definitive Antwort erhalten: ",
          preparedHeadline: "Zeit zu glänzen",
          preparedSubHeadline: "Mit allen Häkchen sind Sie bereit, Ihre beste Geschichte zu erzählen. Los geht's und erzählen Sie sie!",
        },
        storysection: { 
          headline: "Noch keine Geschichte",
          subHeadline: "Beginnen Sie mit den Antworten und erstellen Sie Ihre Geschichte.",
          cta: "Beantworten Sie alle Fragen, um Ihre vollständige Geschichte zu erhalten",
          finalStepH: "Voilà!.. ",
          finalStepH1: ".. jetzt überprüfen und bearbeiten für ein starkes Ergebnis. ",
          finalStepD: "Veröffentlichen Sie niemals Ihren ersten Entwurf. Das weiß jeder PRO-Schreiber. Um Ihnen bei der effizienten Überprüfung und Bearbeitung zu helfen, ",
          finalStepD1: "sind alle Ihre Einträge lila markiert",
          finalStepT: "Beim Überfahren erhalten Sie Überprüfungsanweisungen. Folgen Sie ihnen, um Ihre Geschichte in Bezug auf Erzählung, Kohärenz und Ausdruck zu verbessern",
          reviewMode: "Ihre Geschichte [Überprüfungsmodus]",
          cleanMode: "Ihre Geschichte",
          paragraph1: "Mein Name ist {{name}}. Hiermit bewerbe ich mich um die Stelle als {{jobTitle}}, ausgeschrieben am {{date}} in {{media}}.",
          name: "Mein Name ist",
          nameT: "Doppelt überprüfen: Ist dies der Name in Ihrem Lebenslauf?",
          jobtitle: " Hiermit bewerbe ich mich um die Stelle als ",
          jobTitleT: "Doppelt überprüfen: Ist dies der Jobtitel aus der Anzeige?",
          date: " Datum ",
          dateT: "Doppelt überprüfen: Ist dies das genaue Datum aus der Stellenanzeige?",
          media: " ausgeschrieben in ",
          mediaT: "Doppelt überprüfen: Ist dies der Ort, an dem Sie die Anzeige gefunden haben?",
          paragraph2: "Ich habe über {{workExperience}} Jahre Berufserfahrung in {{workExperiences}} und spezialisiere mich auf den Beruf des {{specialty}} mit einem Abschluss in {{degree}}.",
          workexperience: " Ich habe über ",
          workexperienceT: "Doppelt überprüfen: Ist dies Ihre gesamte berufliche Arbeitserfahrung?",
          workexperience1: " Jahre Berufserfahrung in ",
          workexperience1T: "Doppelt überprüfen: Sind dies Ihre Top 3 relevanten Berufserfahrungen für diesen Job?",
          specialty: " und spezialisiere mich auf den Beruf des ",
          specialtyT: "Doppelt überprüfen: Ist Ihr Beruf relevant für die Stellenanzeige?",
          degree: " mit einem Abschluss in ",
          degreeT: "Doppelt überprüfen: Ist dies Ihre Liste Ihrer Abschlüsse, wie sie in Ihrem Lebenslauf zu finden ist?",
          paragraph3: "{{treasure}} ist meine Kraftquelle. Das befähigt mich, {{jobDeliverable}} zu liefern. Die Herausforderung, die mich dazu veranlasst, gerade jetzt nach einer Stelle zu suchen, ist {{applicantChallenge}}. Ich bin auf der Suche nach {{applicantGoal}}. Daher begann ich, nach einer passenden und offenen Stellenmöglichkeit zu suchen, zunächst durch {{applicantSearch}}.",
          treasure: ", ist meine Kraftquelle. Das befähigt mich, ",
          treasureT: "Doppelt überprüfen: Entspricht dies dem, was für Sie von größter Bedeutung ist? Ihr Schatz, Ihre Kraftquelle, wofür Ihre Lebensenergie steht,... ",
          jobDeliverable: "",
          jobDeliverableT: "Welche Ergebnisse müssen Sie in der Arbeit liefern? Was ist das Ergebnis? ",
          applicantChallenge: "Die Herausforderung, die mich dazu veranlasst, gerade jetzt nach einer Stelle zu suchen, ist ",
          applicantChallengeT: "Doppelt überprüfen: Ist dies DAS, wonach Sie suchen?",
          applicantGoal: "Ich bin auf der Suche nach ",
          applicantGoalT: "Doppelt überprüfen: Passt das, wonach Sie suchen, zu der vorliegenden Stelle? ",
          applicantSearch: "Daher begann ich, nach einer passenden und offenen Stellenmöglichkeit zu suchen, zunächst durch ",
          applicantSearchT: "Doppelt überprüfen: Enthalten diese Methoden die Mittel, mit denen Sie diese Stelle gefunden haben?",
          paragraph4H: "Unternehmensherausforderung; da komme ich ins Spiel",
          paragraph4: "{{companyName}} verfolgt das Problem von {{companyProblem}}. Dabei sieht es die Bedrohung von {{companyThreat}} im Falle des Scheiterns. Der Grund, warum das Unternehmen einstellt, ist {{hiringReason}}. In diesem Bestreben hat das Unternehmen {{companyInitiative}} eingeleitet.",
          companyName: " verfolgt das Problem von  " ,
          companyNameT: "Doppelt überprüfen: Ist dies der offizielle Firmenname, wie er im Handelsregister erscheint?",
          companyProblem: "Dabei sieht es die Bedrohung von ",
          companyProblemT: "Doppelt überprüfen: Im großen Bild: Ist das, was das Unternehmen zu lösen versucht?",
          companyThreat: " im Falle des Scheiterns.",
          companyThreatT: "Doppelt überprüfen: Ist das das, was passiert, wenn sie scheitern?",
          hiringReason: " Der Grund, warum das Unternehmen einstellt, ist ",
          hiringReasonT: "Doppelt überprüfen: Ist das wirklich der Grund?",
          companyInitiative: " In diesem Bestreben hat das Unternehmen ",
          companyInitiativeT: " Doppelt überprüfen: Ist das wirklich der Einstellungskontext des Unternehmens?",
          paragraph5: "Genau hier komme ich ins Spiel. Ich habe die folgenden Qualifikationen, Fähigkeiten und den Wunsch, die erforderlichen Aufgaben zu erfüllen und die Arbeit zu erledigen. Meine drei wichtigsten relevanten Qualifikationen sind: {{qualification}}. Ich spreche die folgenden berufsrelevanten Sprachen fließend: {{languages}}. Meine drei wichtigsten relevanten Fähigkeiten sind {{skill3}}. Meine drei wichtigsten relevanten Referenzen sind: {{references}}. Meine relevanten Berufserfahrungen habe ich bereits zu Beginn erwähnt.",
          qualification: "Genau hier komme ich ins Spiel. Ich habe die folgenden Qualifikationen, Fähigkeiten und den Wunsch, die erforderlichen Aufgaben zu erfüllen - und die Arbeit zu erledigen. Meine relevantesten Qualifikationen sind, ",
          qualificationT: "Doppelt überprüfen: Sind diese Qualifikationen prominent in Ihrem Lebenslauf aufgeführt, und haben Sie einen Nachweis dafür?",
          languages: " Ich spreche die folgenden Sprachen fließend: ",
          languagesT: "Doppelt überprüfen: Sind diese Sprachen prominent in Ihrem Lebenslauf aufgeführt, und haben Sie einen Nachweis für das Niveau?",
          skill3: " Meine drei wichtigsten relevanten Fähigkeiten sind ",
          skill3T: "Doppelt überprüfen: Werden diese Fähigkeiten in Ihrem Lebenslauf erwähnt, und haben Sie einen Nachweis dafür?",
          references: " Meine relevanten Referenzen sind ",
          referencesT: "Doppelt überprüfen: Sind die Referenzen in Ihrem Lebenslauf?",
          workExperiences: " Meine relevanten Berufserfahrungen habe ich bereits zu Beginn erwähnt.",
          workExperiencesT: "Doppelt überprüfen: Sind dies Ihre drei wichtigsten relevanten Berufserfahrungen?",
          paragraph6H: "Zu hinterfragende Annahmen",
          paragraph6: "Das Versprechen, das das Stellenangebot für mich bereithält, stimmt mit diesen Wünschen überein: {{desire}} ist der Grund, warum ich die Arbeit machen würde. Darüber hinaus machen die folgenden Faktoren die Arbeitsbindung für mich mittel- bis langfristig nachhaltig: {{sustainable}}. Das Unternehmen scheint dies durch Bereitstellung von {{companyOffering}} aufrechtzuerhalten. Das Unternehmen scheint {{companyNotOffering}} nicht bereitzustellen, oder zumindest habe ich davon noch keine Kenntnisse. Aufgrund dieser gegebenen Faktoren, wie oben erwähnt, glaube ich, dass ich {{companyName}} bei seiner Mission unterstützen kann, das Problem von {{companyProblem}} anzugehen, was die Motivation entfacht, mich für Ihre ausgeschriebene Position als {{jobTitle}} zu interessieren.",
          desire: "Das Versprechen, das das Stellenangebot für mich bereithält, stimmt mit dem überein, was ich mir wünsche, nämlich:",
          desireT: "Doppelt überprüfen: Ist es klar und konsistent mit Ihrer Erzählung, warum Sie diese Punkte wünschen? Ist es sicher, dass sie das anbieten?",
          desire1: " ist der Grund, warum ich die Arbeit machen würde.",
          sustainable: "Darüber hinaus machen die folgenden Faktoren die Arbeitsbindung für mich mittel- bis langfristig nachhaltig: ",
          sustainableT: "Doppelt überprüfen: Sind diese Faktoren entscheidend, um Ihr Interesse, Ihre Motivation, Finanzen, Gesundheit und Vernunft aufrechtzuerhalten? Wenn Sie nicht in 6 Monaten einen neuen Job suchen möchten, stellen Sie sicher, dass Sie diese richtig einordnen.",
          companyOffering: "Das Unternehmen scheint dies durch Bereitstellung von ",
          companyOfferingT: "Doppelt überprüfen: Nennen Sie die Unternehmensleistungen oder Angebote, die zu Ihren zuvor genannten wichtigen Faktoren passen.",
          companyNotOffering: "Das Unternehmen scheint nicht zu bieten ",
          companyNotOfferingT: "Doppelt überprüfen: Nennen Sie die Vorteile oder Angebote, die Ihnen fehlen oder über die Sie sich nicht sicher sind, ob überhaupt etwas in dieser Richtung angeboten wird. Pro-Tipp: Dieser Punkt ist nützlich, wenn Sie ein Vorstellungsgespräch haben. Erheben Sie ihn, und Sie haben eine großartige Chance, im Interview Kontrolle und Boden zu gewinnen.",
          companyNotOffering2: ", oder zumindest habe ich davon noch keine Kenntnisse.",
          conclusion1: "Aufgrund dieser gegebenen Faktoren, wie oben erwähnt, glaube ich, dass ich {{companyName}} bei seiner Mission, das Problem von {{companyProblem}} anzugehen, unterstützen kann, was die Motivation entfacht, mich für Ihre ausgeschriebene Position als {{jobTitle}} zu interessieren.",
          conclusion2: " in seiner Mission, das Problem von ",
          conclusion3: " anzugehen, was die Motivation entfacht, mich für Ihre ausgeschriebene Position als ",
          start: "Für einen erfolgreichen Start müsste ich wahrscheinlich lernen: ",
          startT: "Doppelt überprüfen: Ist dies die Lücke zwischen Ihren Fähigkeiten und Erfahrungen und der Realität und den Anforderungen des Jobs? Pro-Tipp: Der Start in einen neuen Job wird immer firmenspezifische Neuheiten beinhalten, unabhängig von Ihrem Fachwissen. Die Erwartungen an das, was kommen wird, können interessante Einblicke im Bewerbungsprozess ermöglichen.",
          learn: "In der Vergangenheit habe ich bewiesen, dass ich auf dem Job lernen kann, was notwendig ist. Zum Beispiel durch meine Aktivitäten und/oder Erfahrungen in ",
          learnT: "Doppelt überprüfen: Beweisen diese Aktivitäten wirklich, dass Sie in der Lage sind, die erforderlichen Fähigkeiten zu erlernen?",
          change: "Was ich vermute, dass sich für mich ändern wird, basierend auf meinem aktuellen Verständnis des Jobs, ist: ",
          changeT: "Doppelt überprüfen: Ergeben sich diese Unterschiede aus Ihren früheren Erfahrungen im Vergleich zu Ihren Erwartungen an die kommende Aufgabe?",
          preClimaxParagraph: "Für einen erfolgreichen Start müsste ich wahrscheinlich lernen: {{start}}. In der Vergangenheit habe ich bewiesen, dass ich auf dem Job lernen kann, was notwendig ist. Zum Beispiel durch meine Aktivitäten und/oder Erfahrungen in {{learn}}. Was ich vermute, dass sich für mich ändern wird, basierend auf meinem aktuellen Verständnis des Jobs, ist: {{change}}.",
          wrapUpH: "Auflösung",
          questions: "Aus meiner Perspektive scheint jetzt Klarheit über viele Aspekte zu bestehen, aber einige Punkte wie ",
          questionsT: "Doppelt überprüfen: Sind diese Fragen konsistent mit Ihrer Erzählung? Sind dies Dealbreaker oder Nice-to-haves?",
          questionMarks: " erfordern noch Klärung. Ich könnte mir vorstellen, dass Sie auch Fragen zu meinem ",
          questionMarksT: "Doppelt überprüfen: Sind diese potenziellen Fragen konsistent mit den von Ihnen bereitgestellten Informationen in Ihren Bewerbungsunterlagen?",
          questionMarks1: " zum Beispiel ",
          questionMarksExamples: " Es ist entscheidend festzustellen, ob diese Unklarheiten mit unseren beiderseitigen Interessen übereinstimmen.",
          questionMarksExamplesT: "Doppelt überprüfen: Wenn Sie den Advocatus Diaboli spielen, ist dies der offensichtlichste mögliche Interessenspunkt?",
          wrapup: "Aus meiner Perspektive scheint jetzt Klarheit über viele Aspekte zu bestehen, aber einige Punkte wie {{questions}} erfordern noch Klärung. Ich könnte mir vorstellen, dass Sie auch Fragen zu meinem {{questionMarks}} zum Beispiel {{questionMarksExamples}} haben. Es ist entscheidend festzustellen, ob diese Unklarheiten mit unseren beiderseitigen Interessen übereinstimmen.",
          wrapup2: "Ich würde die Gelegenheit bevorzugen, schnell zur Verwirklichung der Unternehmensmission beizutragen. Um dies zu erreichen, würde ich gerne die Stellenbeschreibung in einem persönlichen Gespräch besprechen. Auf diese Weise können wir verbleibende Fragen und relevante Aspekte gemeinsam klären.",
          },
          favorites: {
            headline: "Lieblingsgeschichten",
            note: "Noch keine Lieblingsgeschichten. Finde deine gespeicherten Favoriten ",
            add: "Zur Favoritenliste hinzufügen",
          },
          learn: {
            headline: "Lerne und verbessere deine Geschichtenerzählung",
            subHeadline: "Hol dir topaktuelles Wissen in wenigen prägnanten Schritten.",
            benefit: "SCHNELL: Gehe direkt zum Thema, das dir am wichtigsten ist.",
            benefit2: "NACHHALTIG: Gehe Schritt für Schritt vor und hinterfrage deine Geschichte mit den vorgestellten Argumenten und Methoden.",
            path: "Beginne zu lernen. Der Weg kann bis zu 30 Minuten dauern",
          },
          learnSteps: {
            intro: "Einführung",
            introtext1: "Um eine Bewerbung effektiv zu erstellen, ist es hilfreich, mit den relevanten Kontexten des Jobs, von dir selbst und den Konzepten des Geschichtenerzählens und effektiven Schreibens vertraut zu sein. Aber Geschichtenerzählen und Schreiben allein sind umfangreiche Themen. Wie weißt du, wann du bereit bist, die beste Bewerbung zu erstellen?",
            introtext2: "Willkommen beim ©Storylenses-Kurs! Dieser anfängerfreundliche, beispielgeführte Kurs führt dich durch das notwendige Wissen für die Erstellung der Geschichte, wie du am besten zu dem Job passt. Du wirst deine Geschichte schrittweise aufbauen, beginnend mit einer Analyse des Jobkontexts und einer Selbstanalyse, über eine Venn-Diagramm- und Geschichtenerzählungsmigration.",
            introtext3: "Jeder Abschnitt baut auf dem vorherigen auf, daher solltest du der Reihe nach vorgehen. Dieses Tutorial setzt Kenntnisse im Schreiben in deiner Muttersprache voraus und keine Kenntnisse im Geschichtenerzählen. Wenn du bereits mit dem Geschichtenerzählen vertraut bist, empfehlen wir dir dennoch, nichts zu überspringen und der Reihenfolge zu folgen.",
            introtext4: "Wenn du Fragen zu allem rund um das Geschichtenerzählen oder diesen Kurs hast, kannst du gerne unsere Community auf ",
            linkText: "Discord",
            introtextcta: "Lass uns anfangen!",
            framework: "Rahmen",
            frameworkHeadline: "Was ist diese Methode?",
            framework1: "Dies ist ein flexibler Geschichtenerzählungsrahmen, der dir Bausteine gibt, um die 'eine' Geschichte für deine Bewerbungen zu erstellen. Aber was genau meinen wir damit? Lassen Sie uns etwas Zeit damit verbringen, zu erläutern, was dieser Rahmen und Geschichtenerzählung sind und wie sie helfen können.",
            frameworkSubHeadline2: "Bausteine einer Bewerbung",
            framework2: "Es gibt einige Dinge, die du beachten musst, wenn du Bewerbungen erstellst. Dazu gehören:",
            framework2li1: "Verständnis des Jobkontexts - welche Kontextnarrative sind mit dir und dem Job verbunden.",
            framework2li2: "Verständnis deiner selbst - Qualifikation und Motivation für den Job",
            framework2li3: "Wie du dich verbindest - was der Job, der Chef, der Kontext für dich und umgekehrt tun können",
            framework2li4: "Erzählung - Strukturierung der Informationen und Botschaften, die du vermitteln möchtest.",
            framework2li5: "Lieferungen - Die Formate, die erforderlich sind, um deine Bewerbungsgeschichte zu liefern",
            frameworkSubHeadline3: "Was ist dieser Rahmen?",
            framework3: "Dies ist ein Kommunikationsrahmen zum Erstellen von Geschichten, die deine Botschaft an deine Zielgruppe übermitteln. Unter Geschichten verstehen wir die Botschaften, die die Zielgruppe in einem Bewerbungskontext sieht und mit denen sie interagiert.",
            framework4: "Mit Framework meinen wir, dass es hilfreiche Funktionen bereitstellt, um deine Story-Elemente und Struktur aufzubauen, aber es dir überlässt, welche Elemente und welche Struktur du in deine Bewerbung aufnehmen möchtest. Der Effekt von ©LIC besteht darin, dass deine Bewerbung eine erhöhte Wahrscheinlichkeit hat, Aufmerksamkeit zu erregen, dass deine Erzählung, und nur deine Sichtweise, übernommen und geteilt wird.",
            framework5: "Ein Teil des Erfolgs dieses Rahmenwerks besteht darin, dass es in Bezug auf andere Aspekte der Erstellung von Bewerbungen relativ unvoreingenommen ist. Dies hat zu einem blühenden Ökosystem von Tools und Lösungen von Drittanbietern geführt, die im Einklang mit diesem Prozess und dieser Tooling verwendet werden können.",
            framework6: "Das bedeutet jedoch auch, dass der Aufbau einer vollständigen Bewerbung von Grund auf etwas Aufwand erfordert. Bewerber müssen Zeit damit verbringen, den Fragebogen auszufüllen, einen zweiten Entwurf durchzugehen und die Checklisten durchzuarbeiten, um die Bewerbungsdokumente und das Interview mit der Erzählung in Einklang zu bringen.",
            frameworkSubHeadline7: "Was ist ein Geschichtenerzählungsrahmen?",
            framework7: "Eine Geschichte aufzubauen bedeutet: Ein Ziel oder einen Appell zu definieren und ihn an eine Zielgruppe mittels Medium, Inhalt, Botschaft, Dramaturgie zu richten, um eine Wirkung zu erzielen. Mit Rahmenwerk meinen wir, dass es die Werkzeuge und Konfigurationen für eine Bewerbungsgeschichte behandelt und zusätzliche Struktur, Funktionen und Optimierungen für den Aufbau deiner Story-Outputs bietet.",
            framework8: "Ob du nun Raketenwissenschaftler oder Verkäufer bist, du kannst dieses Tool nutzen, um deine beste Bewerbungsgeschichte zu erstellen. In den nächsten Lektionen werden wir besprechen, wie du beginnen kannst.",

          },
          



          
        
        
        

      }
        }
      },
      es: {
        translation: {

        }
      },   
      pt: {
        translation: {

        }
      },  
      fr: {
        translation: {

        }
      }, 
    
  });

export default i18n;