import { useState, useEffect } from 'react';
import './App.css';
import Form from './components/common/Form';
import Home from './pages/Home';
import Learn from './pages/Learn';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  unstable_HistoryRouter,
  Switch,
} from "react-router-dom";

import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, sendPasswordResetEmail } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './components/Navbar';
import { Navbar } from './components/Navbar';
import Storyform from './components/StoryForm';
import './style.css';
import 'tailwindcss/lib/css/preflight.css';
import AnimationRevealPage from './helpers/AnimationRevealPage';
import Hero from './components/treact/hero/TwoColumnWithInput';
import Landing from 'pages/Landing';
import Footer from 'components/treact/footers/MiniCenteredFooter'
import { db } from './firebase-config';
import Terms from './pages/TermsOfService';
import Privacy from './pages/PrivacyPolicy';
import ForgotPassword from './components/common/ForgotPassword';



function App() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  let navigate = useNavigate();


   // Add a state to track whether the user is authenticated
   const [user, setUser] = useState(null);
   const [customer, setCustomer] = useState({
    email: '',
    stripeId: '',
    stripeLink: '',
  });
  const [customerHasPaid, setCustomerHasPaid] = useState(false);


  useEffect(() => {
    let authToken = sessionStorage.getItem('Auth Token')
  
    if (authToken) {
      // User is authenticated
      const currentPath = window.location.pathname;
  
      // If the current path is not login or register, navigate to the current path
      if (currentPath !== '/login' && currentPath !== '/register') {
        navigate(currentPath);
      } else {
        // If the current path is login or register, navigate to the home page
        navigate('/home');
      }
    } else {
      // User is not authenticated
      const auth = getAuth();
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setUser(user);
        } else {
          setUser(null);
        }
      });
    }
  }, [navigate]);

  const checkPaymentStatus = async (user) => {
    try {
      // Make an API request to your backend to fetch the webhook data
      const response = await fetch('/api/get-webhook-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: user.uid }),
      });
  
      if (response.ok) {
        const data = await response.json();
  
        // Check the webhook data to see if the user has paid
        const hasPaid = data.webhookData.payment_intent.status === 'succeeded';
  
        return hasPaid;
      } else {
        console.error('Failed to fetch webhook data');
        return false; // Failed to fetch webhook data
      }
    } catch (error) {
      console.error('Error fetching webhook data:', error);
      return false; // Error occurred while fetching webhook data
    }
  };
  
  
  
  const handleAction = (id) => {
    console.log('Email:', email);
    console.log('Password:', password);
    const authentication = getAuth();
  
    if (id === 1) {
      signInWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
          navigate('/home');
        })
        .catch((error) => {
          if (error.code === 'auth/wrong-password') {
            toast.error('Please check the Password');
          }
          if (error.code === 'auth/user-not-found') {
            toast.error('Please check the Email');
          }
        });
    }
  
    if (id === 2) {
      createUserWithEmailAndPassword(authentication, email, password)
        .then((response) => {
          sessionStorage.setItem('Auth Token', response._tokenResponse.refreshToken);
          navigate('/home');
        })
        .catch((error) => {
          if (error.code === 'auth/email-already-in-use') {
            toast.error('Email Already in Use');
          }
        });
    }
  
    if (id === 3) {
      // Handle password reset
      sendPasswordResetEmail(authentication, email)
        .then(() => {
          // Password reset email sent successfully
          console.log('Password reset email sent successfully');
          // You may want to redirect or show a message here
        })
        .catch((error) => {
          console.error('Error sending password reset email:', error.message);
          // Handle error, you may want to show an error message
        });
    }
  };
  
  return (
    
<div className="App">
      
      <ToastContainer />
      <Routes>
        <Route path='/login' element={<Form title="Login" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(1)} />} />
        <Route path='/register' element={<Form title="Register" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(2)} />} />
        <Route path="/forgot-password" element={<Form title="PasswordReset" setEmail={setEmail} setPassword={setPassword} handleAction={() => handleAction(3)} />} />
        <Route path='/home' element={<Home />} />
        <Route path='/learn' element={<Learn />} />
        <Route path='/' element={<Landing />} />
        <Route path="register/:email" component={Form} />
        <Route path='/terms' element={<Terms />} />
        <Route path='/privacy' element={<Privacy />} />
      </Routes>
      <Footer />
      
    </div>
  );
}

export default App;