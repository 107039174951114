import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';


export const Upgrade = () => {
    const { t, i18n } = useTranslation();
    return (
        <Box
            display={'flex'}
            alignItems="center"
            gap={2}
            sx={{ m: 3, p: 3, bgcolor: `${'primary.light'}`, borderRadius: '8px' }}
        >
            <>
                <Box>
                    <Typography variant="h6" mb={1}>{t('subnav.cta')}</Typography>
                    <Button color="primary" target="_blank" href="#" variant="contained" aria-label="logout" size="small">
                    {t('subnav.button')}
                    </Button>
                </Box>
                {/* <Box mt="-35px">
                     <img alt="Remy Sharp" src={img1} width={100} />
                </Box>
                */}
            </>
        </Box>
    );
};
