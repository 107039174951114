import React from 'react';
import { useState, useEffect, useRef } from "react";
import { db } from '../../firebase-config';
import { collection, addDoc, serverTimestamp, query, where, getDocs, onSnapshot, orderBy, limit, doc, setDoc, arrayUnion, deleteDoc } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Grid, FormGroup, TextField, Paper, Typography, styled, Tooltip, Checkbox, Stack, Alert, AlertTitle, InputAdornment,List, ListItem, ListItemIcon, ListItemText, Link, Button } from "@mui/material";
import { useTranslation } from 'react-i18next';
import '../../styles/tooltip.css'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import GradingIcon from '@mui/icons-material/Grading';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Abc, AttachEmail } from '@mui/icons-material';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import BeenhereIcon from '@mui/icons-material/Beenhere';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import PsychologyIcon from '@mui/icons-material/Psychology';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import AbcIcon from '@mui/icons-material/Abc';
import PinIcon from '@mui/icons-material/Pin';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { IconButton } from '@mui/material';
import { PlayArrowRounded, PauseRounded } from '@mui/icons-material';
import { IconUnderline } from '@tabler/icons';
import AudioButton from '../../components/common/AudioButton';

const tooltipClasses = {
  tooltip: 'my-tooltip',
};

const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  '& .my-tooltip': {
    backgroundColor: '#e6e6fa', // Apply the custom background color
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));


const paperInputsStyle = {
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { border: "1px solid", borderColor: "primary.main" },
    "&:hover": {
      "& > fieldset": { borderColor: "primary.light" }
    }
  },
  "& .MuiFormLabel-root": {
    color: "primary.dark"
  }
}


const StoryItem = ({ story, handleFavoriteClick }) => {
  const { t, i18n } = useTranslation();

  const handlePrint = () => {
    window.print();
  };
  

  // Check if the 'story' prop is defined before accessing its properties
    if (!story) {
      return null; // Return null if 'story' is undefined or null
    }
  const key = story.id;

  return (
    <div key={key}>
     {story.name && story.jobTitle && story.date && story.media && story.workExperience && story.workExperiences && story.specialty && story.date && story.degree && story.treasure && story.jobDeliverable  && story.applicantChallenge && story.applicantGoal && story.applicantSearch && (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography variant='h3'>{t('storysection.reviewMode')}</Typography>
        <Typography variant="body2" mt={1}>
          {t('storysection.name')}
          <HtmlTooltip title={t('storysection.nameT')} arrow>
            <span className="lavenderBackground"> {story.name}</span>
          </HtmlTooltip>.
          {t('storysection.jobtitle')}
          <HtmlTooltip title={t('storysection.jobTitleT')} arrow>
            <span className="lavenderBackground">{story.jobTitle}</span>
          </HtmlTooltip>
          {t('storysection.date')}
          <HtmlTooltip title={t('storysection.dateT')} arrow>
            <span className="lavenderBackground">{story.date}</span>
          </HtmlTooltip>
          {t('storysection.media')}
          <HtmlTooltip title={t('storysection.mediaT')} arrow>
            <span className="lavenderBackground">{story.media}</span>
          </HtmlTooltip>.
          {t('storysection.workexperience')}
          <HtmlTooltip title={t('storysection.workexperienceT')} arrow>
            <span className="lavenderBackground">{story.workExperience}</span>
          </HtmlTooltip>
          {t('storysection.workexperience1')}
          <HtmlTooltip title={t('storysection.workExperiencesT')} arrow>
            <span className="lavenderBackground">{story.workExperiences}</span>
          </HtmlTooltip>
          {t('storysection.specialty')}
          <HtmlTooltip title={t('storysection.specialtyT')} arrow>
            <span className="lavenderBackground">{story.specialty}</span>
          </HtmlTooltip>
          {t('storysection.degree')}
          <HtmlTooltip title={t('storysection.degreeT')} arrow>
            <span className="lavenderBackground">{story.degree}.</span>
          </HtmlTooltip>        
          </Typography>
          <Typography variant="body2" mt={2}>
            <HtmlTooltip title={t('storysection.treasureT')} arrow> 
              <span className="lavenderBackground">{story.treasure}</span> 
            </HtmlTooltip>{t('storysection.treasure')} 
            <HtmlTooltip title={t('storysection.jobDeliverableT')} arrow>
              <span className="lavenderBackground">{story.jobDeliverable}.</span>
            </HtmlTooltip>
            {t('storysection.applicantChallenge')}
          <HtmlTooltip title={t('storysection.applicantChallengeT')} arrow>
            <span className="lavenderBackground">{story.applicantChallenge}. </span>
          </HtmlTooltip>
          {t('storysection.applicantGoal')} 
          <HtmlTooltip title={t('storysection.applicantGoalT')} arrow>
            <span className="lavenderBackground">{story.applicantGoal}. </span>
          </HtmlTooltip>
          {t('storysection.applicantSearch')} 
          <HtmlTooltip title={t('storysection.applicantSearchT')} arrow placement='top'>
            <span className="lavenderBackground">{story.applicantSearch}. </span>
          </HtmlTooltip>
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant='h3'>{t('storysection.cleanMode')}</Typography>
          <Typography variant="body2" mt={1}>
            {t('storysection.paragraph1', {
            name: story.name,
            jobTitle: story.jobTitle,
            date: story.date,
            media: story.media,
          } )} 
            {t('storysection.paragraph2', {
            workExperience: story.workExperience,
            workExperiences: story.workExperiences,
            specialty: story.specialty,
            degree: story.degree,
          } )} 
           </Typography>
           <Typography variant="body2" mt={2}>
            {t('storysection.paragraph3', {
            treasure: story.treasure,
            jobDeliverable: story.jobDeliverable,
            applicantChallenge: story.applicantChallenge,
            applicantGoal: story.applicantGoal,
            applicantSearch: story.applicantSearch,
          } )} 
           </Typography>

      </Grid>
    </Grid>
  )}

  {story.companyName && story.companyProblem && story.companyThreat && story.hiringReason && story.companyInitiative &&  (
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <Typography variant='h6' align='left' mt={4}>{t('storysection.paragraph4H')}</Typography>
        <Typography variant="body2" mt={2}>
        <HtmlTooltip title={t('storysection.companyNameT')} arrow>
          <span className="lavenderBackground">{story.companyName}</span>
        </HtmlTooltip>
        {t('storysection.companyName')} 
        <HtmlTooltip title={t('storysection.companyNameT')} arrow>
          <span className="lavenderBackground">{story.companyProblem}.</span>
        </HtmlTooltip>
        {t('storysection.companyProblem')} 
        <HtmlTooltip title={t('storysection.companyThreatT')} arrow>
          <span className="lavenderBackground">{story.companyThreat}</span>
        </HtmlTooltip> 
        {t('storysection.companyThreat')}
        {t('storysection.hiringReason')}
        <HtmlTooltip title={t('storysection.hiringReasonT')} arrow>
          <span className="lavenderBackground"> {story.hiringReason}.</span>
        </HtmlTooltip> 
        {t('storysection.companyInitiative')} 
        <HtmlTooltip title={t('storysection.hiringReasonT')} arrow>
          <span className="lavenderBackground"> {story.companyInitiative}.</span>
        </HtmlTooltip> 
        </Typography>
        <Typography variant="body2" mt={2}>
        {t('storysection.qualification')}
        <HtmlTooltip title={t('storysection.qualificationT')} arrow>
          <span className="lavenderBackground"> {story.qualification}.</span>
        </HtmlTooltip> 
        {t('storysection.languages')} 
        <HtmlTooltip title={t('storysection.languagesT')} arrow>
          <span className="lavenderBackground"> {story.languages}.</span>
        </HtmlTooltip> 
        {t('storysection.skill3')} 
        <HtmlTooltip title={t('storysection.skill3T')} arrow>
          <span className="lavenderBackground"> {story.skill3}.</span>
        </HtmlTooltip>
        {t('storysection.references')} 
        <HtmlTooltip title={t('storysection.referencesT')} arrow>
          <span className="lavenderBackground"> {story.references}.</span>
        </HtmlTooltip>
        {t('storysection.workExperiences')}
        </Typography>
      </Grid>
      <Grid item xs={6}>
      <Typography variant='h6' align='left' mt={4}>{t('storysection.paragraph4H')}</Typography>
      <Typography variant="body2" mt={2}>   
            {t('storysection.paragraph4', {
            companyName: story.companyName,
            companyProblem: story.companyProblem,
            companyThreat: story.companyThreat,
            hiringReason: story.hiringReason,
            companyInitiative: story.companyInitiative,
          } )} 
        </Typography>
        <Typography variant="body2" mt={2}>
          {t('storysection.paragraph5', {
            qualification: story.qualification,
            languages: story.languages,
            skill3: story.skill3,
            references: story.references,
            companyInitiative: story.companyInitiative,
            workExperiences: story.workExperiences,
          } )} 
           </Typography>
      </Grid>
    </Grid>
  )}
  
{story.desire  && story.companyOffering && story.companyNotOffering && story.start && story.learn && story.change && (
    <Grid container spacing={2}>
      <Grid item xs={6}>
      <Typography variant='h6' align='left' mt={4}>{t('storysection.paragraph6H')}</Typography>
        <Typography variant="body2" mt={2}>
          {t('storysection.desire')} 
          <HtmlTooltip title={t('storysection.desireT')} arrow>
          <span className="lavenderBackground"> {story.desire}.</span>
          </HtmlTooltip>
          {t('storysection.desire1')}. 
         {t('storysection.sustainable')} 
         <HtmlTooltip title={t('storysection.sustainableT')} arrow>
          <span className="lavenderBackground"> {story.sustainable}.</span>
          </HtmlTooltip>
         {t('storysection.companyOffering')}
         <HtmlTooltip title={t('storysection.companyOfferingT')} arrow>
          <span className="lavenderBackground">{story.companyOffering}.</span>
          </HtmlTooltip>
          {t('storysection.companyNotOffering')} 
          <HtmlTooltip title={t('storysection.companyNotOfferingT')} arrow>
          <span className="lavenderBackground">{story.companyNotOffering}</span>
          </HtmlTooltip>
           {t('storysection.companyNotOffering2')}
          {t('storysection.conclusion1')} {story.companyName} {t('storysection.conclusion2')} {story.companyProblem} 
         {t('storysection.conclusion3')} {story.jobTitle}.
       
        </Typography>
        <Typography variant="body2" mt={2}>
        {t('storysection.start')} 
        <HtmlTooltip title={t('storysection.startT')} arrow>
          <span className="lavenderBackground">{story.start}. </span>
          </HtmlTooltip>
        {t('storysection.learn')} 
        <HtmlTooltip title={t('storysection.learnT')} arrow>
          <span className="lavenderBackground">{story.learn}. </span>
          </HtmlTooltip>
        {t('storysection.change')}
        <HtmlTooltip title={t('storysection.changeT')} arrow>
          <span className="lavenderBackground">{story.change} </span>
          </HtmlTooltip>
        </Typography>
        <Typography variant='h6' align='left' mt={4}>{t('storysection.wrapUpH')}</Typography>
        <Typography variant="body2" mt={2}>
        {t('storysection.questions')} 
        <HtmlTooltip title={t('storysection.questionsT')} arrow>
          <span className="lavenderBackground">{story.questions}. </span>
          </HtmlTooltip>
        {t('storysection.questionMarks')} 
        <HtmlTooltip title={t('storysection.questionMarksT')} arrow>
          <span className="lavenderBackground">{story.questionMarks}. </span>
          </HtmlTooltip>
          {t('storysection.questionMarks1')} 
        <HtmlTooltip title={t('storysection.questionMarksExamplesT')} arrow>
          <span className="lavenderBackground">{story.questionMarksExamples}. </span>
          </HtmlTooltip>
        {t('storysection.questionMarksExamples')}
        </Typography>
        
      </Grid>
      <Grid item xs={6}>
      <Typography variant='h6' align='left' mt={4}>{t('storysection.paragraph6H')}</Typography>
      <Typography variant="body2" mt={2}>   
            {t('storysection.paragraph6', {
            desire: story.desire,
            sustainable: story.sustainable,
            companyOffering: story.companyOffering,
            companyNotOffering: story.companyNotOffering,
            companyInitiative: story.companyInitiative,
            companyName: story.companyName,
            companyProblem: story.companyProblem,
            jobTitle: story.jobTitle,
          } )} 
        </Typography>
        <Typography variant="body2" mt={2}>
          {t('storysection.preClimaxParagraph', {
            start: story.start,
            learn: story.learn,
            change: story.change,
          } )} 
           </Typography>
           <Typography variant='h6' align='left' mt={4}>{t('storysection.wrapUpH')}</Typography>
           <Typography variant="body2" mt={2}>
           {t('storysection.wrapup', {
            questions: story.questions,
            questionMarks: story.questionMarks,
            questionMarksExamples: story.questionMarksExamples,
           }
           )}
           </Typography>   
           
      </Grid>
    </Grid>
  )}
    
      {/* Button to trigger print dialog */}
      <Grid container mt={2}> 
     <Grid item xs={4}></Grid> 
     <Grid item xs={4}>
      <Button variant="contained" color="primary" onClick={handlePrint}>
          {t('Download as PDF')}
        </Button>
      </Grid> 
      <Grid item xs={4}></Grid> 
      </Grid>
    </div>
  );
};

export default function StoryForm(props: { 
  step: number,
  
}) {
    const { t, i18n } = useTranslation();
    const [values, setValues] = useState({});
    const [isHovered1, setIsHovered1] = useState(false);
    const [isHovered2, setIsHovered2] = useState(false);
    const [isHovered3, setIsHovered3] = useState(false);
    const [isHovered4, setIsHovered4] = useState(false);
    const [isHovered5, setIsHovered5] = useState(false);
    const [isHovered6, setIsHovered6] = useState(false);
    const [isHovered7, setIsHovered7] = useState(false);
    const [isHovered8, setIsHovered8] = useState(false);
    const [isHovered9, setIsHovered9] = useState(false);
    const [isHovered10, setIsHovered10] = useState(false);
    const [isHovered11, setIsHovered11] = useState(false);
    const [isHovered12, setIsHovered12] = useState(false);
    const [isHovered13, setIsHovered13] = useState(false);
    const [isHovered14, setIsHovered14] = useState(false);
    const [isHovered15, setIsHovered15] = useState(false);
    const [isHovered16, setIsHovered16] = useState(false);
    const [isHovered17, setIsHovered17] = useState(false);
    const [isHovered18, setIsHovered18] = useState(false);
    const [isHovered19, setIsHovered19] = useState(false);
    const [isHovered20, setIsHovered20] = useState(false);
    const [isHovered21, setIsHovered21] = useState(false);
    const [isHovered22, setIsHovered22] = useState(false);
    const [isHovered23, setIsHovered23] = useState(false);
    const [isHovered24, setIsHovered24] = useState(false);
    const [isHovered25, setIsHovered25] = useState(false);
    const [isHovered26, setIsHovered26] = useState(false);
    const [isHovered27, setIsHovered27] = useState(false);
    const [isHovered28, setIsHovered28] = useState(false);
    const [isHovered29, setIsHovered29] = useState(false);
    const [isHovered30, setIsHovered30] = useState(false);
    const [isHovered31, setIsHovered31] = useState(false);
    const [isHovered32, setIsHovered32] = useState(false);
 
      const handleChange = (event) => {
        setValues({ 
          ...values,
          [event.target.name]: event.target.value,
        });
      };
      const [userStoryId, setUserStoryId] = useState(null);
      const [userStories, setUserStories] = useState([]);
      const [name, setName] = useState("");
      const [jobTitle, setJobTitle] = useState("");
      const [workExperience, setworkExperience] = useState("");
      const [treasure, setTreasure] = useState("");
      const [date, setDate] = useState("");
      const [media, setMedia] = useState("");
      const [specialty, setSpecialty] = useState("");
      const [degree, setDegree] = useState("");
      const [jobDeliverable, setJobDeliverable] = useState("");
      const [applicantChallenge, setApplicantChallenge] = useState("");
      const [applicantGoal, setApplicantGoal] = useState("");
      const [applicantSearch, setApplicantSearch] = useState("");
      const [companyName, setCompanyName] = useState("");
      const [companyProblem, setCompanyProblem] = useState("");
      const [companyThreat, setCompanyThreat] = useState("");
      const [hiringReason, setHiringReason] = useState("");
      const [companyInitiative, setCompanyInitiative] = useState("");
      const [city, setCity] = useState("");
      const [qualification, setQualification] = useState("");
      const [languages, setLanguages] = useState("");
      const [skill3, setSkill3] = useState("");
      const [references, setReferences] = useState("");
      const [workExperiences, setWorkExperiences] = useState("");
      const [desire, setDesire] = useState("");
      const [sustainable, setSustainable] = useState("");
      const [companyOffering, setCompanyOffering] = useState("");
      const [companyNotOffering, setCompanyNotOffering] = useState("");
      const [start, setStart] = useState("");
      const [learn, setLearn] = useState("");
      const [change, setChange] = useState("");
      const [questions, setQuestions] = useState("");
      const [questionMarks, setQuestionMarks] = useState("");
      const [questionMarksExamples, setQuestionMarksExamples] = useState("");
      const [submittedInfo, setSubmittedInfo] = useState(null); 
      
    
    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
            const userId = user.uid;
            const userStoryRef = collection(db, "users", userId, "stories");
            const sortedQuery = query(userStoryRef, orderBy("timestamp", "desc"));

            const unsubscribe = onSnapshot(sortedQuery, (querySnapshot) => {
                const stories = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setUserStories(stories);
                if (stories.length > 0) {
                    setUserStoryId(stories[0].id); // Set the existing document ID in state
                }
            });

            return () => unsubscribe();
        }
    }, [userStoryId]);

    useEffect(() => {
      // Listen for auth state changes (i.e., user logs in or out)
      const auth = getAuth();
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        // Clear form input fields and reset state when user logs out
        if (!user) {
          setName("");
          setJobTitle("");
          setworkExperience("");
          setTreasure("");
          setDate("");
          setMedia("");
          setSpecialty("");
          setDegree("");
          setJobDeliverable("");
          setApplicantChallenge("");
          setApplicantGoal("");
          setApplicantSearch("");
          setCompanyName("");
          setCompanyProblem("");
          setCompanyThreat("");
          setHiringReason("");
          setCompanyInitiative("");
          setCity("");
          setQualification("");
          setLanguages("");
          setSkill3("");
          setReferences("");
          setWorkExperiences("");
          setDesire("");
          setSustainable("");
          setCompanyOffering("");
          setCompanyNotOffering("");
          setStart("");
          setLearn("");
          setChange("");
          setQuestions("");
          setQuestionMarks("");
          setQuestionMarksExamples("");
          setUserStoryId(null);
          setUserStories([]);
        }
        else {
          // Fetch the latest story for the user and populate the input fields
      const userId = user.uid;
      const userStoryRef = collection(db, "users", userId, "stories");
      const sortedQuery = query(userStoryRef, orderBy("timestamp", "desc"), limit(1));
      const querySnapshot = await getDocs(sortedQuery);
      

      if (!querySnapshot.empty) {
        const latestStory = querySnapshot.docs[0].data();
        setName(latestStory.name || "");
        setJobTitle(latestStory.jobTitle || "");
        setworkExperience(latestStory.workExperience || "");
        setTreasure(latestStory.treasure || "");
        setDate(latestStory.date || "");
        setMedia(latestStory.media || "");
        setSpecialty(latestStory.specialty || "");
        setDegree(latestStory.degree || "");
        setJobDeliverable(latestStory.jobDeliverable || "");
        setApplicantChallenge(latestStory.applicantChallenge || "");
        setApplicantGoal(latestStory.applicantGoal || "");
        setApplicantSearch(latestStory.applicantSearch || "");
        setCompanyName(latestStory.companyName || "");
        setCompanyProblem(latestStory.companyProblem || "");
        setCompanyThreat(latestStory.companyThreat || "");
        setHiringReason(latestStory.hiringReason || "");
        setCompanyInitiative(latestStory.companyInitiative || "");
        setCity(latestStory.city || "");
        setQualification(latestStory.qualification || "");
        setLanguages(latestStory.languages || "");
        setSkill3(latestStory.skill3 || "");
        setReferences(latestStory.references || "");
        setWorkExperiences(latestStory.workExperiences || "");
        setDesire(latestStory.desire || "");
        setSustainable(latestStory.sustainable || "");
        setCompanyOffering(latestStory.companyOffering || "");
        setCompanyNotOffering(latestStory.companyNotOffering || "");
        setStart(latestStory.start || "");
        setLearn(latestStory.learn || "");
        setChange(latestStory.change || "");
        setQuestions(latestStory.questions || "");
        setQuestionMarks(latestStory.questionMarks || "");
        setQuestionMarksExamples(latestStory.questionMarksExamples || "");
        setUserStoryId(querySnapshot.docs[0].id);
      }
    }
      });
  
      return () => unsubscribe();
    }, []);

    useEffect(() => {
      const auth = getAuth();
      const user = auth.currentUser;
      
  
      if (user && jobTitle) {
        const userId = user.uid;
        const storyData = {
          timestamp: serverTimestamp(),
          name: name,
          jobTitle: jobTitle,
          workExperience: workExperience,
          treasure: treasure,
          date: date,
          media: media,
          specialty:specialty,
          degree:degree,
          jobDeliverable:jobDeliverable,
          applicantChallenge:applicantChallenge,
          applicantGoal:applicantGoal,
          applicantSearch:applicantSearch,
          companyName:companyName,
          companyProblem:companyProblem,
          companyThreat:companyThreat,
          hiringReason:hiringReason,
          companyInitiative:companyInitiative,
          city:city,
          qualification:qualification,
          languages:languages,
          skill3:skill3,
          references:references,
          workExperiences:workExperiences,
          desire:desire,
          sustainable:sustainable,
          companyOffering:companyOffering,
          companyNotOffering:companyNotOffering,
          start:start,
          learn:learn,
          change:change,
          questions:questions,
          questionMarks:questionMarks,
          questionMarksExamples:questionMarksExamples,
          
        };
        const userStoryRef = collection(db, "users", userId, "stories");
        
  
        if (userStoryId) {
          // Update the existing document
          const storyDocRef = doc(db, "users", userId, "stories", userStoryId);
          setDoc(storyDocRef, storyData);
        } else {
          // Add a new document
          addDoc(userStoryRef, storyData).then((docRef) => {
            setUserStoryId(docRef.id); // Set the newly generated document ID in state
          });
        }
      }
    }, [name, jobTitle, workExperience, treasure, date, media, specialty, degree, jobDeliverable,
      applicantChallenge, applicantGoal, applicantSearch, companyName,companyProblem,companyThreat, 
      hiringReason, companyInitiative, city, qualification, languages, skill3, references, workExperiences, 
      desire, sustainable, companyOffering, companyNotOffering, start, learn,change , questions, questionMarks,
       questionMarksExamples, userStoryId]);

      

  return (
    <>
      <Paper sx={{
        ...paperInputsStyle,
        margin: { xs: 1, sm: 2 },
      }}
      >
        <form>
          <FormGroup sx={{ display: props.step === 0 ? "" : "none" }}>
       
      <Grid container spacing={1}>
        {/*}Section*/}
        <Grid item xs={1}>
          <Typography variant='h4' align='left' mt={2} >{t('stepper.audio')}</Typography> 
        </Grid>
        <Grid item xs={5}>
          <Typography variant='h4' align='left' mt={2} >{t('stepper.input')}</Typography> 
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h4' align='left' mt={2} >{t('stepper.inputCheck')}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant='h4' align='left' mt={2} >{t('stepper.example')}</Typography> 
        </Grid>
        {/*} Section */}
        <Grid item xs={1}>
          <AudioButton filePath={`/${t('storyform.name')}.mp3`} />
        </Grid>
        <Grid item xs={5}>
         
            <TextField 
                   id="name"
                   multiline
                   name="name"
                   margin="normal"
                   required
                   fullWidth
                   label={t('storyform.qname')} 
                   variant="outlined" 
                   autoComplete="name"
                   autoFocus={true}
                   helperText={
                    <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                  }
                   value={name}
                   placeholder={name === '' ? t('storyform.placeholderForm') : ''}
                   onChange={(e) => setName(e.target.value)}
                   InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <HtmlTooltip title={t('stepper.iconText')} arrow>
                        <span className="lavenderBackground"> <AbcIcon /> </span>
                        </HtmlTooltip>  
                      </InputAdornment>
                    ),
                  }}
            />
            
          
        </Grid>
        <Grid item xs={3}>
        {name ? (
          <>
          <Typography variant='body2' align='left' mt={2}>{t('storysection.name')}
            <HtmlTooltip title={t('storysection.nameT')} arrow>
                <span className="lavenderBackground"> {name}</span>
            </HtmlTooltip>
          </Typography>
          <hr></hr>
          <Typography variant='body2' mt={1} align='left'> 
          
          ☝️{t('stepper.inputCheckH')} 
          </Typography>
        </>
        ) : (
          <>
          <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
        </>
        )}

        </Grid>
        <Grid item xs={3}>
        <div>
          
          <div
            onMouseEnter={() => setIsHovered1(true)}
            onMouseLeave={() => setIsHovered1(false)}
          >
          {!isHovered1 && <AdsClickIcon />}
          {!isHovered1 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}
          {isHovered1 && (
          <TextField 
                    margin="normal" 
                    id="filled-disabled"
                    fullWidth
                    variant="filled" 
                    value={t('example.name')} 
                    label={t('example.nameL')}  
                    helperText={t('example.nameS')} 
            />    
            )}
          </div>
        </div>
          
        </Grid>  
         {/*} Section */}
         <Grid item xs={1}>
           <AudioButton filePath={`/${t('storyform.workExperience')}.mp3`} />
        </Grid>
         <Grid item xs={5}>
        
        <TextField 
                 margin="normal"
                 required
                 fullWidth 
                 type="text"
                 name="workExperience"
                 value={workExperience}
                 placeholder={workExperience === '' ? t('storyform.placeholderForm') : ''}
                 label={t('storyform.qworkexperience')}  
                 variant="outlined" 
                 helperText={
                  <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                }
                 onChange={(e) => setworkExperience(e.target.value)} 
                 InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <HtmlTooltip title={t('stepper.iconNumbers')} arrow>
                        <span className="lavenderBackground"> <PinIcon /> </span>
                        </HtmlTooltip> 
                    </InputAdornment>
                  ),
                }}
          />
      </Grid>
      <Grid item xs={3}>
      {workExperience ? (
        <>
        <Typography variant='body2' align='left' mt={2}>{t('storysection.workexperience')}
          <HtmlTooltip title={t('storysection.workexperienceT')} arrow>
              <span className="lavenderBackground"> {workExperience}</span>
          </HtmlTooltip> {t('storysection.workexperience1')}...
        </Typography>
        <hr></hr>
        <Typography variant='body2' mt={1} align='left'> 
        
        ☝️{t('stepper.inputCheckH')} 
        </Typography>
      </>
      ) : (
        <>
        <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
      </>
      )}

      </Grid>
      <Grid item xs={3}>
      <div>
          <div
          onMouseEnter={() => setIsHovered2(true)}
          onMouseLeave={() => setIsHovered2(false)}
        >
          {!isHovered2 && <AdsClickIcon />}
          {!isHovered2 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered2 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.workExperience')} 
            label={t('example.workExperienceL')}  
            helperText={t('example.workExperienceS')} 
            />    
            )}
            </div>
        </div>
      </Grid> 
      {/*} Section */}
      <Grid item xs={1}>
        <AudioButton filePath={`/${t('storyform.date')}.mp3`} />
      </Grid>
      <Grid item xs={5}>
      <TextField 
                    id="date"
                    value={date} 
                    placeholder={date === '' ? t('storyform.placeholderForm') : ''}
                    name="date"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qdate')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setDate(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconDate')} arrow>
                        <span className="lavenderBackground"> <CalendarMonthIcon /> </span>
                        </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
      </Grid>
      <Grid item xs={3}>
      {date ? (
        <>
        <Typography variant='body2' align='left' mt={2}>{t('example.dateL')}
          <HtmlTooltip title={t('storysection.dateT')} arrow>
              <span className="lavenderBackground"> {date}</span>
          </HtmlTooltip>
        </Typography>
        <hr></hr>
        <Typography variant='body2' mt={1} align='left'> 
        
        ☝️{t('stepper.inputCheckH')} 
        </Typography>
      </>
      ) : (
        <>
        <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
      </>
      )}

      </Grid>
      <Grid item xs={3}>
      <div>
          <div
          onMouseEnter={() => setIsHovered3(true)}
          onMouseLeave={() => setIsHovered3(false)}
        >
          {!isHovered3 && <AdsClickIcon />}
          {!isHovered3 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered3 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.date')} 
            label={t('example.dateL')}  
            helperText={t('example.dateS')} 
            />    
            )}
            </div>
        </div>
      </Grid> 
      {/*} Section */}
      <Grid item xs={1}>
        <AudioButton filePath={`/${t('storyform.media')}.mp3`} />
      </Grid>
      <Grid item xs={5}>
        
      <TextField 
                     id="media"
                     value={media} 
                     placeholder={media === '' ? t('storyform.placeholderForm') : ''}
                     name="media"
                     margin="normal"
                     required
                     fullWidth
                     label={t('storyform.qmedia')} 
                     variant="outlined" 
                     helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                     onChange={(e) => setMedia(e.target.value)}
                     InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
      </Grid>
      <Grid item xs={3}>
      {media ? (
        <>
        <Typography variant='body2' align='left' mt={2}>{t('example.mediaL')}
          <HtmlTooltip title={t('storysection.mediaT')} arrow>
              <span className="lavenderBackground"> {media}</span>
          </HtmlTooltip>
        </Typography>
        <hr></hr>
        <Typography variant='body2' mt={1} align='left'> 
        
        ☝️{t('stepper.inputCheckH')} 
        </Typography>
      </>
      ) : (
        <>
        <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
      </>
      )}

      </Grid>
      <Grid item xs={3}>
      <div>
          <div
          onMouseEnter={() => setIsHovered4(true)}
          onMouseLeave={() => setIsHovered4(false)}
        >
          {!isHovered4 && <AdsClickIcon />}
          {!isHovered4 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered4 && (
            
          <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.media')} 
            label={t('example.mediaL')}  
            helperText={t('example.mediaS')} 
            />    
            )}
            </div>
        </div>
      </Grid> 
       {/*} Section */}
       <Grid item xs={1}>
        <AudioButton filePath={`/${t('storyform.jobtitle')}.mp3`} />
      </Grid>
      <Grid item xs={5}>
        <TextField 
                       margin="normal"
                       value={jobTitle}
                       placeholder={jobTitle === '' ? t('storyform.placeholderForm') : ''}
                       multiline
                       required
                       fullWidth 
                       type="text"
                       name="jobTitle"
                       label={t('storyform.qjobtitle')} 
                       variant="outlined" 
                       helperText={
                        <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                      }
                       onChange={(e) => setJobTitle(e.target.value)} 
                       InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <HtmlTooltip title={t('stepper.iconText')} arrow>
                            <span className="lavenderBackground"> <AbcIcon /> </span>
                           </HtmlTooltip> 
                          </InputAdornment>
                        ),
                      }}
                  />  
        </Grid>
        <Grid item xs={3}>
        {media ? (
          <>
          <Typography variant='body2' align='left' mt={2}>{t('example.jobtitleL')}
            <HtmlTooltip title={t('storysection.jobtitleT')} arrow>
              <span className="lavenderBackground"> {jobTitle}</span>
            </HtmlTooltip>
        </Typography>
          <hr></hr>
          <Typography variant='body2' mt={1} align='left'> 
          
          ☝️{t('stepper.inputCheckH')} 
          </Typography>
        </>
        ) : (
          <>
          <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
        </>
        )}
  
        </Grid>
        <Grid item xs={3}>
        <div>
          <div
          onMouseEnter={() => setIsHovered5(true)}
          onMouseLeave={() => setIsHovered5(false)}
        >
          {!isHovered5 && <AdsClickIcon />}
          {!isHovered5 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered5 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.jobtitle')} 
            label={t('example.jobtitleL')}  
            helperText={t('example.jobtitleS')} 
            />    
            )}
            </div>
          </div>
        </Grid> 
          {/*} Section */}
          <Grid item xs={1}>
            <AudioButton filePath={`/${t('storyform.workExperiences')}.mp3`} />
          </Grid>
          <Grid item xs={5}>
           <TextField 
                    id="workExperiences"
                    value={workExperiences} 
                    placeholder={workExperiences === '' ? t('storyform.placeholderForm') : ''}
                    name="workExperiences"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qworkExperiences')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setWorkExperiences(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
        </Grid>
        <Grid item xs={3}>
        {workExperiences ? (
          <>
          <Typography variant='body2' align='left' mt={2}>{t('example.workExperiencesL')}
            <HtmlTooltip title={t('storysection.workExperiencesT')} arrow>
              <span className="lavenderBackground"> {workExperiences}</span>
            </HtmlTooltip>
        </Typography>
          <hr></hr>
          <Typography variant='body2' mt={1} align='left'> 
          
          ☝️{t('stepper.inputCheckH')} 
          </Typography>
        </>
        ) : (
          <>
          <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
        </>
        )}
  
        </Grid>
        <Grid item xs={3}>
        <div>
          <div
          onMouseEnter={() => setIsHovered6(true)}
          onMouseLeave={() => setIsHovered6(false)}
        >
          {!isHovered6 && <AdsClickIcon />}
          {!isHovered6 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered6 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.workExperiences')} 
            label={t('example.workExperiencesL')}  
            helperText={t('example.workExperiencesS')} 
            />    
            )}
            </div>
          </div>
        </Grid> 
           {/*} Section */}
           <Grid item xs={1}>
        <AudioButton filePath={`/${t('storyform.specialty')}.mp3`} />
        </Grid>
        <Grid item xs={5}>
          
          <TextField 
                    id="specialty"
                    value={specialty} 
                    placeholder={specialty === '' ? t('storyform.placeholderForm') : ''}
                    name="specialty"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qspecialty')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setSpecialty(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />   
         </Grid>
         <Grid item xs={3}>
         {specialty ? (
           <>
           <Typography variant='body2' align='left' mt={2}>{t('example.specialtyL')}
             <HtmlTooltip title={t('storysection.specialtyT')} arrow>
               <span className="lavenderBackground"> {specialty}</span>
             </HtmlTooltip>
         </Typography>
           <hr></hr>
           <Typography variant='body2' mt={1} align='left'> 
           
           ☝️{t('stepper.inputCheckH')} 
           </Typography>
         </>
         ) : (
           <>
           <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
         </>
         )}
   
         </Grid>
         <Grid item xs={3}>
         <div>
          <div
          onMouseEnter={() => setIsHovered7(true)}
          onMouseLeave={() => setIsHovered7(false)}
        >
          {!isHovered7 && <AdsClickIcon />}
          {!isHovered7 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered7 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.specialty')} 
            label={t('example.specialtyL')}  
            helperText={t('example.specialtyS')} 
            />    
            )}
            </div>
           </div>
         </Grid> 
             {/*} Section */}
             <Grid item xs={1}>
              <AudioButton filePath={`/${t('storyform.degree')}.mp3`} />
            </Grid>
            <Grid item xs={5}>
              
              <TextField 
                    id="degree"
                    value={degree} 
                    placeholder={degree === '' ? t('storyform.placeholderForm') : ''}
                    name="degree"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qdegree')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setDegree(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />    
         </Grid>
         <Grid item xs={3}>
         {degree ? (
           <>
           <Typography variant='body2' align='left' mt={2}>{t('example.degreeL')}
             <HtmlTooltip title={t('storysection.degreeT')} arrow>
               <span className="lavenderBackground"> {degree}</span>
             </HtmlTooltip>
         </Typography>
           <hr></hr>
           <Typography variant='body2' mt={1} align='left'> 
           
           ☝️{t('stepper.inputCheckH')} 
           </Typography>
         </>
         ) : (
           <>
           <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
         </>
         )}
   
         </Grid>
         <Grid item xs={3}>
         <div>
          <div
          onMouseEnter={() => setIsHovered8(true)}
          onMouseLeave={() => setIsHovered8(false)}
        >
          {!isHovered8 && <AdsClickIcon />}
          {!isHovered8 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered8 && (
            
           <TextField 
           margin="normal" 
           id="filled-disabled"
           fullWidth
           variant="filled" 
           value={t('example.degree')} 
           label={t('example.degreeL')}  
           helperText={t('example.degreeS')} 
           />    
           )}
           </div>
           </div>
         </Grid> 
          {/*} Section */}
          <Grid item xs={1}>
              <AudioButton filePath={`/${t('storyform.treasure')}.mp3`} />
          </Grid>
            <Grid item xs={5}>
            <TextField 
                    margin="normal"
                    value={treasure}
                    placeholder={treasure === '' ? t('storyform.placeholderForm') : ''}
                    multiline
                    required
                    fullWidth 
                    type="text"
                    name="treasure"
                    label={t('storyform.qtreasure')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setTreasure(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      )  
                    }}
                />   
         </Grid>
         <Grid item xs={3}>
         {treasure ? (
           <>
           <Typography variant='body2' align='left' mt={2}>{t('example.treasureL')}
             <HtmlTooltip title={t('storysection.treasureT')} arrow>
               <span className="lavenderBackground"> {treasure}</span>
             </HtmlTooltip>
         </Typography>
           <hr></hr>
           <Typography variant='body2' mt={1} align='left'> 
           
           ☝️{t('stepper.inputCheckH')} 
           </Typography>
         </>
         ) : (
           <>
           <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
         </>
         )}
   
         </Grid>
         <Grid item xs={3}>
         <div>
          <div
          onMouseEnter={() => setIsHovered9(true)}
          onMouseLeave={() => setIsHovered9(false)}
        >
          {!isHovered9 && <AdsClickIcon />}
          {!isHovered9 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered9 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.treasure')} 
            label={t('example.treasureL')}  
            helperText={t('example.treasureS')} 
            />    
            )}
            </div>
           </div>
         </Grid> 
          {/*} Section */}
          <Grid item xs={1}>
              <AudioButton filePath={`/${t('storyform.jobDeliverable')}.mp3`} />
            </Grid>
            <Grid item xs={5}>
        
          <TextField 
                    id="jobDeliverable"
                    value={jobDeliverable} 
                    placeholder={jobDeliverable === '' ? t('storyform.placeholderForm') : ''}
                    name="jobDeliverable"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qjobDeliverable')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setJobDeliverable(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
         </Grid>
         <Grid item xs={3}>
         {jobDeliverable ? (
           <>
           <Typography variant='body2' align='left' mt={2}>{t('example.jobDeliverableL')}
             <HtmlTooltip title={t('storysection.jobDeliverableT')} arrow>
               <span className="lavenderBackground"> {jobDeliverable}</span>
             </HtmlTooltip>
         </Typography>
           <hr></hr>
           <Typography variant='body2' mt={1} align='left'> 
           
           ☝️{t('stepper.inputCheckH')} 
           </Typography>
         </>
         ) : (
           <>
           <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
         </>
         )}
   
         </Grid>
         <Grid item xs={3}>
         <div>
          <div
          onMouseEnter={() => setIsHovered10(true)}
          onMouseLeave={() => setIsHovered10(false)}
        >
          {!isHovered10 && <AdsClickIcon />}
          {!isHovered10 && (
            <Typography variant="h3" >
              {t('stepper.exampleHover')}
            </Typography>
          )}

          {isHovered10 && (
            
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.jobDeliverable')} 
            label={t('example.jobDeliverableL')}  
            helperText={t('example.jobDeliverableS')} 
            />    
            )}
            </div>
           </div>
         </Grid> 
         {/*} container ends */}
         </Grid>     
        </FormGroup> 
          <FormGroup sx={{ display: props.step === 1 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
             {/*} Section */}
             <Grid item xs={1}>
              <AudioButton filePath={`/${t('storyform.applicantChallenge')}.mp3`} />
            </Grid>
              <Grid item xs={5}>
              
                <TextField 
                          id="applicantChallenge"
                          value={applicantChallenge} 
                          placeholder={applicantChallenge === '' ? t('storyform.placeholderForm') : ''}
                          name="applicantChallenge"
                          multiline
                          margin="normal"
                          required
                          fullWidth
                          label={t('storyform.qapplicantChallenge')} 
                          variant="outlined" 
                          autoFocus={true}
                          helperText={
                            <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                          }
                          onChange={(e) => setApplicantChallenge(e.target.value)}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <HtmlTooltip title={t('stepper.iconList')} arrow>
                                <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                               </HtmlTooltip> 
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <HtmlTooltip title={t('stepper.iconThink')} arrow>
                                  <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                                </HtmlTooltip> 
                              </InputAdornment>
                            ) 
                          }}
                    />  
              </Grid>
              <Grid item xs={3}>
              {applicantChallenge ? (
                <>
                <Typography variant='body2' align='left' mt={2}>{t('example.applicantChallengeL')}
                  <HtmlTooltip title={t('storysection.applicantChallengeT')} arrow>
                    <span className="lavenderBackground"> {applicantChallenge}</span>
                  </HtmlTooltip>
              </Typography>
                <hr></hr>
                <Typography variant='body2' mt={1} align='left'> 
                
                ☝️{t('stepper.inputCheckH')} 
                </Typography>
              </>
              ) : (
                <>
                <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
              </>
              )}
        
              </Grid>
              <Grid item xs={3}>
              <div>
                <div
                  onMouseEnter={() => setIsHovered11(true)}
                  onMouseLeave={() => setIsHovered11(false)}
                >
                  {!isHovered11 && <AdsClickIcon />}
                  {!isHovered11 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered11 && (
                  
                  <TextField 
                  margin="normal" 
                  id="filled-disabled"
                  fullWidth
                  variant="filled" 
                  value={t('example.applicantChallenge')} 
                  label={t('example.applicantChallengeL')}  
                  helperText={t('example.applicantChallengeS')} 
                  />    
                  )}  
                </div>
              </div>
              </Grid> 
                {/*} Section */}
                <Grid item xs={1}>
              <AudioButton filePath={`/${t('storyform.applicantGoal')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
                  <TextField 
                    id="applicantGoal"
                    value={applicantGoal} 
                    placeholder={applicantGoal === '' ? t('storyform.placeholderForm') : ''}
                    multiline
                    name="applicantGoal"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qapplicantGoal')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setApplicantGoal(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                            <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                            <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
              />  
            </Grid>
            <Grid item xs={3}>
            {applicantGoal ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.applicantGoalL')}
                <HtmlTooltip title={t('storysection.applicantGoalT')} arrow>
                  <span className="lavenderBackground"> {applicantGoal}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered12(true)}
                  onMouseLeave={() => setIsHovered12(false)}
                >
                  {!isHovered12 && <AdsClickIcon />}
                  {!isHovered12 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered12 && (
              <TextField 
                        margin="normal" 
                        id="filled-disabled"
                        fullWidth
                        variant="filled" 
                        value={t('example.applicantGoal')} 
                        label={t('example.applicantGoalL')}  
                        helperText={t('example.applicantGoalS')} 
                        />    
                )}  
                </div>
              </div>
            </Grid> 
              {/*} Section */}
              <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.applicantSearch')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
                <TextField 
                    id="applicantSearch"
                    value={applicantSearch} 
                    placeholder={applicantSearch === '' ? t('storyform.placeholderForm') : ''}
                    name="applicantSearch"
                    margin="normal"
                    required
                    fullWidth
                    multiline
                    label={t('storyform.qapplicantSearch')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setApplicantSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                            <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
            </Grid>
            <Grid item xs={3}>
            {applicantSearch ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.applicantSearchL')}
                <HtmlTooltip title={t('storysection.applicantSearchT')} arrow>
                  <span className="lavenderBackground"> {applicantSearch}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered13(true)}
                  onMouseLeave={() => setIsHovered13(false)}
                >
                  {!isHovered13 && <AdsClickIcon />}
                  {!isHovered13 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered13 && (

              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.applicantSearch')} 
              label={t('example.applicantSearchL')}  
              helperText={t('example.applicantSearchS')} 
              />    
                
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */}     
          </Grid>
          </FormGroup>
          <FormGroup sx={{ display: props.step === 2 ? "" : "none", m: 2 }}>
           <Grid container spacing={2}>
            {/*} Section */}
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.companyName')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              <TextField 
                    id="companyName"
                    value={companyName} 
                    placeholder={companyName === '' ? t('storyform.placeholderForm') : ''}
                    name="companyName"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qcompanyName')} 
                    variant="outlined" 
                    autoFocus={true}
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyName(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {companyName ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyNameL')}
              <HtmlTooltip title={t('storysection.companyNameT')} arrow>
                <span className="lavenderBackground"> {companyName}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered14(true)}
                  onMouseLeave={() => setIsHovered14(false)}
                >
                  {!isHovered14 && <AdsClickIcon />}
                  {!isHovered14 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered14 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyName')} 
            label={t('example.companyNameL')}  
            helperText={t('example.companyNameS')} 
            />    
              )}  
            </div>
          </div>
          </Grid> 
            {/*} Section */}  
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.companyProblem')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              <TextField 
                    id="companyProblem"
                    value={companyProblem} 
                    placeholder={companyProblem === '' ? t('storyform.placeholderForm') : ''}
                    name="companyProblem"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qcompanyProblem')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyProblem(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {companyProblem ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyProblemL')}
              <HtmlTooltip title={t('storysection.companyProblemT')} arrow>
                <span className="lavenderBackground"> {companyProblem}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered15(true)}
                  onMouseLeave={() => setIsHovered15(false)}
                >
                  {!isHovered15 && <AdsClickIcon />}
                  {!isHovered15 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered15 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyProblem')} 
            label={t('example.companyProblemL')}  
            helperText={t('example.companyProblemS')} 
            />    
            )}  
          </div>
        </div>
          </Grid> 
          {/*} Section */}  
          <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.companyThreat')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              <TextField 
                    id="companyThreat"
                    value={companyThreat} 
                    placeholder={companyThreat === '' ? t('storyform.placeholderForm') : ''}
                    name="companyThreat"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qcompanyThreat')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyThreat(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {companyThreat ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyThreatL')}
              <HtmlTooltip title={t('storysection.companyThreatT')} arrow>
                <span className="lavenderBackground"> {companyThreat}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered15(true)}
                  onMouseLeave={() => setIsHovered15(false)}
                >
                  {!isHovered15 && <AdsClickIcon />}
                  {!isHovered15 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered15 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyThreat')} 
            label={t('example.companyThreatL')}  
            helperText={t('example.companyThreatS')} 
            />    
            )}  
          </div>
        </div>
          </Grid> 
            {/*} Section */}   
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.hiringReason')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              <TextField 
                    id="hiringReason"
                    value={hiringReason} 
                    placeholder={hiringReason === '' ? t('storyform.placeholderForm') : ''}
                    name="hiringReason"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qhiringReason')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setHiringReason(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {hiringReason ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.hiringReasonL')}
              <HtmlTooltip title={t('storysection.hiringReasonT')} arrow>
                <span className="lavenderBackground"> {hiringReason}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered16(true)}
                  onMouseLeave={() => setIsHovered16(false)}
                >
                  {!isHovered16 && <AdsClickIcon />}
                  {!isHovered16 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered16 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.hiringReason')} 
            label={t('example.hiringReasonL')}  
            helperText={t('example.hiringReasonS')} 
            />    
            )}  
          </div>
        </div>
          </Grid> 
            {/*} Section */} 
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.companyInitiative')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              <TextField 
                    id="companyInitiative"
                    value={companyInitiative} 
                    placeholder={companyInitiative === '' ? t('storyform.placeholderForm') : ''}
                    name="companyInitiative"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qcompanyInitiative')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyInitiative(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {companyInitiative ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyInitiativeL')}
              <HtmlTooltip title={t('storysection.companyInitiativeT')} arrow>
                <span className="lavenderBackground"> {companyInitiative}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered17(true)}
                  onMouseLeave={() => setIsHovered17(false)}
                >
                  {!isHovered17 && <AdsClickIcon />}
                  {!isHovered17 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered17 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyInitiative')} 
            label={t('example.companyInitiativeL')}  
            helperText={t('example.companyInitiativeS')} 
            />    
                )}  
              </div>
            </div>
          </Grid> 
            {/*} Section */}
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.city')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              
            <TextField 
                    id="city"
                    value={city} 
                    placeholder={city === '' ? t('storyform.placeholderForm') : ''}
                    name="city"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qcity')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCity(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconText')} arrow>
                          <span className="lavenderBackground"> <AbcIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
          </Grid>
          <Grid item xs={3}>
          {city ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.cityL')}
              <HtmlTooltip title={t('storysection.cityT')} arrow>
                <span className="lavenderBackground"> {city}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered18(true)}
                  onMouseLeave={() => setIsHovered18(false)}
                >
                  {!isHovered18 && <AdsClickIcon />}
                  {!isHovered18 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered18 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.city')} 
            label={t('example.cityL')}  
            helperText={t('example.cityS')} 
            />    
                )}  
              </div>
            </div>
          </Grid> 
            {/*} Section */}
            <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.qualification')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              
            <TextField 
                    id="qualification"
                    value={qualification} 
                    placeholder={qualification === '' ? t('storyform.placeholderForm') : ''}
                    name="qualification"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qqualification')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setQualification(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />  
            </Grid>
            <Grid item xs={3}>
            {qualification ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.qualificationL')}
                <HtmlTooltip title={t('storysection.qualificationT')} arrow>
                  <span className="lavenderBackground"> {qualification}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered19(true)}
                  onMouseLeave={() => setIsHovered19(false)}
                >
                  {!isHovered19 && <AdsClickIcon />}
                  {!isHovered19 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered19 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.qualification')} 
              label={t('example.qualificationL')}  
              helperText={t('example.qualificationS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */}  
              <Grid item xs={1}>
               <AudioButton filePath={`/${t('storyform.languages')}.mp3`} />
                </Grid>
              <Grid item xs={5}>
              
              <TextField 
                    id="languages"
                    value={languages} 
                    placeholder={languages === '' ? t('storyform.placeholderForm') : ''}
                    name="languages"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qlanguages')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setLanguages(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                />
              </Grid>
              <Grid item xs={3}>
              {languages ? (
                <>
                <Typography variant='body2' align='left' mt={2}>{t('example.languagesL')}
                  <HtmlTooltip title={t('storysection.languagesT')} arrow>
                    <span className="lavenderBackground"> {languages}</span>
                  </HtmlTooltip>
              </Typography>
                <hr></hr>
                <Typography variant='body2' mt={1} align='left'> 
                
                ☝️{t('stepper.inputCheckH')} 
                </Typography>
              </>
              ) : (
                <>
                <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
              </>
              )}
        
              </Grid>
              <Grid item xs={3}>
              <div>
                <div
                  onMouseEnter={() => setIsHovered20(true)}
                  onMouseLeave={() => setIsHovered20(false)}
                >
                  {!isHovered20 && <AdsClickIcon />}
                  {!isHovered20 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered20 && (
                  
                <TextField 
                margin="normal" 
                id="filled-disabled"
                fullWidth
                variant="filled" 
                value={t('example.languages')} 
                label={t('example.languagesL')}  
                helperText={t('example.languagesS')} 
                />    
                    )}  
                  </div>
                </div>
              </Grid> 
                {/*} Section */} 
                <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.skill3')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
                  <TextField 
                    id="skill3"
                    value={skill3} 
                    placeholder={skill3 === '' ? t('storyform.placeholderForm') : ''}
                    name="skill3"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qskill3')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setSkill3(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                  /> 
              </Grid>
              <Grid item xs={3}>
              {skill3 ? (
                <>
                <Typography variant='body2' align='left' mt={2}>{t('example.skill3L')}
                  <HtmlTooltip title={t('storysection.skill3T')} arrow>
                    <span className="lavenderBackground"> {skill3}</span>
                  </HtmlTooltip>
              </Typography>
                <hr></hr>
                <Typography variant='body2' mt={1} align='left'> 
                
                ☝️{t('stepper.inputCheckH')} 
                </Typography>
              </>
              ) : (
                <>
                <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
              </>
              )}
        
              </Grid>
              <Grid item xs={3}>
              <div>
                <div
                  onMouseEnter={() => setIsHovered21(true)}
                  onMouseLeave={() => setIsHovered21(false)}
                >
                  {!isHovered21 && <AdsClickIcon />}
                  {!isHovered21 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered21 && (
                  
                <TextField 
                margin="normal" 
                id="filled-disabled"
                fullWidth
                variant="filled" 
                value={t('example.skill3')} 
                label={t('example.skill3L')}  
                helperText={t('example.skill3S')} 
                />    
                  )}  
                </div>
              </div>
              </Grid> 
                {/*} Section */} 
                <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.references')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
                  <TextField 
                    id="references"
                    value={references} 
                    placeholder={references === '' ? t('storyform.placeholderForm') : ''}
                    multiline
                    name="references"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qreferences')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setReferences(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
              </Grid>
              <Grid item xs={3}>
              {references ? (
                <>
                <Typography variant='body2' align='left' mt={2}>{t('example.referencesL')}
                  <HtmlTooltip title={t('storysection.referencesT')} arrow>
                    <span className="lavenderBackground"> {references}</span>
                  </HtmlTooltip>
              </Typography>
                <hr></hr>
                <Typography variant='body2' mt={1} align='left'> 
                
                ☝️{t('stepper.inputCheckH')} 
                </Typography>
              </>
              ) : (
                <>
                <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
              </>
              )}
        
              </Grid>
              <Grid item xs={3}>
              <div>
                <div
                  onMouseEnter={() => setIsHovered22(true)}
                  onMouseLeave={() => setIsHovered22(false)}
                >
                  {!isHovered22 && <AdsClickIcon />}
                  {!isHovered22 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered22 && (
                  
                <TextField 
                margin="normal" 
                id="filled-disabled"
                fullWidth
                variant="filled" 
                value={t('example.references')} 
                label={t('example.referencesL')}  
                helperText={t('example.referencesS')} 
                />    
                  )}  
                </div>
              </div>
              </Grid> 
                {/*} Section */} 
                <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.desire')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
                  <TextField 
                    id="desire"
                    value={desire} 
                    placeholder={desire === '' ? t('storyform.placeholderForm') : ''}
                    name="desire"
                    margin="normal"
                    multiline
                    required
                    fullWidth
                    label={t('storyform.qdesire')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setDesire(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                /> 
              </Grid>
              <Grid item xs={3}>
              {desire ? (
                <>
                <Typography variant='body2' align='left' mt={2}>{t('example.desireL')}
                  <HtmlTooltip title={t('storysection.desireT')} arrow>
                    <span className="lavenderBackground"> {desire}</span>
                  </HtmlTooltip>
              </Typography>
                <hr></hr>
                <Typography variant='body2' mt={1} align='left'> 
                
                ☝️{t('stepper.inputCheckH')} 
                </Typography>
              </>
              ) : (
                <>
                <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
              </>
              )}
        
              </Grid>
              <Grid item xs={3}>
              <div>
                <div
                  onMouseEnter={() => setIsHovered23(true)}
                  onMouseLeave={() => setIsHovered23(false)}
                >
                  {!isHovered23 && <AdsClickIcon />}
                  {!isHovered23 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered23 && (
                  
                <TextField 
                margin="normal" 
                id="filled-disabled"
                fullWidth
                variant="filled" 
                value={t('example.desire')} 
                label={t('example.desireL')}  
                helperText={t('example.desireS')} 
                />    
                  )}  
                </div>
              </div>
              </Grid> 
                {/*} Section */} 
            </Grid> 
          </FormGroup>
          <FormGroup sx={{ display: props.step === 3 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.sustainable')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              <TextField 
                    id="sustainable"
                    value={sustainable} 
                    placeholder={sustainable === '' ? t('storyform.placeholderForm') : ''}
                    name="desire"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qsustainable')} 
                    variant="outlined" 
                    autoFocus={true}
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setSustainable(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                /> 
          </Grid>
          <Grid item xs={3}>
          {sustainable ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.sustainableL')}
              <HtmlTooltip title={t('storysection.sustainableT')} arrow>
                <span className="lavenderBackground"> {sustainable}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered24(true)}
                  onMouseLeave={() => setIsHovered24(false)}
                >
                  {!isHovered24 && <AdsClickIcon />}
                  {!isHovered24 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered24 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.sustainable')} 
            label={t('example.sustainableL')}  
            helperText={t('example.sustainableS')} 
            />    
                )}  
              </div>
            </div>
          </Grid> 
            {/*} Section */} 
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.companyoffering')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              <TextField 
                    id="companyOffering"
                    value={companyOffering} 
                    placeholder={companyOffering === '' ? t('storyform.placeholderForm') : ''}
                    name="companyOffering"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qcompanyOffering')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyOffering(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
          </Grid>
          <Grid item xs={3}>
          {companyOffering ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyOfferingL')}
              <HtmlTooltip title={t('storysection.companyOfferingT')} arrow>
                <span className="lavenderBackground"> {companyOffering}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered25(true)}
                  onMouseLeave={() => setIsHovered25(false)}
                >
                  {!isHovered25 && <AdsClickIcon />}
                  {!isHovered25 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered25 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyOffering')} 
            label={t('example.companyOfferingL')}  
            helperText={t('example.companyOfferingS')} 
            />    
              )}  
            </div>
          </div>
          </Grid> 
            {/*} Section */} 
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.companynotoffering')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
            <TextField 
                    id="companyNotOffering"
                    value={companyNotOffering} 
                    placeholder={companyNotOffering === '' ? t('storyform.placeholderForm') : ''}
                    name="companyNotOffering"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qcompanyNotOffering')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setCompanyNotOffering(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
          </Grid>
          <Grid item xs={3}>
          {companyNotOffering ? (
            <>
            <Typography variant='body2' align='left' mt={2}>{t('example.companyNotOfferingL')}
              <HtmlTooltip title={t('storysection.companyNotOfferingT')} arrow>
                <span className="lavenderBackground"> {companyNotOffering}</span>
              </HtmlTooltip>
          </Typography>
            <hr></hr>
            <Typography variant='body2' mt={1} align='left'> 
            
            ☝️{t('stepper.inputCheckH')} 
            </Typography>
          </>
          ) : (
            <>
            <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
          </>
          )}
    
          </Grid>
          <Grid item xs={3}>
          <div>
                <div
                  onMouseEnter={() => setIsHovered26(true)}
                  onMouseLeave={() => setIsHovered26(false)}
                >
                  {!isHovered26 && <AdsClickIcon />}
                  {!isHovered26 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered26 && (
                  
            <TextField 
            margin="normal" 
            id="filled-disabled"
            fullWidth
            variant="filled" 
            value={t('example.companyNotOffering')} 
            label={t('example.companyNotOfferingL')}  
            helperText={t('example.companyNotOfferingS')} 
            />    
              )}  
            </div>
          </div>
          </Grid> 
            {/*} Section */}   
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.start')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
              <TextField 
                    id="start"
                    value={start} 
                    placeholder={start === '' ? t('storyform.placeholderForm') : ''}
                    name="start"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qstart')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setStart(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
            </Grid>
            <Grid item xs={3}>
            {start ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.startL')}
                <HtmlTooltip title={t('storysection.startT')} arrow>
                  <span className="lavenderBackground"> {start}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered27(true)}
                  onMouseLeave={() => setIsHovered27(false)}
                >
                  {!isHovered27 && <AdsClickIcon />}
                  {!isHovered27 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered27 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.start')} 
              label={t('example.startL')}  
              helperText={t('example.startS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */}   
              <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.learn')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
              <TextField 
                    id="learn"
                    value={learn} 
                    placeholder={learn === '' ? t('storyform.placeholderForm') : ''}
                    name="learn"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qlearn')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setLearn(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ), 
                    }}
                />  
            </Grid>
            <Grid item xs={3}>
            {learn ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.learnL')}
                <HtmlTooltip title={t('storysection.learnT')} arrow>
                  <span className="lavenderBackground"> {learn}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered28(true)}
                  onMouseLeave={() => setIsHovered28(false)}
                >
                  {!isHovered28 && <AdsClickIcon />}
                  {!isHovered28 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered28 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.learn')} 
              label={t('example.learnL')}  
              helperText={t('example.learnS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */} 
              <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.change')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
                <TextField 
                    id="change"
                    value={change} 
                    placeholder={change === '' ? t('storyform.placeholderForm') : ''}
                    name="change"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qchange')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setChange(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                />  
            </Grid>
            <Grid item xs={3}>
            {change ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.changeL')}
                <HtmlTooltip title={t('storysection.changeT')} arrow>
                  <span className="lavenderBackground"> {change}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered29(true)}
                  onMouseLeave={() => setIsHovered29(false)}
                >
                  {!isHovered29 && <AdsClickIcon />}
                  {!isHovered29 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered29 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.change')} 
              label={t('example.changeL')}  
              helperText={t('example.changeS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */}    
             </Grid>   
          </FormGroup>
          <FormGroup sx={{ display: props.step === 4 ? "" : "none", m: 2 }}>
            <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.questions')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
              <TextField 
                    id="questions"
                    value={questions} 
                    placeholder={questions === '' ? t('storyform.placeholderForm') : ''}
                    name="questions"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qquestions')} 
                    variant="outlined" 
                    autoFocus={true}
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setQuestions(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                /> 
            </Grid>
            <Grid item xs={3}>
            {questions ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.questionsL')}
                <HtmlTooltip title={t('storysection.questionsT')} arrow>
                  <span className="lavenderBackground"> {questions}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered30(true)}
                  onMouseLeave={() => setIsHovered30(false)}
                >
                  {!isHovered30 && <AdsClickIcon />}
                  {!isHovered30 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered30 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.questions')} 
              label={t('example.questionsL')}  
              helperText={t('example.questionsS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */} 
          </Grid>

          </FormGroup>
          <FormGroup sx={{ display: props.step === 5 ? "" : "none", m: 2 }}>
          <Grid container spacing={2}>
            {/*} Section */} 
            <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.questionmarks')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
              <TextField 
                    id="questionMarks"
                    value={questionMarks} 
                    placeholder={questionMarks === '' ? t('storyform.placeholderForm') : ''}
                    name="desire"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qquestionMarks')} 
                    variant="outlined" 
                    autoFocus={true}
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setQuestionMarks(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <HtmlTooltip title={t('stepper.iconThink')} arrow>
                          <span className="lavenderBackground"> <PsychologyIcon color='secondary' /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ) 
                    }}
                /> 
            </Grid>
            <Grid item xs={3}>
            {questionMarks ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.questionMarksL')}
                <HtmlTooltip title={t('storysection.questionMarksT')} arrow>
                  <span className="lavenderBackground"> {questionMarks}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered31(true)}
                  onMouseLeave={() => setIsHovered31(false)}
                >
                  {!isHovered31 && <AdsClickIcon />}
                  {!isHovered31 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered31 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.questionMarks')} 
              label={t('example.questionMarksL')}  
              helperText={t('example.questionMarksS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */} 
              <Grid item xs={1}>
                  <AudioButton filePath={`/${t('storyform.questionmarksexamples')}.mp3`} />
                </Grid>
                <Grid item xs={5}>
              
              <TextField 
                    id="questionMarksExamples"
                    value={questionMarksExamples} 
                    placeholder={questionMarksExamples === '' ? t('storyform.placeholderForm') : ''}
                    name="questionMarksExamples"
                    margin="normal"
                    required
                    fullWidth
                    label={t('storyform.qquestionMarksExamples')} 
                    variant="outlined" 
                    helperText={
                      <div dangerouslySetInnerHTML={{ __html: t('storyform.Helper') }} />
                    }
                    onChange={(e) => setQuestionMarksExamples(e.target.value)}
                    multiline
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <HtmlTooltip title={t('stepper.iconList')} arrow>
                          <span className="lavenderBackground"> <FormatListNumberedIcon /> </span>
                          </HtmlTooltip> 
                        </InputAdornment>
                      ),
                    }}
                /> 
            </Grid>
            <Grid item xs={3}>
            {questionMarksExamples ? (
              <>
              <Typography variant='body2' align='left' mt={2}>{t('example.questionMarksExamplesL')}
                <HtmlTooltip title={t('storysection.questionMarksExamplesT')} arrow>
                  <span className="lavenderBackground"> {questionMarksExamples}</span>
                </HtmlTooltip>
            </Typography>
              <hr></hr>
              <Typography variant='body2' mt={1} align='left'> 
              
              ☝️{t('stepper.inputCheckH')} 
              </Typography>
            </>
            ) : (
              <>
              <Typography variant="body2" mt={4} ml={1}>{t('storyform.placeholder')}</Typography>
            </>
            )}
      
            </Grid>
            <Grid item xs={3}>
            <div>
                <div
                  onMouseEnter={() => setIsHovered32(true)}
                  onMouseLeave={() => setIsHovered32(false)}
                >
                  {!isHovered32 && <AdsClickIcon />}
                  {!isHovered32 && (
                    <Typography variant="h3" >
                      {t('stepper.exampleHover')}
                    </Typography>
                  )}

                {isHovered32 && (
                  
              <TextField 
              margin="normal" 
              id="filled-disabled"
              fullWidth
              variant="filled" 
              value={t('example.questionMarksExamples')} 
              label={t('example.questionMarksExamplesL')}  
              helperText={t('example.questionMarksExamplesS')} 
              />    
                )}  
              </div>
            </div>
            </Grid> 
              {/*} Section */}   
          </Grid>

          </FormGroup>
          <FormGroup sx={{ display: props.step === 6 ? "" : "none", m: 2 }}>
            {userStoryId ? (
              <>
                <Typography variant="h3" mt={2} mb={2}>{t('storysection.finalStepH')}</Typography>
                <Typography variant="h6" mb={3}>{t('storysection.finalStepH1')}{t('storysection.finalStepD')}  
              <HtmlTooltip title={t('storysection.finalStepT')} arrow>
            <span className="lavenderBackground">{t('storysection.finalStepD1')}</span>
              </HtmlTooltip></Typography>
               {/*  <Typography variant="h6" mt={2} mb={3}>ID: {userStoryId}</Typography>
                Display the latest story */}
                <StoryItem
                  story={userStories[0]} // Only the latest story
                />
              </>
            ) : (
              <>
                <Typography variant="h4" mt={2} ml={1}>{t('storysection.headline')}</Typography>
                <Typography variant="h6" mt={2} ml={1}>{t('storysection.subHeadline')}</Typography>
              </>
            )}
          </FormGroup>
            <FormGroup sx={{ display: props.step === 7 ? "" : "none", m: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>   
                  <ContentPasteSearchIcon /> 
                  <Typography variant="h4" mt={2} ml={1}>{t('search.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('search.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('search.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                  <hr></hr>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('search.jobTitle')}{jobTitle}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.workExperience')}{workExperience}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('search.treasure')}{treasure}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('search.specialty')}{specialty}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.degree')}{degree}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.jobDeliverable')}{jobDeliverable}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.applicantChallenge')}{applicantChallenge}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.applicantGoal')}{applicantGoal}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.companyProblem')}{companyProblem}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.companyThreat')}{companyThreat}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.hiringReason')}{hiringReason}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.companyInitiative')}{companyInitiative}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.desire')}{desire}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.sustainable')}{sustainable}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.companyOffering')}{companyOffering}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.companyNotOffering')}{companyNotOffering}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.start')}{start}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.learn')}{learn}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.references')}{references}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.change')}{change}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.questionMarks')}{questionMarks}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('search.questionMarksExamples')}{questionMarksExamples}</Typography> 
                  </div> 

                </Grid>
                <Grid item xs={6}>   
                  <GradingIcon />
                  <Typography variant="h4" mt={2} ml={1}>{t('resume.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('resume.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('resume.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                  <hr></hr>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('resume.name')}{name}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.workExperience')}{workExperience}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('resume.media')}{media}</Typography> 
                  <Typography variant="body2" mt={1}>{t('resume.specialty')}{specialty}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.degree')}{degree}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.applicantChallenge')}{applicantChallenge}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.applicantGoal')}{applicantGoal}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.qualification')}{qualification}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.languages')}{languages}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.skill3')}{skill3}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.references')}{references}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.workExperiences')}{workExperiences}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.change')}{change}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.questionMarks')}{questionMarks}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('resume.questionMarksExamples')}{questionMarksExamples}</Typography> 
                  </div>              
                </Grid>
             </Grid>
            </FormGroup>
            <FormGroup sx={{ display: props.step === 8 ? "" : "none", m: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>   
                  <AttachEmail /> 
                  <Typography variant="h4" mt={2} ml={1}>{t('coverletter.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('coverletter.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('coverletter.benefit')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                  <hr></hr>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('coverletter.letter')}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/>
                  <Typography variant="body2" mt={1}>{t('coverletter.resume')}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('coverletter.documents')}</Typography> 
                  </div>
                </Grid>
                <Grid item xs={6}>   
                  <Diversity3Icon /> 
                  <Typography variant="h4" mt={2} ml={1}>{t('interview.headline')}</Typography>
                  <Typography variant="h6" mt={2} ml={1}>{t('interview.subHeadline')}</Typography>
                  <Stack sx={{ width: '80%' }} spacing={2} mb={2} mt={2} align='justify'>
                    <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('interview.benefit')} </AlertTitle>  
                  </Alert> 
                  <Alert color='info' variant='outlined' >
               
                     <AlertTitle> {t('interview.benefit2')} </AlertTitle>  
                  </Alert> 
                  </Stack>
                  <hr></hr>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.why')} {desire}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" mt={1}>{t('interview.how')}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.skills3')} {skill3}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.qualification')} {qualification}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.workExperience')} {workExperiences}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.degree')} {degree}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.what')} {jobDeliverable}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mt={1}>{t('interview.risk')} {questionMarks}</Typography> 
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Checkbox/><Typography variant="body2" mb={3} mt={1}>{t('interview.questions')} {questions}</Typography> 
                  </div>
                <hr></hr>
                <BeenhereIcon />
                <Typography variant="h4" mt={2} ml={1}>{t('interview.preparedHeadline')}</Typography>
                <Typography variant="h6" mt={2} ml={1}>{t('interview.preparedSubHeadline')}</Typography>
                
                </Grid>
              </Grid>
            </FormGroup>      
        </form>
              
      </Paper>
      
    </>
  );
}

