import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Storyform from '../components/StoryForm';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import '../style.css';
import 'tailwindcss/lib/css/preflight.css';
import AnimationRevealPage from 'helpers/AnimationRevealPage';
import Hero from 'components/treact/hero/TwoColumnWithInput';
import FeatureWithSteps from 'components/treact/features/TwoColWithSteps.js';
import tw from 'twin.macro';
import macHeroScreenshotImageSrc from 'images/hero-screenshot-2.png';
import Pricing from 'components/treact/pricing/TwoPlansWithDurationSwitcher';
import Testimonials from 'components/treact/testimonials/TwoColumnWithImageAndRating';
import FAQs from 'components/treact/faqs/TwoColumnPrimaryBackground';
import CTA from 'components/treact/cta/GetStartedLight';
import MainFeature2 from "components/treact/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import prototypeIllustrationImageSrc from "images/prototype-illustration.svg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as MoneyIcon } from "feather-icons/dist/icons/dollar-sign.svg";
import Blog from 'components/treact/blogs/ThreeColSimpleWithImageAndDashedBorder';
import Header from 'components/treact/headers/light';
import Features from 'components/treact/features/ThreeColWithSideImage';
import { Helmet } from 'react-helmet';


export default function Landing(roundedHeaderButton) {
    const { t, i18n } = useTranslation();
    const topRef = useRef(null);
    const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
    const HighlightedText = tw.span`text-primary-500`;
    
    return (
        <div ref={topRef}>

                <Helmet>
                <title>Job Application Story Tool</title>
                <meta name="description" content="" />
                <meta name="keywords" content="react, meta tags, seo" />
                <meta name="author" content="Your Name" />
                <meta property="og:title" content="My Page Title" />
                <meta property="og:description" content="This tool helps you get your job application story straight. Get started for free." />
                <meta property="og:image" content="/static/media/prototype-illustration.21bc4b3f612a2f257c3d361067582485.svg" />
                <meta property="og:url" content="https://storylenses.app/" />
                <meta name="twitter:title" content="Job Application Story Tool" />
                <meta name="twitter:description" content="This tool helps you get your job application story straight. Get started for free" />
                <meta name="twitter:image" content="/static/media/prototype-illustration.21bc4b3f612a2f257c3d361067582485.svg" />
                <meta name="twitter:card" content="summary_large_image" />
            </Helmet>



      
            <Header roundedHeaderButton={roundedHeaderButton} />
            <AnimationRevealPage>
                <Hero />
                <Features />
                <MainFeature2
                subheading={<Subheading>{t('lp.valueH')}</Subheading>}
                heading={
                <>
                    {t('lp.valueH1')}
                </>
                }
                description={<>
                    {t('lp.valueD')}
                </>
                }
                imageSrc={prototypeIllustrationImageSrc}
                showDecoratorBlob={false}
                features={[
                {
                    Icon: MoneyIcon,
                    title: t('lp.valueH2'),
                    description: t('lp.valueD2'),
                    iconContainerCss: tw`bg-green-300 text-green-800`
                },
                {
                    Icon: BriefcaseIcon,
                    title: t('lp.valueH3'),
                    description: t('lp.valueD3'),
                    iconContainerCss: tw`bg-red-300 text-red-800`
                }
                ]}
            />
            <FeatureWithSteps
                subheading={<Subheading> {t('lp.stepsH1')} </Subheading>}
                heading={
                <>
                    {t('lp.stepsH2')}
                </>
                }
                textOnLeft={false}
                imageSrc={macHeroScreenshotImageSrc}
                imageDecoratorBlob={true}
                decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
            />
                <Testimonials />
                <Pricing />
                <FAQs />
             {/* <Blog />*/}
            <CTA />
            </AnimationRevealPage>
            
            
            
    
        </div>

    )
}